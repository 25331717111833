import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleTeacherDebitItem } from 'services/LessonsService'
import { propOr } from 'ramda'
import { getCurrentParsedQuery } from 'utils/navigation'
import {
  fetchSingleTeacherExpensesRoutine,
  fetchSingleTeacherRoutine
} from 'modules/teachers/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { removeExpense } from 'services/ExpensesService'
import { parseNumber } from 'utils/numbers'

const DeleteTeacherExpenseModal = ({ expense }) => {
  const teacher = useSelector(selectCurrentTeacher)
  const [open, setOpen] = useState(false)
  const [teacherDebit, setTeacherDebit] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (isNotNilOrEmpty(expense) && open) {
      getSingleTeacherDebitItem(expense.teacherDebitId, setTeacherDebit)
    }
  }, [expense, open])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) - parseNumber(teacherDebit.value)
    }

    const successCallback = () => {
      const query = getCurrentParsedQuery()
      dispatch(fetchSingleTeacherExpensesRoutine({ id: expense.teacherId, ...query }))
      dispatch(fetchSingleTeacherRoutine({ id: expense.teacherId }))
      handleClose()
    }

    removeExpense({
      values: {
        expenseId: expense.id,
        teacherDebit,
        teacher: teacherPayload
      },
      callback: successCallback
    })
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Usuń lekcję'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz usunąć ten wydatek/przychód?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeleteTeacherExpenseModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
