import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getTeacherLastLessonDates } from 'services/TeachersService'
import Table from 'components/Table'
import ExpandablePanel from 'components/ExpandablePanel'
import moment from 'moment'
import { DATE_FORMATS, formatDate } from 'utils/date'

const PanelTopicality = () => {
  const [teachers, setTeachers] = useState([])

  useEffect(() => {
    getTeacherLastLessonDates()
      .then(resp => {
        Promise.all(resp)
          .then(result => {
            setTeachers(result)
          })
      })
  }, [])

  const getColor = date => {
    switch (true) {
      case moment(date).isSameOrAfter(moment().subtract('2', 'day').format(DATE_FORMATS.input), 'day'):
        return '#8cd98c'
      case moment(date).isSameOrAfter(moment().subtract('6', 'day').format(DATE_FORMATS.input), 'day'):
        return '#ffff80'
      case moment(date).isSameOrAfter(moment().subtract('7', 'day').format(DATE_FORMATS.input), 'day'):
        return '#ffd480'
      case date === '---':
      case moment(date).isSameOrAfter(moment().subtract('14', 'day').format(DATE_FORMATS.input), 'day'):
      case moment(date).isSameOrBefore(moment().subtract('14', 'day').format(DATE_FORMATS.input), 'day'):
        return '#ffcccc'
      default:
        return 'transparent'
    }
  }

  return (
    <>
      <ExpandablePanel startOpen isStatic title='Aktualność panelu'>
        <Table>
          <thead>
          <tr>
            <th>Nauczyciel</th>
            <th>Data ostatniej lekcji</th>
          </tr>
          </thead>
          <tbody>
          {teachers.map(teacher => (
            <Row color={getColor(teacher.date)}>
              <td>{teacher.name}</td>
              <td>{teacher.date === '---' ? '---' : formatDate(teacher.date, DATE_FORMATS.dashboard)}</td>
            </Row>
          ))}
          </tbody>
        </Table>
      </ExpandablePanel>
    </>
  )
}

export default PanelTopicality

const Row = styled.tr`
  background-color: ${({ color }) => color};
  font-size: 14px;
`
