import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import { fetchSingleWorkerIncomeItemsRoutine, fetchSingleWorkerRoutine } from 'modules/workers/ducks/actions'
import { removeWorkerIncome } from 'services/WorkersService'

const DeleteWorkerIncomeModal = ({ income }) => {
  const worker = useSelector(selectCurrentWorker)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const successCallback = () => {
      dispatch(fetchSingleWorkerIncomeItemsRoutine({ id: worker.id }))
      dispatch(fetchSingleWorkerRoutine({ id: worker.id }))
      handleClose()
    }

    removeWorkerIncome({
      id: income.id,
      callback: successCallback
    })
  }


  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Usuń wypłatę'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz usunąć tę wypłatę?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeleteWorkerIncomeModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
