import moment from 'moment'
import { reverse } from 'ramda'
import { toast } from 'react-hot-toast'

export const DATE_FORMATS = {
  dashed: 'DD-MM-YYYY',
  dashboard: 'DD.MM',
  dashedWithTime: 'DD-MM-YYYY HH:mm',
  exactTime: 'DD-MM-YYYY HH:mm:ss',
  slash: 'DD/MM/YYYY',
  input: 'YYYY-MM-DD',
  inputWithTime: 'YYYY-MM-DDTHH:mm',
  withTimeAndSeconds: 'YYYY-MM-DDTHH:mm:ss',
}

export const formatDate = (date, format) => moment(date).format(format)

export const dateWithTime = (date, lastDate) => {
  if (lastDate) {
    const givenDate = formatDate(date, DATE_FORMATS.input)
    const newDate = moment(lastDate).add(1, 'second')
    const time = newDate.format('HH:mm:ss')
    return `${givenDate}T${time}`
  } else {
    const givenDate = formatDate(date, DATE_FORMATS.input)
    const today = new Date()
    const time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0')
    return `${givenDate}T${time}`
  }
}

export const sortByDates = (array, flip = false) => {
  const sortedArray = array.sort((a, b) => new Date(b.date) - new Date(a.date))
  return flip ? reverse(sortedArray) : sortedArray
}

export const copyToClipboard = str => () => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  toast.success('Skopiowano')
}
