import history from 'utils/history'
import qs from 'qs'

export const redirect = (path, state) => {
  history.push(path, state)
}

export const getCurrentPathname = () => {
  return history.location.pathname
}

export const parseQuery = search => {
  return qs.parse(search, { ignoreQueryPrefix: true })
}

export const stringifyQuery = search => {
  return qs.stringify(search, { addQueryPrefix: true })
}

export const getCurrentParsedQuery = () => {
  return parseQuery(history.location.search)
}

export const changeQuery = (query) => {
  redirect(`${getCurrentPathname()}${stringifyQuery(query)}`)
}
