import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// reducers
import authReducer from 'modules/auth/ducks/reducer'
import teachersReducer from 'modules/teachers/ducks/reducer'
import settingsReducer from 'modules/settings/ducks/reducer'
import studentsReducer from 'modules/students/ducks/reducer'
import workersReducer from 'modules/workers/ducks/reducer'

// sagas
import { authSagas } from 'modules/auth/ducks/actions'
import { teachersSagas } from 'modules/teachers/ducks/actions'
import { settingsSagas } from 'modules/settings/ducks/actions'
import { studentsSagas } from 'modules/students/ducks/actions'
import { workersSagas } from 'modules/workers/ducks/actions'

// reducer
const appReducer = combineReducers({
  auth: authReducer,
  teachers: teachersReducer,
  settings: settingsReducer,
  students: studentsReducer,
  workers: workersReducer
})

export default appReducer

export function * rootSaga () {
  yield all([
    ...authSagas,
    ...teachersSagas,
    ...settingsSagas,
    ...studentsSagas,
    ...workersSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
