import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'theme/globalStyles'
import theme from 'theme/theme'
import ReduxProvider from 'providers/ReduxProvider'
import { Toaster } from 'react-hot-toast'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import 'moment/locale/pl'
import ApiCalendar from 'react-google-calendar-api'
moment.locale('pl')

const config = {
  "scope": "https://www.googleapis.com/auth/calendar",
  "clientId": process.env.REACT_APP_GOOGLE_CLIENT_ID,
  "apiKey": process.env.REACT_APP_GOOGLE_API_KEY,
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

export const apiCalendar = new ApiCalendar(config)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ThemeProvider theme={theme}>
    <Toaster />
    <GlobalStyles />
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ReduxProvider>
        <App />
      </ReduxProvider>
    </LocalizationProvider>
  </ThemeProvider>
)
