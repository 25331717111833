import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentTeacherLessons } from 'modules/teachers/ducks/selectors'
import Table from 'components/Table'
import LessonTableRow from 'modules/lessons/components/LessonTableRow'
import { selectCurrentStudentLessons } from 'modules/students/ducks/selectors'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import { reverse } from 'ramda'

const LessonsTable = ({ isStudent, isDashboard, studentId }) => {
  const teacherLessons = useSelector(selectCurrentTeacherLessons)
  const studentLessons = useSelector(selectCurrentStudentLessons)

  const lessonsSource = isStudent ? studentLessons : teacherLessons
  const data = reverse(lessonsSource)

  const lessons = useMemo(() => {
    if (isNotNilOrEmpty(studentId) && studentId !== 'all') {
      return data.filter(lesson => lesson.studentId === studentId)
    } else {
      return data
    }
  }, [data, studentId])

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Data</th>
          <th>{isStudent ? 'Nauczyciel' : 'Uczeń'}</th>
          <th>{isStudent ? 'Przedmiot' : 'Typ lekcji'}</th>
          <th>Czas</th>
          {
            isDashboard ? (
              <>
                <th>Zarobek</th>
                <th>Bilans</th>
              </>
            ) : (
              <>
                <th>Stawka ucznia</th>
                <th>Stawka nau.</th>
                {
                  !isStudent && (
                    <>
                      <th>Przychód nau.</th>
                      <th>Wydatek ucz.</th>
                      <th>Bilans nau.</th>
                    </>
                  )
                }
              </>
            )
          }
          <th>Komentarz</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {lessons && lessons.map((lesson, index) => (
          <LessonTableRow
            isDashboard={isDashboard}
            isStudent={isStudent}
            order={index + 1}
            lesson={lesson}
            key={`lesson-${lesson.id}`}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(lessons) && (
        <EmptyState>
          Brak lekcji do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default LessonsTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
