import React from 'react'
import styled from 'styled-components'
import NavigationContent from 'components/navigation/NavigationContent'

const Navigation = () => {
  return (
    <NavigationWrapper>
      <NavigationContent />
    </NavigationWrapper>
  )
}

export default Navigation

const NavigationWrapper = styled.div`
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.primary.main};
`
