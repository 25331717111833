import {
  clearSingleStudentRoutine,
  fetchSingleStudentDebitItemsRoutine,
  fetchSingleStudentLessonsRoutine,
  fetchSingleStudentRoutine,
  getActiveStudentsRoutine,
  getArchivedStudentsRoutine
} from 'modules/students/ducks/actions'

const defaultState = {
  list: [],
  archivedList: [],
  currentStudent: {},
  currentStudentLessons: [],
  currentStudentDebitItems: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case getActiveStudentsRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case getArchivedStudentsRoutine.SUCCESS:
      return {
        ...state,
        archivedList: action.payload
      }
    case fetchSingleStudentRoutine.SUCCESS:
      return {
        ...state,
        currentStudent: action.payload
      }
    case fetchSingleStudentLessonsRoutine.SUCCESS:
      return {
        ...state,
        currentStudentLessons: action.payload
      }
    case fetchSingleStudentDebitItemsRoutine.SUCCESS:
      return {
        ...state,
        currentStudentDebitItems: action.payload
      }
    case clearSingleStudentRoutine.SUCCESS:
      return {
        ...state,
        currentStudent: {},
        currentStudentLessons: [],
        currentStudentDebitItems: []
      }
    default:
      return state
  }
}
