import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import styled from 'styled-components'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EditTeacherModal from 'modules/teachers/components/EditTeacherModal'

const TeacherBasicDetails = () => {
  const teacher = useSelector(selectCurrentTeacher)
  // const rates = propOr([], 'rates', teacher)

  return (
    <TeacherName>
      {teacher.name}
      <EditTeacherModal teacher={teacher} isSingleTeacherView />
    </TeacherName>
    // <PanelWrapper>
    //   <Wrapper>
    //     <ImageWrapper>
    //       <UserIcon />
    //     </ImageWrapper>
    //     <UserDetailsWrapper>
    //       <DetailsWrapper>
    //         <div>
    //           <SectionName>Dane nauczyciela</SectionName>
    //           <UserDetail>
    //             <div>ID:</div>
    //             <div>{teacher.id}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Email:</div>
    //             <div>{propOr('---', 'email', teacher)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Numer telefonu:</div>
    //             <div>{propOr('---', 'phoneNumber', teacher)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Data utworzenia:</div>
    //             <div>{formatDate(teacher.createdAt, DATE_FORMATS.dashed)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Aktualne saldo:</div>
    //             <div>{propOr('---', 'balance', teacher)}</div>
    //           </UserDetail>
    //         </div>
    //         <div>
    //           <SectionName>Stawki</SectionName>
    //           {
    //             isNotNilOrEmpty(rates)
    //               ? rates.map(rate => (
    //                   <RateItem key={rate.name}>
    //                     <div>{rate.name}</div>
    //                     <div>{rate.rate}zł</div>
    //                   </RateItem>
    //                 ))
    //               : <div>Brak dodanych stawek</div>
    //           }
    //         </div>
    //       </DetailsWrapper>
    //     </UserDetailsWrapper>
    //   </Wrapper>
    // </PanelWrapper>
  )
}

export default TeacherBasicDetails

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 10px;
  flex: 1;
`

const TeacherName = styled.div`
  display: flex;
  gap: 15px;
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
`

const SectionName = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`

const UserDetailsWrapper = styled.div`
  width: 100%;
`

const DetailsWrapper = styled.div`
  display: flex;
  gap: 30px;
  
  & > div {
    flex: 1;
  }
`

const RateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const UserIcon = styled(AccountCircleIcon)`
  font-size: 150px !important;
  color: ${({ theme }) => theme.colors.secondary.main};
`

const UserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const PanelWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`
