import React, { useEffect, useMemo, useState } from 'react'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { apiCalendar } from 'index'
import styled from 'styled-components'
import moment from 'moment'
import Input from 'components/Input'
import { DATE_FORMATS, dateWithTime } from 'utils/date'
import { head, pathOr, propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { selectActiveStudents } from 'modules/students/ducks/selectors'
import { v4 as uuid } from 'uuid'
import { countLessonBalance, isCashLesson } from 'utils/lessons'
import { toast } from 'react-hot-toast'
import { addImportedLesson, batchAddLesson, getLessonsForAllStudents } from 'services/LessonsService'
import ImportLessonResultTable from 'modules/calendarSync/components/ImportLessonResultTable'
import { CircularProgress } from '@mui/material'
import { parseNumber } from 'utils/numbers'

const defaultDates = {
  dateFrom: moment().subtract(1, 'week').startOf('isoWeek').format(DATE_FORMATS.input),
  dateTo: moment().subtract(1, 'week').endOf('isoWeek').format(DATE_FORMATS.input)
}

const ImportLessonsModal = ({ teachers }) => {
  const students = useSelector(selectActiveStudents)
  const [open, setOpen] = useState(false)
  const [dates, setDates] = useState(defaultDates)
  const [started, setStarted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [lessons, setLessons] = useState([])
  const [globalResult, setGlobalResult] = useState([])

  useEffect(() => {
    if (open) {
      setStarted(false)
      setGlobalResult([])
    }
  }, [open])

  const handleDateChange = (name, value) => {
    setDates(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    getLessonsForAllStudents({
      dateTo: dates.dateTo,
      dateFrom: dates.dateFrom,
      setFn: setLessons
    })
  }, [dates])

  const handleGoogleLogin = () => {
    apiCalendar.handleAuthClick()
  }

  const getSingleTeacherLessons = teacher => new Promise(resolve => {
    let teacherBalance = parseNumber(propOr(0, 'balance', teacher))
      apiCalendar.listEvents({
        timeMin: dates.dateFrom + 'T00:00:00Z',
        timeMax: dates.dateTo + 'T23:59:00Z',
        singleEvents: true,
        showDeleted: false
      }, teacher.calendarId.trim())
        .then(resp => {
          let result = {
            name: teacher.name,
            total: 0,
            success: 0,
            existing: 0,
            failed: 0,
            cancelled: 0,
            events: []
          }
          const handleResultUpdate = (name, value) => {
            result = {
              ...result,
              [name]: value
            }
          }
          const events = pathOr([], ['result', 'items'], resp)
          handleResultUpdate('total', events.length)
          if (isNotNilOrEmpty(events)) {
            events.forEach((event, index) => {
              setTimeout(() => {
                const eventDetails = propOr('', 'summary', event).replace('--', '').trim().split(' | ')
                const isCancelled = propOr('', 'summary', event).startsWith('--')
                const studentName = head(eventDetails).trim()

                const dividedSubject = pathOr(null, [1], eventDetails)
                const subjectName = isNotNilOrEmpty(dividedSubject) ? dividedSubject.trim() : null
                const student = students.find(s => s.name.toLowerCase().trim() === studentName.toLowerCase())
                const startDate = pathOr('', ['start', 'dateTime'], event)
                const endDate = pathOr('', ['end', 'dateTime'], event)
                const duration = (moment(endDate).unix() - moment(startDate).unix()) / 60 / 60
                const studentTeachers = propOr([], 'teachers', student).filter(t => t.id === teacher.id)
                const foundTeacher = studentTeachers.find(t => t.subject === subjectName)
                const subject = isNotNilOrEmpty(subjectName)
                  ? isNotNilOrEmpty(foundTeacher) ? foundTeacher : pathOr(null, [0], studentTeachers)
                  : pathOr(null, [0], studentTeachers)
                const lessonId = event.id
                const lessonExists = isNotNilOrEmpty(lessons.find(lesson => lesson.id === lessonId))

                if (subject && student && duration && lessonId && !isCancelled && !lessonExists) {
                  const debitId = uuid()
                  const teacherDebitId = uuid()
                  const isCash = isCashLesson(subject.lessonType)
                  const lessonCost = parseNumber(duration) * parseNumber(subject.studentRate)
                  const teacherIncome = countLessonBalance({
                    lessonType: subject.lessonType,
                    duration: duration,
                    studentRate: subject.studentRate,
                    teacherRate: subject.teacherRate
                  })
                  const createdAt = dateWithTime(new Date())

                  const lessonPayload = {
                    date: startDate,
                    subjectEntryId: subject.id,
                    studentId: student.id,
                    id: lessonId,
                    subject: subject.subject,
                    studentRate: parseNumber(subject.studentRate),
                    teacherRate: parseNumber(subject.teacherRate),
                    lessonType: subject.lessonType,
                    duration: parseNumber(duration),
                    comment: '',
                    createdAt,
                    debitId,
                    teacherDebitId,
                    teacher: {
                      rate: parseNumber(subject.teacherRate),
                      name: teacher.name,
                      id: teacher.id
                    },
                    student: {
                      rate: parseNumber(subject.studentRate),
                      name: student.name,
                      id: student.id
                    }
                  }

                  const debitPayload = {
                    type: 'lesson',
                    studentId: student.id,
                    comment: '',
                    createdAt,
                    subject: subject.subject,
                    studentRate: parseNumber(subject.studentRate),
                    duration: parseNumber(duration),
                    id: debitId,
                    teacherDebitId,
                    parentId: lessonId,
                    value: isCash ? 0 : parseNumber(lessonCost) * -1,
                    date: startDate,
                    balanceAfter: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student) - lessonCost)
                  }

                  const teacherDebitPayload = {
                    type: 'lesson',
                    teacherId: teacher.id,
                    comment: '',
                    createdAt,
                    id: teacherDebitId,
                    debitId,
                    parentId: lessonId,
                    value: parseNumber(teacherIncome),
                    subject: subject.subject,
                    teacherRate: parseNumber(subject.teacherRate),
                    duration: parseNumber(duration),
                    date: startDate,
                    student: {
                      name: student.name,
                      id: student.id
                    },
                    balanceAfter: parseNumber(propOr(0, 'balance', teacher)) + teacherIncome
                  }

                  const teacherPayload = {
                    ...teacher,
                    balance: teacherBalance += teacherIncome
                  }

                  batchAddLesson({
                    values: {
                      lessonPayload,
                      debitPayload,
                      teacherDebitPayload,
                      teacherPayload,
                      isCash,
                      lessonCost
                    },
                    silent: true,
                    callback: () => {
                      handleResultUpdate('success', result.success + 1)
                      index === events.length - 1 && resolve(result)
                    }
                  })
                } else {
                  let errors = []
                  if (isNilOrEmpty(subject)) {
                    errors.push('Nie znaleziono przypisania nauczyciela do ucznia')
                  }

                  if (isNilOrEmpty(student)) {
                    errors.push('Nie znaleziono ucznia')
                  }

                  const report = {
                    startDate: startDate,
                    title: event.summary,
                    status: 'failed',
                    errors
                  }

                  isCancelled
                    ? handleResultUpdate('cancelled', result.cancelled + 1)
                    : lessonExists
                      ? handleResultUpdate('existing', result.existing + 1)
                      : handleResultUpdate('failed', result.failed + 1)

                  isNotNilOrEmpty(errors) && handleResultUpdate('events', [...result['events'], report])
                  index === events.length - 1 && resolve(result)
                }
              }, index * 5000)
            })
          } else {
            resolve(result)
          }
        })
        .catch(() => {
          toast.error('Wystąpił błąd. Czy na pewno się zalogowałeś?')
        })
    }
  )

  const getTeachersLessons = async () => {
    setStarted(true)
    setLoading(true)
    for (let item of teachers) {
      await getSingleTeacherLessons(item)
        .then(resp => {
          setGlobalResult(prev => ([
            ...prev,
            resp
          ]))
        })
    }
  }

  useEffect(() => {
    if (loading && globalResult.length === teachers.length) {
      setLoading(false)
      setCompleted(true)
    }
  }, [loading, started, globalResult, teachers])

  const renderResult = useMemo(() => {
    if (isNotNilOrEmpty(globalResult)) {
      return globalResult
        .filter(result => isNotNilOrEmpty(result.events))
        .map(result => (
          <ResultRow>
            <ResultRowTeacherName>{result.name}</ResultRowTeacherName>
            <ImportLessonResultTable events={propOr([], 'events', result)} />
          </ResultRow>
        ))
    }
  }, [globalResult])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const totalCount = useMemo(() => globalResult.reduce((acc, { total }) => total + acc, 0), [globalResult])
  const cancelledCount = useMemo(() => globalResult.reduce((acc, { cancelled }) => cancelled + acc, 0), [globalResult])
  const failedCount = useMemo(() => globalResult.reduce((acc, { failed }) => failed + acc, 0), [globalResult])
  const existingCount = useMemo(() => globalResult.reduce((acc, { existing }) => existing + acc, 0), [globalResult])
  const successCount = useMemo(() => globalResult.reduce((acc, { success }) => success + acc, 0), [globalResult])

  return (
    <>
      <Button
        color='primary'
        disabled={isNilOrEmpty(teachers)}
        onClick={handleOpen}
      >
        Importuj lekcje
      </Button>
      <Modal
        open={open}
        title='Import lekcji z kalendarza Google'
        onClose={loading ? () => {} : handleClose}
      >
        {
          started
            ? (
              <ResultWrapper>
                {
                  loading && (
                    <LoadingWrapper>
                      <CircularProgress />
                    </LoadingWrapper>
                  )
                }
                {
                  completed && (
                    <LoadingWrapper>
                      Pomyślnie zaimportowano lekcje!
                    </LoadingWrapper>
                  )
                }
                <StatsRow>
                <StatsWrapper>
                  <StatItem>
                    <div>Sprawdzeni nauczyciele:</div>
                    <div>{globalResult.length}/{teachers.length}</div>
                  </StatItem>
                  <StatItem>
                    <div>Wszystkich lekcji:</div>
                    <div>{totalCount}</div>
                  </StatItem>
                  <StatItem>
                    <div>Dodanych do panelu:</div>
                    <div>{successCount}</div>
                  </StatItem>
                  <StatItem>
                    <div>Anulowane:</div>
                    <div>{cancelledCount}</div>
                  </StatItem>
                  <StatItem>
                    <div>Istniejące:</div>
                    <div>{existingCount}</div>
                  </StatItem>
                  <StatItem>
                    <div>Błędy:</div>
                    <div>{failedCount}</div>
                  </StatItem>
                </StatsWrapper>
                </StatsRow>
                {renderResult}
              </ResultWrapper>
            )
            : (
              <>
                <StepTitle>
                  1. Zaloguj się do Google!
                </StepTitle>
                <Button onClick={handleGoogleLogin}>
                  Zaloguj się do Google
                </Button>
                <StepTitle>
                  2. Pobierz lekcje
                </StepTitle>
                <DateFilters>
                  <Input
                    type='date'
                    label='Data od'
                    name='dateFrom'
                    onChange={handleDateChange}
                    value={dates.dateFrom}
                  />
                  <Input
                    type='date'
                    label='Data do'
                    name='dateTo'
                    onChange={handleDateChange}
                    value={dates.dateTo}
                  />
                </DateFilters>
                <Button onClick={getTeachersLessons}>
                  Pobierz lekcje
                </Button>
              </>
            )
        }
      </Modal>
    </>

  )
}

export default ImportLessonsModal

const StepTitle = styled.div`
  margin-bottom: 10px;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`

const DateFilters = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`

const ResultRow = styled.div`
  margin-bottom: 30px;
`

const ResultRowTeacherName = styled.div`
  font-weight: bold;
`

const ResultWrapper = styled.div`
  width: 800px;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const StatsWrapper = styled.div`
  margin-bottom: 20px;
  width: 300px;
`

const StatItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StatsRow = styled.div`
  display: flex;
  justify-content: flex-end;
`
