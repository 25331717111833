import React, { useEffect, useState } from 'react'
import Input from 'components/Input'
import styled from 'styled-components'
import Button from 'components/Button'

const initialValues = {
  zus: 0,
  tax: 0,
  rent: 0
}

const FixedCostsForm = ({ onSubmit, values: parentValues }) => {
  const [values, setValues] = useState(initialValues)

  const handleSubmit = () => {
    onSubmit(values)
  }

  useEffect(() => {
    setValues(parentValues)
  }, [parentValues])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  return (
    <Wrapper>
      <Input
        name='rent'
        label='Wynajem'
        value={values.rent}
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        name='zus'
        label='ZUS'
        value={values.zus}
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        name='tax'
        label='Podatek'
        value={values.tax}
        onChange={handleValueChange}
        noMarginBottom
      />
      <Button onClick={handleSubmit}>
        Zapisz
      </Button>
    </Wrapper>
  )
}

export default FixedCostsForm

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
