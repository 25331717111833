import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { restoreStudent } from 'services/StudentsService'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'

const RestoreStudentModal = ({ student }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleDelete = () => {
    restoreStudent({
      student,
      callback: () => {
        dispatch(getActiveStudentsRoutine())
      }
    })
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Przywróć'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz przywrócić ucznia <StudentName>{student.name}</StudentName>?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleDelete}>Przywróć</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default RestoreStudentModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(SettingsBackupRestoreIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const StudentName = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
