import { pipe, propOr } from 'ramda'
import { createSelector } from 'reselect'
import { isNotNilOrEmpty } from 'utils/ramda'

export const selectAuth = state => state.auth

export const selectCurrentUser = createSelector(
  selectAuth,
  propOr(null, 'user')
)

export const selectIsUserSignedIn = createSelector(
  selectAuth,
  pipe(
    propOr(null, 'user'),
    isNotNilOrEmpty
  )
)
