import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from 'components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/Button'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import { fetchSingleWorkerRoutine, fetchWorkersListRoutine } from 'modules/workers/ducks/actions'
import { updateWorker } from 'services/WorkersService'

const emptyValues = {
  name: '',
  email: '',
  phoneNumber: ''
}

const EditWorkerForm = ({ handleCloseModal, fetchSingle }) => {
  const [values, setValues] = useState(emptyValues)
  const worker = useSelector(selectCurrentWorker)
  const dispatch = useDispatch()

  const callback = () => {
    fetchSingle
      ? dispatch(fetchSingleWorkerRoutine({ id: worker.id }))
      : dispatch(fetchWorkersListRoutine())
    handleCloseModal()
  }

  useEffect(() => {
    isNotNilOrEmpty(worker) && setValues(worker)
  }, [worker])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleUpdate = () => {
    updateWorker({
      worker: values,
      callback
    })
  }

  return (
    <Wrapper>
      <Input
        name='email'
        label='Email'
        disabled
        value={values.email}
        onChange={handleValueChange}
      />
      <Input
        name='phoneNumber'
        label='Numer telefonu'
        value={values.phoneNumber}
        onChange={handleValueChange}
      />
      <Input
        name='name'
        label='Nazwa'
        value={values.name}
        onChange={handleValueChange}
      />
      <ButtonsWrapper>
        <Button onClick={handleCloseModal} color='secondary'>Anuluj</Button>
        <Button
          color='primary'
          onClick={handleUpdate}
          disabled={isNilOrEmpty(values.name)}
        >
          Zapisz
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default EditWorkerForm

const Wrapper = styled.form`
  
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
