import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

export const Input = props => {
  const {
    name,
    type,
    onChange,
    label,
    value: initialValue,
    required,
    variant,
    disabled,
    multiline,
    validate,
    rows,
    startAdornment,
    endAdornment,
    hiddenLabel,
    noMarginBottom,
    inputProps,
    placeholder,
    onBlur,
    onFocus,
    options
  } = props
  const [passwordVisible, _setPasswordVisibility] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: ''
  })

  useEffect(() => {
    if (touched) {
      validate(name, v => {
        _validate({ valid: v.valid, error: v.errors })
      })
    }
  }, [value, touched])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const inputType = !type || passwordVisible ? 'text' : type

  const handleVisibilityChange = () =>
      _setPasswordVisibility(prevVisibility => !prevVisibility)

  const getEndAdornment = type === 'password' && (
      <InputAdornment position='end'>
        <IconButton onClick={handleVisibilityChange}>
          {passwordVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </IconButton>
      </InputAdornment>
  )

  const handleBlur = () => {
    _setTouched(true)
    typeof onBlur === 'function' && onBlur()
  }
  const handleFocus = () => {
    typeof onFocus === 'function' && onFocus()
  }
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <InputWrapper noMarginBottom={noMarginBottom}>
      <TextField
        select
        error={!valid && touched && !disabled}
        helperText={error}
        label={label}
        validate={validate}
        disabled={disabled}
        hiddenLabel={hiddenLabel}
        placeholder={placeholder}
        variant={variant}
        required={required}
        name={name}
        multiline={multiline}
        rows={rows}
        type={inputType || 'text'}
        fullWidth
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          ) : null,
          endAdornment: endAdornment || getEndAdornment
        }}
        inputProps={inputProps}
      >
        {
          options.map(option => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))
        }
      </TextField>
    </InputWrapper>
  )
}

Input.defaultProps = {
  name: '',
  type: 'text',
  options: [],
  label: '',
  value: '',
  required: false,
  variant: 'filled',
  disabled: false,
  multiline: false,
  rows: 1,
  noMarginBottom: false,
  inputProps: {},
  placeholder: '',
  onChange: () => {},
  onBlur: () => {},
  validate: () => {},
  onFocus: () => {}
}

export default Input

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '15px' };
  width: 100%;
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary.main};
  }

  .MuiFilledInput-root, .MuiSelect-select {
    background-color: #fff !important;

    &:hover, &:active {
      background-color: #fff !important;
    }
  }

  .Mui-error {
    color: ${({ theme }) => theme.colors.error} !important;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    margin: 0;
  }
`
