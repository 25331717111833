import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Input from 'components/Input'
import moment from 'moment'
import {
  addAdditionalFixedCost,
  removeAdditionalFixedCost,
  updateAdditionalFixedCost
} from 'services/fixedCostsService'
import Table from 'components/Table'
import { v4 as uuid } from 'uuid'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const defaultValues = {
  name: '',
  value: 0,
  dateTo: moment().add(3, 'year').format('YYYY-MM'),
  dateFrom: moment().format('YYYY-MM')
}

const AdditionalFixedCostsPanel = ({ costs, callback }) => {
  const [values, setValues] = useState(defaultValues)
  const [open, setOpen] = useState(false)
  const [selectedCost, setSelectedCost] = useState(null)

  const handleOpen = cost => () => {
    if (cost) {
      setSelectedCost(cost)
    } else {
      setSelectedCost(null)
    }
    setOpen(true)
  }

  useEffect(() => {
    if (selectedCost && open) {
      setValues(selectedCost)
    }

    if (!open) {
      setValues(defaultValues)
    }
  }, [selectedCost])

  const handleClose = () => {
    setValues(defaultValues)
    setOpen(false)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const successCallback = () => {
    callback()
    handleClose()
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (isNotNilOrEmpty(selectedCost)) {
      updateAdditionalFixedCost({ values, callback: successCallback })
    } else {
      addAdditionalFixedCost({
        values: {
          id: uuid(),
          ...values
        },
        callback: successCallback
      })
    }
  }

  const handleRemove = id => () => {
    removeAdditionalFixedCost({ id, callback: successCallback })
  }

  return (
    <Wrapper>
      <Header>
        <div>Dodatkowe koszta stałe</div>
        <Button onClick={handleOpen()}>Dodaj</Button>
      </Header>
      <Table>
        <thead>
        <tr>
          <th>Nazwa</th>
          <th>Kwota</th>
          <th>Pierwszy miesiąc</th>
          <th>Ostatni miesiąc</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          costs?.map(cost => (
            <tr key={cost.id}>
              <td>{cost.name}</td>
              <td>{cost.value}</td>
              <td>{cost.dateFrom}</td>
              <td>{cost.dateTo}</td>
              <td>
                <IconsWrapper>
                  <StyledEditIcon onClick={handleOpen(cost)} />
                  <StyledDeleteIcon onClick={handleRemove(cost.id)} />
                </IconsWrapper>
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
      {
        isNilOrEmpty(costs) && (
          <EmptyState>
            Brak dodatkowych kosztów w tym miesiący
          </EmptyState>
        )
      }
      <Modal
        title='Dodaj koszt stały'
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa'
            value={values.name}
            onChange={handleValueChange}
          />
          <Input
            name='value'
            label='Kwota'
            value={values.value}
            onChange={handleValueChange}
          />
          <Input
            name='dateFrom'
            type='month'
            label='Pierwszy miesiąc'
            value={values.dateFrom}
            onChange={handleValueChange}
          />
          <Input
            name='dateTo'
            type='month'
            label='Ostatni miesiąc'
            value={values.dateTo}
            onChange={handleValueChange}
          />
          <ButtonsWrapper>
            <Button type='button' onClick={handleClose} color='primary-outline'>Anuluj</Button>
            <Button type='submit'>
              {
                isNotNilOrEmpty(selectedCost)
                  ? 'Zapisz'
                  : 'Dodaj'
              }
            </Button>
          </ButtonsWrapper>
        </form>
      </Modal>
    </Wrapper>
  )
}

export default AdditionalFixedCostsPanel

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`

const ButtonsWrapper = styled.div`
  display: flex;  
  gap: 15px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  button {
    width: fit-content;
  }
`

const EmptyState = styled.div`
  margin-top: 15px;
`

const StyledEditIcon = styled(EditIcon)`
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const StyledDeleteIcon = styled(DeleteIcon)`
  transition: all .3s;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`
