import React from 'react'
import styled from 'styled-components'
import { redirect } from 'utils/navigation'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const BackButton = ({ path, label, withMargin }) => {
  return (
      <Wrapper onClick={() => redirect(path)} withMargin={withMargin}>
        <ArrowBackIcon />
        {label}
      </Wrapper>
  )
}

BackButton.defaultProps = {
  label: 'Wróć'
}

export default BackButton

const Wrapper = styled.div`
  display: flex;  
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-bottom: ${({ withMargin }) => withMargin ? '20px' : 0};
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
