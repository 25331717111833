import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { getCompanyExpenses, removeCompanyExpense } from 'services/CompanyExpesnesService'

const DeleteOtherExpenseModal = ({ date, expenseId, setFn }) => {
  const [open, setOpen] = useState(false)
  const month = date.locale('en').format('MMMM')
  const year = date.format('YYYY')
  const title = `${month}${year}`

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const successCallback = () => {
      getCompanyExpenses({
        title,
        setFn
      })
      handleClose()
    }

    removeCompanyExpense({
      title,
      expenseId,
      callback: successCallback
    })

  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Usuń wydatek/przychód'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz usunąć ten wydatek/przychód?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeleteOtherExpenseModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
