import { db } from 'firestoreConfig'
import { toast } from 'react-hot-toast'
import { propOr } from 'ramda'

export const getCompanyExpenses = async ({ title, setFn }) => {
  const ref = db.collection('companyExpenses').doc(title)
  await ref
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        setFn(data.entries)
      } else {
        await ref.set({ entries: [] })
        setFn([])
      }
    })
    .catch(() => {
      toast.error('Nie udało się pobrać wydatków')
    })
}

export const addCompanyExpense = async ({ title, newExpense, callback }) => {
  const ref = db.collection('companyExpenses').doc(title)
  await ref
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const entries = [
          ...propOr([], 'entries', data),
          newExpense
        ]
        await ref.update({ entries })
        toast.success('Dodano wydatek')
        typeof callback === 'function' && callback()
      } else {
        toast.error('Coś poszło nie tak.')
      }
    })
    .catch(() => {
      toast.error('Nie udało się pobrać wydatków')
    })
}

export const removeCompanyExpense = async ({ title, expenseId, callback }) => {
  const ref = db.collection('companyExpenses').doc(title)
  await ref
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const entries = propOr([], 'entries', data).filter(entry => entry.id !== expenseId)
        await ref.update({ entries })
        toast.success('Usunięto wydatek')
        typeof callback === 'function' && callback()
      } else {
        toast.error('Coś poszło nie tak.')
      }
    })
    .catch(() => {
      toast.error('Nie udało się pobrać wydatków')
    })
}
