import React, { useEffect, useState } from 'react'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import Button from 'components/Button'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectTeachersList } from 'modules/teachers/ducks/selectors'
import Checkbox from 'components/Checkbox'
import ImportLessonsModal from 'modules/calendarSync/components/ImportLessonsModal'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import { equals } from 'ramda'

const CalendarSync = () => {
  const teachers = useSelector(selectTeachersList)
  const [selectedTeachers, setSelectedTeachers] = useState([])
  const dispatch = useDispatch()

  const toggleTeacherSelection = teacher => () => {
    selectedTeachers.some(t => t.id === teacher.id)
      ? setSelectedTeachers(prev => prev.filter(t => t.id !== teacher.id))
      : setSelectedTeachers(prev => [ ...prev,  teacher ])
  }

  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
    dispatch(getActiveStudentsRoutine())
  }, [])

  const handleClearSelected = () => {
    setSelectedTeachers([])
  }

  const handleSelectAll = () => {
    setSelectedTeachers(teachers.filter(t => isNotNilOrEmpty(t.calendarId)))
  }

  return (
    <Wrapper>
      <SummaryPanel>
        <div>
          Zaznaczeni nauczyciele: {selectedTeachers.length}
        </div>
        <ButtonsContainer>
          <Button
            color='primary-outline'
            disabled={equals(selectedTeachers, teachers)}
            onClick={handleSelectAll}
          >
            Zaznacz wszystkich
          </Button>
          <Button
            color='primary-outline'
            disabled={isNilOrEmpty(selectedTeachers)}
            onClick={handleClearSelected}
          >
            Wyczyść
          </Button>
          <ImportLessonsModal
            teachers={selectedTeachers}
          />
        </ButtonsContainer>
      </SummaryPanel>
      <TeachersList>
        {
          teachers.map(teacher => (
            <TeacherRow>
              <Checkbox
                disabled={isNilOrEmpty(teacher.calendarId)}
                value={selectedTeachers.map(t => t.id).includes(teacher.id)}
                onChange={toggleTeacherSelection(teacher)}
              />
              {teacher.name}
            </TeacherRow>
          ))
        }
      </TeachersList>
    </Wrapper>
  )
}

export default CalendarSync

const SummaryPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 299px;
  padding: 10px 30px;
  background: #fff;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  min-width: 550px;
`

const Wrapper = styled.div`
  padding-top: 75px;
`

const TeachersList = styled.div`
  
`

const TeacherRow = styled.div`
  display: flex;
  gap: 15px;
  padding: 3px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`
