import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import {
  getSingleStudent,
  getSingleStudentDebitItems,
  getSingleStudentLessons,
  fetchActiveStudents, fetchArchivedStudents
} from 'services/StudentsService'

export const getActiveStudentsRoutine = createRoutine('FETCH_ACTIVE_STUDENTS')
export const getArchivedStudentsRoutine = createRoutine('FETCH_Archived_STUDENTS')
export const fetchSingleStudentRoutine = createRoutine('FETCH_SINGLE_STUDENT')
export const clearSingleStudentRoutine = createRoutine('CLEAR_SINGLE_STUDENT')
export const fetchSingleStudentLessonsRoutine = createRoutine('FETCH_STUDENT_LESSONS')
export const fetchSingleStudentDebitItemsRoutine = createRoutine('FETCH_STUDENT_DEBIT_ITEMS')

function * getActiveStudents () {
  yield put(getActiveStudentsRoutine.request())
  try {
    const result = yield call(fetchActiveStudents)
    yield put(getActiveStudentsRoutine.success(result))
  } catch (e) {
    yield put(getActiveStudentsRoutine.failure(e))
    console.error(e)
  }
}

function * getArchivedStudents () {
  yield put(getArchivedStudentsRoutine.request())
  try {
    const result = yield call(fetchArchivedStudents)
    yield put(getArchivedStudentsRoutine.success(result))
  } catch (e) {
    yield put(getArchivedStudentsRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleStudent ({ payload }) {
  yield put(fetchSingleStudentRoutine.request())
  try {
    const result = yield call(getSingleStudent, payload.id)
    yield put(fetchSingleStudentRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleStudentRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleStudentLessons ({ payload }) {
  yield put(fetchSingleStudentLessonsRoutine.request())
  try {
    const result = yield call(getSingleStudentLessons, payload)
    yield put(fetchSingleStudentLessonsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleStudentLessonsRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleStudentDebitItems ({ payload }) {
  yield put(fetchSingleStudentDebitItemsRoutine.request())
  try {
    const result = yield call(getSingleStudentDebitItems, payload)
    yield put(fetchSingleStudentDebitItemsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleStudentDebitItemsRoutine.failure(e))
    console.error(e)
  }
}

function * clearSingleStudent () {
  yield put(clearSingleStudentRoutine.success())
}

export function * getActiveStudentsWatcher () {
  yield takeLatest(getActiveStudentsRoutine.TRIGGER, getActiveStudents)
}

export function * getArchivedStudentsWatcher () {
  yield takeLatest(getArchivedStudentsRoutine.TRIGGER, getArchivedStudents)
}

export function * fetchSingleStudentWatcher () {
  yield takeLatest(fetchSingleStudentRoutine.TRIGGER, fetchSingleStudent)
}

export function * fetchSingleStudentLessonsWatcher () {
  yield takeLatest(fetchSingleStudentLessonsRoutine.TRIGGER, fetchSingleStudentLessons)
}

export function * fetchSingleStudentDebitItemsWatcher () {
  yield takeLatest(fetchSingleStudentDebitItemsRoutine.TRIGGER, fetchSingleStudentDebitItems)
}

export function * clearSingleStudentWatcher () {
  yield takeLatest(clearSingleStudentRoutine.TRIGGER, clearSingleStudent)
}

export const studentsSagas = [
  fork(getActiveStudentsWatcher),
  fork(fetchSingleStudentWatcher),
  fork(fetchSingleStudentLessonsWatcher),
  fork(clearSingleStudentWatcher),
  fork(fetchSingleStudentDebitItemsWatcher),
  fork(getArchivedStudentsWatcher)
]
