import React from 'react'
import { useSelector } from 'react-redux'
import Table from 'components/Table'
import { selectWorkersList } from 'modules/workers/ducks/selectors'
import WorkerTableRow from 'modules/workers/components/WorkerTableRow'
import { isNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'

const WorkersTable = () => {
  const workers = useSelector(selectWorkersList)

  return (
    <>
      <Table>
        <thead>
        <th>Lp.</th>
        <th>Nazwa</th>
        <th>Email</th>
        <th>Numer telefonu</th>
        <th />
        </thead>
        <tbody>
        {workers.map((worker, index) => (
          <WorkerTableRow
            order={index + 1}
            worker={worker}
            key={`worker-${worker.id}`}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(workers) && (
        <EmptyState>
          Brak pracowników do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default WorkersTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
