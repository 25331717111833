import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { getSingleTeacherStudents } from 'services/TeachersService'
import StudentsTable from 'modules/students/components/StudentsTable'
import { isEmpty } from 'ramda'
import styled from 'styled-components'

const TeacherDetailsStudents = () => {
  const teacher = useSelector(selectCurrentTeacher)
  const [students, setStudents] = useState([])

  const getStudents = () => {
    getSingleTeacherStudents(teacher.id, setStudents)
  }

  useEffect(() => {
    teacher.id && getStudents()
  }, [teacher])

  return (
    <>
      {
        isEmpty(students)
          ? <EmptyState>Brak przypisanych uczniów</EmptyState>
          : (
            <StudentsTable
              studentsList={students}
              actionCallback={getStudents}
            />
          )
      }
    </>

  )
}

export default TeacherDetailsStudents

const EmptyState = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
