import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import AddWorkerIncomeForm from 'modules/workerDetails/components/AddWorkerIncomeForm'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import { fetchSingleWorkerIncomeItemsRoutine, fetchSingleWorkerRoutine } from 'modules/workers/ducks/actions'
import WorkerIncomeTable from 'modules/workerDetails/components/WorkerIncomeTable'
import ExpandablePanel from 'components/ExpandablePanel'

const WorkerDebitHistory = () => {
  const worker = useSelector(selectCurrentWorker)
  const dispatch = useDispatch()

  const fetchWorkerIncomeItems = () => {
    worker.id && dispatch(fetchSingleWorkerIncomeItemsRoutine({ id: worker.id }))
  }

  useEffect(() => {
    fetchWorkerIncomeItems()
  }, [worker])

  return (
    <ExpandablePanel title='Historia wypłat' startOpen>
      <Header>
        <AddWorkerIncomeForm
          callback={() => {
            fetchWorkerIncomeItems()
            dispatch(fetchSingleWorkerRoutine({ id: worker.id }))
          }}
          worker={worker}
        />
      </Header>
      <WorkerIncomeTable />
    </ExpandablePanel>
  )
}

export default WorkerDebitHistory


const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`
