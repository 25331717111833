import React, { useMemo, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import styled from 'styled-components'
import { DATE_FORMATS, dateWithTime, formatDate } from 'utils/date'
import { v4 as uuid } from 'uuid'
import { dissoc, propOr, values as ramdaValues } from 'ramda'
import { useSelector } from 'react-redux'
import { selectCurrentTeacher, selectCurrentTeacherDebitItems } from 'modules/teachers/ducks/selectors'
import { addTeacherSalary } from 'services/TeachersService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'
import Select from 'components/Select'
import Modal from 'components/Modal'
import moment from 'moment'

const defaultValues = {
  date: formatDate(new Date(), DATE_FORMATS.input),
  value: 0,
  type: 'salary',
  comment: ''
}

const typeOptions = [
  { label: 'Wypłata', value: 'salary' },
  { label: 'Wypłacona niedopłata za zeszły miesiąc (lekcje dopisane po wypłacie)', value: 'underpayment' },
  { label: 'Nadpłata za zeszły miesiąc (lekcje usunięte po wypłacie)', value: 'overpayment' },
  { label: 'Zaliczka', value: 'advance' },
  { label: 'Nieobecność ucznia', value: 'absence' },
  { label: 'Dodatkowa praca lub wydatek nauczyciela', value: 'additional' }
]

const AddSalaryForm = ({ callback }) => {
  const list = useSelector(selectCurrentTeacherDebitItems)
  const teacher = useSelector(selectCurrentTeacher)
  const [values, setValues] = useState(defaultValues)
  const [open, setOpen] = useState(false)

  const salary = list.find(el => el.type === 'salary')
  const advances = list.filter(el => el.type === 'advance')

  const edgeDates = {
    firstDay: moment(values.date).startOf('month'),
    lastDay: moment(values.date).endOf('month'),
  }

  const hasSalary = isNotNilOrEmpty(salary) && moment(salary.date).isBetween(edgeDates.firstDay, edgeDates.lastDay, 'day', '[]')

  console.log({ edgeDates, hasSalary, date:values.date })
  const hasAdvances = isNotNilOrEmpty(advances)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setValues(defaultValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const createdAt = dateWithTime(new Date())
    const debitId = uuid()

    const operationValue = (type, value, addAdditionalValue) => {
      switch (true) {
        case type === 'overpayment' && !addAdditionalValue:
          return 0
        case type === 'absence':
        case type === 'additional':
          return parseNumber(value) * -1
        default:
          return parseNumber(value)
      }
    }

    const isOverpayment = values.type === 'overpayment'

    const teacherDebitPayload = {
      type: values.type,
      teacherId: teacher.id,
      comment: isOverpayment ? `[${operationValue(values.type, values.value, true)}zł] ${values.comment}` : values.comment,
      createdAt,
      id: debitId,
      value: operationValue(values.type, values.value) * -1,
      overpaymentValue: isOverpayment ? operationValue(values.type, values.value, true) * -1 : 0,
      date: dateWithTime(values.date),
      balanceAfter: parseNumber(propOr(0, 'balance', teacher)) - operationValue(values.type, values.value)
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) - operationValue(values.type, values.value)
    }

    addTeacherSalary({
      values: {
        teacherDebitPayload,
        teacherPayload
      },
      callback: () => {
        callback()
        handleClose()
      }
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.value) > 0
  }, [values])

  return (
    <>
      <ButtonWrapper>
        <Button onClick={handleOpen}>Dodaj wpis</Button>
      </ButtonWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        title='Dodaj wpis historii salda'
      >
        {
          hasSalary && (
            <Warning>
              <div>W tym miesiącu dokonano już wypłaty.</div>
              <div>&bull; {formatDate(salary?.date, DATE_FORMATS.dashed)} - {Math.abs(salary?.value)}zł</div>
            </Warning>
          )
        }
        {
          hasAdvances && (
            <Warning>
              <div>W tym miesiącu wypłacono następujące zaliczki:</div>
              {
                advances.map(advance => (
                  <div key={advance.id}>
                    &bull; {formatDate(advance?.date, DATE_FORMATS.dashed)} - {Math.abs(advance?.value)}zł
                  </div>
                ))
              }
            </Warning>
          )
        }
        <AddSalaryWrapper>
          <Select
            name='type'
            onChange={handleValueChange}
            label='Typ zmiany salda'
            value={values.type}
            options={typeOptions}
          />
          <Input
            name='value'
            type='number'
            label='Kwota wypłaty'
            value={values.value}
            onChange={handleValueChange}
          />
          <Input
            type='date'
            label='Data'
            name='date'
            onChange={handleValueChange}
            value={values.date}
          />
          <Input
            name='comment'
            label='Komentarz'
            value={values.comment}
            onChange={handleValueChange}
          />
          <Button
            onClick={handleSubmit}
            disabled={!isValid || (values.type === 'salary' && hasSalary)}
          >
            Dodaj
          </Button>
        </AddSalaryWrapper>
      </Modal>
    </>

  )
}

export default AddSalaryForm

const ButtonWrapper = styled.div`
  width: 150px;
`

const AddSalaryWrapper = styled.div`
  max-width: 400px;
`

const Warning = styled.div`
  border: 1px solid #cc2000;
  background: rgba(255, 187, 51, .5);
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 14px;
`
