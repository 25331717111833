import React, { useEffect, useState } from 'react'
import Button from 'components/Button'
import { checkStudentsCorrectness, checkTeachersCorrectness } from 'services/TeachersService'
import ExpandablePanel from 'components/ExpandablePanel'
import styled from 'styled-components'
import { getLogs } from 'services/SettingsService'
import Table from 'components/Table'
import { isNotNilOrEmpty } from 'utils/ramda'

const CheckDataCorrectness = () => {
  const [teachers, setTeachers] = useState([])
  const [students, setStudents] = useState([])

  useEffect(() => {
    checkStudentsCorrectness()
      .then(resp => setStudents(resp))

    checkTeachersCorrectness()
      .then(resp => setTeachers(resp))
  }, [])

  return (
    <ExpandablePanel title='Sprawdź uczniów i nauczycieli'>
      <Wrapper>
        <SectionWrapper>
        <SectionTitle>Nauczyciele</SectionTitle>
        {
          isNotNilOrEmpty(teachers)
            ? (
              <Table>
                <thead>
                <tr>
                  <th>Nauczyciel</th>
                  <th>Aktualne saldo</th>
                  <th>Saldo ostatniego wpisu</th>
                </tr>
                </thead>
                <tbody>
                {
                  teachers.map(teacher => (
                    <tr key={teacher.id}>
                      <td>{teacher.name}</td>
                      <td>{teacher.teacherBalance}</td>
                      <td>{teacher.lastItemBalance}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            )
            : <div>Brak błędów</div>
        }
        </SectionWrapper>
        <SectionWrapper>
          <SectionTitle>Uczniowie</SectionTitle>
          {
            isNotNilOrEmpty(students)
              ? (
                <Table>
                  <thead>
                  <tr>
                    <th>Uczeń</th>
                    <th>Aktualne saldo</th>
                    <th>Saldo ostatniego wpisu</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    students.map(student => (
                      <tr key={student.id}>
                        <td>{student.name}</td>
                        <td>{student.studentBalance}</td>
                        <td>{student.lastItemBalance}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </Table>
              )
              : <div>Brak błędów</div>
          }
        </SectionWrapper>
      </Wrapper>
    </ExpandablePanel>
  )
}

export default CheckDataCorrectness

const Wrapper = styled.div`
  padding-top: 10px;
`

const SectionWrapper = styled.div`
  margin-bottom: 50px;
`

const SectionTitle = styled.div`
  font-size: 18px;
`
