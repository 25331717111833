import React from 'react'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Table from 'components/Table'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'

const ImportLessonResultTable = ({ events }) => {
  return isNotNilOrEmpty(events) ? (
    <Table>
      <thead>
        <tr>
          <th>Data</th>
          <th>Nazwa wydarzenia</th>
          <th>Błędy</th>
        </tr>
      </thead>
      <tbody>
      {
        events.map(event => (
          <tr>
            <td>{formatDate(propOr('---', 'startDate', event), DATE_FORMATS.dashed)}</td>
            <td>{event.title}</td>
            <td>
              {event.errors.map(error => <div>{error}</div>)}
            </td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  ) : null
}

export default ImportLessonResultTable
