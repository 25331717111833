import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Table from 'components/Table'
import { selectActiveStudents } from 'modules/students/ducks/selectors'
import StudentsTableRow from 'modules/students/components/StudentsTableRow'
import { isNilOrEmpty, isNotNil } from 'utils/ramda'
import styled from 'styled-components'
import Input from 'components/Input'
import SearchIcon from '@mui/icons-material/Search'
import { isNil } from 'ramda'

const StudentsTable = ({ studentsList, actionCallback }) => {
  const activeStudents = useSelector(selectActiveStudents)
  const list = isNotNil(studentsList) ? studentsList : activeStudents
  const [filtered, setFiltered] = useState(list)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const query = searchQuery.toLowerCase().trim()
    setFiltered(list.filter(student => {
      const name = student.name.toLowerCase()
      const email = student.email.toLowerCase()
      return name.includes(query) || email.includes(query)
    }))
  }, [searchQuery, list])

  return (
    <>
      {
        isNil(studentsList) && (
          <InputWrapper>
            <Input
              name='search'
              placeholder='Szukaj...'
              onChange={(_, value) => setSearchQuery(value)}
              value={searchQuery}
              startAdornment={<SearchIcon />}
            />
          </InputWrapper>
        )
      }
      <Table>
        <thead>
        <tr>
          <th>Lp.</th>
          <th>Nazwa</th>
          <th>Typ lekcji</th>
          <th>Przedmiot</th>
          <th>Nauczyciel</th>
          <th>Czas</th>
          <th>Stawka ucz.</th>
          <th>Stawka nau.</th>
          <th>Email</th>
          <th>Tel.</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {filtered.map((student, index) => (
          <StudentsTableRow
            actionCallback={actionCallback}
            isTeacherView={isNotNil(studentsList)}
            order={index + 1}
            student={student}
            key={`student-${student.id}`}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(filtered) && (
        <EmptyState>
          Brak uczniów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default StudentsTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`

const InputWrapper = styled.div`
  display: flex;

  div {
    max-width: 300px;
  }
`
