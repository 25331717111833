import React, { useEffect, useState } from 'react'
import AddLessonModal from 'modules/lessons/components/AddLessonModal'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import LessonsTable from 'modules/lessons/components/LessonsTable'
import { fetchSingleTeacherLessonsRoutine } from 'modules/teachers/ducks/actions'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import Input from 'components/Input'
import styled from 'styled-components'
import { changeQuery } from 'utils/navigation'

const defaultDates = {
  dateFrom: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input),
}

const TeacherDetailsLessons = () => {
  const teacher = useSelector(selectCurrentTeacher)
  const [dates, setDates] = useState(defaultDates)
  const dispatch = useDispatch()

  useEffect(() => {
    dates.dateFrom && teacher && dispatch(fetchSingleTeacherLessonsRoutine({
      id: teacher.id,
      ...dates
    }))
  }, [dates, teacher])

  const handleValueChange = (name, value) => {
    setDates(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    changeQuery(dates)
  }, [dates])

  return (
    <>
      <Header>
        <DateFilters>
          <Input
            type='date'
            label='Data od'
            name='dateFrom'
            onChange={handleValueChange}
            value={dates.dateFrom}
          />
          <Input
            type='date'
            label='Data do'
            name='dateTo'
            onChange={handleValueChange}
            value={dates.dateTo}
          />
        </DateFilters>
        <AddLessonModal teacher={teacher} />
      </Header>
      <LessonsTable />
    </>
  )
}

export default TeacherDetailsLessons

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`

const DateFilters = styled.div`
  display: flex;
  gap: 15px;
  flex: 1;
`
