import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { getArchivedStudentsRoutine } from 'modules/students/ducks/actions'
import ExpandablePanel from 'components/ExpandablePanel'
import ArchivedStudentsTable from 'modules/archivedStudents/compontents/ArchivedStudentsTable'

const ArchivedStudents = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getArchivedStudentsRoutine())
  }, [])

  return (
    <div>
      <PageHeader>
        Archiwum Uczniów
      </PageHeader>
      <ExpandablePanel startOpen title='Lista uczniów'>
        <ArchivedStudentsTable />
      </ExpandablePanel>
    </div>
  )
}

export default ArchivedStudents

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
