import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import Button from 'components/Button'
import { propOr } from 'ramda'
import { markStudentToBeDeleted } from 'services/StudentsService'

const MarkStudentToBeDeleted = ({ student, getStudents }) => {
  const toBeDeleted = propOr(false, 'toBeDeleted', student)
  const [open, setOpen] = useState(false)

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const successCallback = () => {
      getStudents()
      handleClose()
    }

    markStudentToBeDeleted({
      student,
      value: !toBeDeleted,
      callback: successCallback
    })
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        {toBeDeleted ? <StyledActiveIcon /> : <StyledIcon />}
      </Trigger>
      <Modal
        title='Oznacz ucznia'
        open={open}
        onClose={handleClose}
      >
        {
          toBeDeleted
            ? 'Czy chcesz cofnąć oznaczenie ucznia jako przeznaczonego do usunięcia?'
            : 'Czy chcesz oznaczyć ucznia jako przeznaczonego do usunięcia?'
        }
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>{toBeDeleted ? 'Odznacz' : 'Oznacz'}</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default MarkStudentToBeDeleted

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(CheckBoxOutlineBlankOutlinedIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const StyledActiveIcon = styled(CheckBoxOutlinedIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
