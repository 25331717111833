import React from 'react'
import TeacherDetailsStudents from 'modules/teacherDetails/components/TeacherDetailsStudents'
import TeacherDetailsLessons from 'modules/teacherDetails/components/TeacherDetailsLessons'
import Tabs from 'components/Tabs'
import styled from 'styled-components'
import TeacherDetailsDebitHistory from 'modules/teacherDetails/components/TeacherDetailsDebitHistory'

const TeacherDetailsTabs = () => {
  const tabs = [
    {
      key: 'students',
      title: 'Uczniowie',
      children: <TeacherDetailsStudents />
    },
    {
      key: 'lessons',
      title: 'Lekcje',
      children: <TeacherDetailsLessons />
    },
    // {
    //   key: 'expenses',
    //   title: 'Przychody/Wydatki',
    //   children: <TeacherDetailsExpenses />
    // },
    {
      key: 'debit',
      title: 'Historia salda',
      children: <TeacherDetailsDebitHistory />
    }
  ]

  return (
    <PanelWrapper>
      <Tabs tabs={tabs} />
    </PanelWrapper>
  )
}

export default TeacherDetailsTabs

const PanelWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`
