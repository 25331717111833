import React, { useEffect, useMemo, useState } from 'react'
import { loginUser } from 'services/AuthService'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import Input from 'components/Input'
import Button from 'components/Button'
import { values as ramdaValues } from 'ramda'
import appLogo from 'assets/app-logo.png'

const emptyValues = {
  email: '',
  password: ''
}

const Login = () => {
  const [values, setValues] = useState(emptyValues)
  const currentUser = useSelector(selectCurrentUser)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    loginUser(values)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(currentUser)) {
      ['admin', 'worker'].includes(currentUser.role)
        ? redirect(PATHS.teachers)
        : redirect(PATHS.dashboard)
    }
  }, [currentUser])

  const isValid = useMemo(() => {
    return ramdaValues(values).every(val => isNotNilOrEmpty(val))
  }, [values])

  return (
    <Wrapper>
      <Box>
        <LogoWrapper>
          <img src={appLogo} alt='logo' />
        </LogoWrapper>
        <form onSubmit={handleSubmit}>
          <Input
            label='Email'
            name='email'
            onChange={handleValueChange}
            value={values.email}
          />
          <Input
            type='password'
            label='Password'
            name='password'
            onChange={handleValueChange}
            value={values.password}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!isValid}
          >
            Zaloguj
          </Button>
        </form>
      </Box>
    </Wrapper>
  )
}

export default Login

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.lightGrey};
`

const Box = styled.div`
  max-width: 400px;
  width: 95%;
  box-shadow: ${({ theme }) => theme.shadows.main};
  padding: 20px;
  background: #fff;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  img {
    width: 80%;
  }
`
