import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal'
import Input from 'components/Input'
import { isNotNilOrEmpty } from 'utils/ramda'
import { updateTeacher } from 'services/TeachersService'
import Button from 'components/Button'
import styled from 'styled-components'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import { Tooltip } from '@mui/material'

const EditIncomesCommentModal = ({ teacher }) => {
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const dispatch = useDispatch()
  const hasComment = isNotNilOrEmpty(teacher.incomesComment)

  useEffect(() => {
    if (open) {
      if (hasComment) {
        setComment(teacher.incomesComment)
      } else {
        setComment('')
      }
    }
  }, [teacher, open])

  const handleUpdate = () => {
    updateTeacher({
      teacher: { id: teacher.id, incomesComment: comment },
      callback: () => {
        handleClose()
        dispatch(fetchTeachersListRoutine())
      }
    })
  }

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  return (
    <>
      {
        hasComment
          ? (
            <Tooltip title={<TooltipText>{comment}</TooltipText>}>
              <TriggerButton hasComment onClick={handleOpen} />
            </Tooltip>
          )
          : <TriggerButton onClick={handleOpen} />
      }

      <Modal
        title='Dodaj komentarz'
        open={open}
        onClose={handleClose}
      >
        <Input
          label='Komentarz'
          multiline
          rows={2}
          value={comment}
          name='comment'
          onChange={(_, value) => setComment(value)}
        />
        <ButtonWrapper>
          <Button onClick={handleUpdate}>
            Zapisz
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default EditIncomesCommentModal

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TriggerButton = styled(CommentOutlinedIcon)`
  cursor: pointer;
  font-size: 16px !important;
  color: ${({ theme, hasComment }) => hasComment ? theme.colors.primary.main : theme.colors.disabled};
`

const TooltipText = styled.div`
  font-size: 16px;
`
