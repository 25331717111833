import React from 'react'
import styled from 'styled-components'
import RestoreStudentModal from 'modules/archivedStudents/compontents/RestoreStudentModal'

const ArchivedStudentsTableRow = ({ student, order }) => {
  return (
    <Wrapper>
      <td>{order}</td>
      <td>{student.name}</td>
      <td>{student.email}</td>
      <td>{student.phoneNumber}</td>
      <td>
        <Actions onClick={e => e.stopPropagation()}>
          <RestoreStudentModal student={student} />
        </Actions>
      </td>
    </Wrapper>
  )
}

export default ArchivedStudentsTableRow

const Wrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
