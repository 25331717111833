import React, { useEffect } from 'react'
import styled from 'styled-components'
import ExpandablePanel from 'components/ExpandablePanel'
import { useDispatch } from 'react-redux'
import WorkersTable from 'modules/workers/components/WorkersTable'
import AddWorkerModal from 'modules/workers/components/AddWorkerModal'
import { fetchWorkersListRoutine } from 'modules/workers/ducks/actions'

const Teachers = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchWorkersListRoutine())
  }, [])

  return (
    <>
      <PageHeader>
        Pracownicy
      </PageHeader>
      <AddWorkerModal />
      <ExpandablePanel startOpen title='Lista pracowników'>
        <WorkersTable />
      </ExpandablePanel>
    </>
  )
}

export default Teachers

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
