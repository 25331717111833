import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { toast } from 'react-hot-toast'
import { sendEmail } from 'services/EmailService'
import { isEmpty, pathOr, propOr, uniq } from 'ramda'
import moment from 'moment'
import { isCashLesson, properHourVariation } from 'utils/lessons'
import { copyToClipboard, sortByDates } from 'utils/date'
import BackButton from 'components/BackButton'
import PATHS from 'utils/paths'
import styled from 'styled-components'
import Button from 'components/Button'
import { Collapse, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Input from 'components/Input'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { updateStudent } from 'services/StudentsService'
import { useDispatch, useSelector } from 'react-redux'
import { selectEmailTemplateSettings } from 'modules/settings/ducks/selectors'
import { fetchEmailTemplateSettingsRoutine } from 'modules/settings/ducks/actions'
import { parseNumber } from 'utils/numbers'

const Message = () => {
  const { location: { state = [] } } = useHistory()
  const [values, setValues] = useState()
  const [collapsed, setCollapsed] = useState([])
  const [sent, setSent] = useState(0)
  const [notSent, setNotSent] = useState([])
  const settings = useSelector(selectEmailTemplateSettings)
  const dispatch = useDispatch()

  useEffect(() => {
  }, [])

  useEffect(() => {
    isNilOrEmpty(state) && toast.error('Nie znaleziono studentów')
    dispatch(fetchEmailTemplateSettingsRoutine())
  }, [])

  const data = useMemo(() => {
    return state.map(student => {
      const dateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      const dateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      const lessons = propOr([], 'lessons', student).filter(lesson => !isCashLesson(lesson.lessonType))
      const studentLessons = lessons.filter(lesson => moment(lesson.date).isBetween(dateFrom, dateTo, 'days', '[]'))
      const lessonsAfterMonth = lessons.filter(lesson => moment(lesson.date).isAfter(dateTo, 'days'))

      const balance = studentLessons.reduce((acc, { duration, studentRate }) => {
        return acc + parseNumber(duration) * parseNumber(studentRate)
      }, 0)
      const balanceAfter = lessonsAfterMonth.reduce((acc, { duration, studentRate }) => {
        return acc + parseNumber(duration) * parseNumber(studentRate)
      }, 0)

      const totalDuration = studentLessons.reduce((acc, { duration }) => acc + parseNumber(duration), 0)

      const balanceForEndOfTheMonth = parseNumber(student.balance) + parseNumber(balanceAfter)
      const debit = balanceForEndOfTheMonth + balance
      console.log({ debit, balance, caseTest: debit > 0 && debit === balance })

      const subjects = uniq(studentLessons
        .filter(lesson => isNotNilOrEmpty(lesson.subject))
        .map(lesson => lesson.subject))

      const lessonsBySubjects = subjects.map(subject => {
        const lessons = studentLessons.filter(lesson => lesson.subject === subject)
        const balance = lessons.reduce((acc, { duration, studentRate }) => {
          return acc + parseNumber(duration) * parseNumber(studentRate)
        }, 0)
        return `${subject.split(' ')[0]} - ${sortByDates(lessons, true).map((lesson) => {
          return `${moment(lesson.date).format('DD')} (${lesson.duration}h)`
        }).join(`, `)}, czyli łącznie ${balance}zł.`
      }).sort((a, b) => a.localeCompare(b))


      const textMessage = () => {
        switch(true) {
          case student.balance >= 0:
          case balance - debit < 0:
          case debit > 0 && debit === balance:
            return `W ubiegłym miesiącu zajęcia odbyły się w dniach: ${sortByDates(studentLessons, true).map((lesson) => {
              return `${moment(lesson.date).format('DD')} (${lesson.duration}h)`
            }).join(`, `)}, co daje łącznie ${totalDuration} ${properHourVariation(totalDuration)}, czyli kwotę ${balance}zł.
${subjects.length > 1 ? `\n${lessonsBySubjects.join('\n')}\n` : ''}
Po odjęciu dokonanych wpłat lub rabatu (bądź ew. nadpłaty z poprzedniego miesiąca) nic nie pozostaje do zapłaty.`
          case debit > 0:
            return `W ubiegłym miesiącu zajęcia odbyły się w dniach: ${sortByDates(studentLessons, true).map((lesson) => {
              return `${moment(lesson.date).format('DD')} (${lesson.duration}h)`
            }).join(`, `)}, co daje łącznie ${totalDuration} ${properHourVariation(totalDuration)}, czyli kwotę ${balance}zł.
${subjects.length > 1 ? `\n${lessonsBySubjects.join('\n')}\n` : ''}
Po odjęciu dokonanych wpłat lub rabatu (bądź ew. nadpłaty z poprzedniego miesiąca) o wartości ${debit}zł, do zapłaty pozostaje ${balance - debit}zł.`
          case debit < 0:
            return `W ubiegłym miesiącu zajęcia odbyły się w dniach: ${sortByDates(studentLessons, true).map((lesson) => {
              return `${moment(lesson.date).format('DD')} (${lesson.duration}h)`
            }).join(`, `)}, co daje łącznie ${totalDuration} ${properHourVariation(totalDuration)}, czyli kwotę ${balance}zł.
${subjects.length > 1 ? `\n${lessonsBySubjects.join('\n')}\n` : ''}
Po uwzględnieniu wcześniejszej niedopłaty w wysokości ${-debit}zł, do zapłaty jest łącznie ${balance - debit}zł. 
Jeśli zaszła pomyłka i niedopłata nie zaistniała, a jest to jedynie nasz błąd obliczeniowy, bardzo przepraszamy i prosimy o informację.`
          default:
            return `W ubiegłym miesiącu zajęcia odbyły się w dniach: ${sortByDates(studentLessons, true).map((lesson) => {
              return `${moment(lesson.date).format('DD')} (${lesson.duration}h)`
            }).join(`, `)}, co daje łącznie ${totalDuration} ${properHourVariation(totalDuration)}, czyli kwotę ${balance}zł.
${subjects.length > 1 ? `\n${lessonsBySubjects.join('\n')}` : ''}`
        }
      }
      return {
        ...student,
        message: (
        `${settings.header}
        
${textMessage().trim()}
        
${settings.footer}`
        )
      }
    })
  }, [settings, state])

  useEffect(() => {
    setValues(data.map(student => ({
      ...student,
      title: `Rozliczenie za miesiąc ${moment().subtract(1, 'month').format('MMMM')}`,
      message: student.message
    })))
    setCollapsed(data.map(() => false))
  }, [data])

  const handleValueChange = (name, value, index) => {
    setValues(prev => {
      const copy = [ ...prev ]
      copy[index] = {
        ...copy[index],
        [name]: value
      }
      return copy
    })
  }

  const handleTogglePanel = index => {
    setCollapsed(prev => {
      const old = [ ...prev ]
      old[index] = !old[index]
      return old
    })
  }

  const handleSuccess = student => () => {
    setSent(prev => prev + 1)
    const foundStudent = data.find(s => s.id === student.id)
    updateStudent({
      student: {
        ...student,
        email: foundStudent.email,
        lastMessageDate: moment().format('DD-MM-YYYY'),
        lastMessage: student.message
      }
    })
  }

  const handleFailure = student => () => {
    setNotSent(prev => [ ...prev, student.name ])
  }

  const sendEmails = () => {
    values.forEach((student, index) => {
      setTimeout(() => {
        sendEmail({
          students: [student.email],
          message: student.message,
          title: student.title,
          onSuccess: handleSuccess(student),
          onError: handleFailure(student)
          }
        )
      }, index * 1000)
    })
  }

  const phoneNumbers = useMemo(() => {
    let numbers = ''
    if (!isEmpty(data)) {
      data
        .filter(student => !notSent.includes(student.name))
        .forEach((student, index) => {
          const cleanNumber = propOr('', 'phoneNumber', student).replace(' ', '')
          const startOfTheString = index === 0 ? '' : ' ; '
          numbers += `${startOfTheString}${cleanNumber}`
        })
    }
    return numbers
  }, [data, notSent])

  return (
    <>
      <SendButtonWrapper>
        <BackButton path={PATHS.debtors} label='Powrót do listy dłużnków' />
        <RightSideWrapper>
          <SentWrapper>Wysłano: {sent}/{state.length}</SentWrapper>
          <Button color='primary' disabled={sent > 0} onClick={sendEmails}>Wyślij</Button>
        </RightSideWrapper>
      </SendButtonWrapper>
      {
        sent + notSent.length === state.length && (
          <PhoneNumbersWrapper>
            <PhoneNumbersTitle>
              Numery telefonów
              <Tooltip title='skopiuj'>
                <ContentCopyIcon onClick={copyToClipboard(phoneNumbers)} />
              </Tooltip>
            </PhoneNumbersTitle>
            {phoneNumbers}
          </PhoneNumbersWrapper>
        )
      }
      {
        data.map((student, index) => (
          <Wrapper key={student.id}>
            <Header>
              <StudentName onClick={() => handleTogglePanel(index)}>
                {propOr('', 'name', student)}
              </StudentName>
              <StyledExpandIcon open={collapsed[index]} />
            </Header>
            <Collapse in={collapsed[index]}>
              <>
                <Row>
                  <Input
                    value={pathOr('', [index, 'email'], values)}
                    onChange={(name, value) => handleValueChange(name, value, index)}
                    label='Email'
                    name='email'
                  />
                  <Input
                    value={pathOr('', [index, 'title'], values)}
                    onChange={(name, value) => handleValueChange(name, value, index)}
                    label='Tytuł'
                    name='title'
                  />
                </Row>
                <Input
                  value={pathOr('', [index, 'message'], values)}
                  onChange={(name, value) => handleValueChange(name, value, index)}
                  label='Wiadomość'
                  name='message'
                  multiline
                  rows={7}
                />
              </>
            </Collapse>
          </Wrapper>
        ))
      }
    </>
  )
}

export default Message


const StudentName = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
`

const Wrapper = styled.div`
  margin-bottom: 20px;
  background-color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  & > div {
    width: 49%;
  }
`

const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px;
  width: 100%;
  align-items: center;
`

const SentWrapper = styled.div`
  margin-right: 30px;
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.darkGrey};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PhoneNumbersWrapper = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
`

const PhoneNumbersTitle = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  
  & > * {
    margin-left: 15px;
  }
`
