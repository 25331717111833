import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BackButton from 'components/BackButton'
import PATHS from 'utils/paths'
import { clearSingleStudentRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import StudentBasicDetails from 'modules/studentDetails/components/StudentBasicDetails'
import StudentDetailsTabs from 'modules/studentDetails/components/StudentDetailsTabs'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'

const StudentDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    id && dispatch(fetchSingleStudentRoutine({ id }))
    dispatch(fetchTeachersListRoutine())
  }, [id])

  useEffect(() => {
    dispatch(clearSingleStudentRoutine())
  }, [])

  return (
    <>
      <BackButton
        label='Powrót do listy uczniów'
        path={PATHS.students}
        withMargin
      />
      <StudentBasicDetails />
      <StudentDetailsTabs />
    </>
  )
}

export default StudentDetails
