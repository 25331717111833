import React, { useMemo } from 'react'
import Table from 'components/Table'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import MyStudentsTableRow from 'modules/myStudents/components/MyStudentsTableRow'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { isCashLesson } from 'utils/lessons'

const MyStudentsTable = ({ students, getStudents }) => {
  const user = useSelector(selectCurrentTeacher)

  const hasCashStudents = useMemo(() => {
    if (isNotNilOrEmpty(students)) {
      return students.some(student => {
        const studentTeachers = propOr([], 'teachers', student)
        return studentTeachers.some(teacher => {
          return teacher.id === user.id && isCashLesson(teacher.lessonType)
        })
      })
    } else {
      return false
    }
  }, [user, students])

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Lp.</th>
          <th>Nazwa</th>
          <th>Typ lekcji</th>
          <th>Czas</th>
          {hasCashStudents && <th>Stawka ucznia</th>}
          <th>{hasCashStudents ? 'Stawka nauczyciela' : 'Stawka'}</th>
          <th>Do usnięcia?</th>
        </tr>
        </thead>
        <tbody>
        {students && students.map((student, index) => (
          <MyStudentsTableRow
            hasCashStudents={hasCashStudents}
            order={index + 1}
            student={student}
            key={`student-${student.id}`}
            getStudents={getStudents}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(students) && (
        <EmptyState>
          Brak uczniów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default MyStudentsTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
