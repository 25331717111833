import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'

const DeleteStudentTeacherModal = ({ onDeleteTeacher, teacher, index }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDelete = () => {
    onDeleteTeacher(index)
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        open={open}
        onClose={handleClose}
        title='Usuń przypisanie'
      >
        Czy na pewno chcesz usunąć przypisanie nauczyciela <TeacherName>{teacher.name}</TeacherName>?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleDelete}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeleteStudentTeacherModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

const TeacherName = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
`
