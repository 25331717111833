import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { removeStudentPayment } from 'services/StudentsService'
import { propOr } from 'ramda'
import { getCurrentParsedQuery } from 'utils/navigation'
import {
  fetchSingleStudentDebitItemsRoutine,
  fetchSingleStudentRoutine
} from 'modules/students/ducks/actions'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import { parseNumber } from 'utils/numbers'

const DeletePaymentModal = ({ payment }) => {
  const student = useSelector(selectCurrentStudent)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const studentPayload = {
      ...student,
      balance: parseNumber(propOr(0, 'balance', student)) - parseNumber(payment.value)
    }

    const successCallback = () => {
      const query = getCurrentParsedQuery()
      dispatch(fetchSingleStudentDebitItemsRoutine({ id: student.id, ...query }))
      dispatch(fetchSingleStudentRoutine({ id: student.id }))
      handleClose()
    }

    removeStudentPayment({
      values: {
        studentPayload,
        debitPayload: payment
      },
      callback: successCallback
    })
  }


  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Usuń wpłatę'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz usunąć tę wpłatę?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeletePaymentModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
