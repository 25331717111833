import { db } from 'firestoreConfig'
import { pathOr, reverse } from 'ramda'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { updatePreviousTeacherDebitItems } from 'services/LessonsService'
import { parseNumber } from 'utils/numbers'

export const addExpense = async ({ values, callback }) => {
  const { teacherDebitPayload, teacherPayload, expensePayload } = values
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const expenseRef = db.collection('expenses').doc(expensePayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)

  const prevTeacherDebitSnapshot = await db
    .collection('teacherDebit')
    .where('teacherId', '==', teacherDebitPayload.teacherId)
    .where('date', '<', teacherDebitPayload.date)
    .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
    .get()

  const prepareTeacherDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    if (!prevTeacherDebitSnapshot.empty) {
      prevTeacherDebitSnapshot.forEach(doc => {
        debitsData = [...debitsData, doc.data()]
        if (index === prevTeacherDebitSnapshot.docs.length - 1) {
          resolve(debitsData)
        }
        index++
      })
    } else {
      return resolve([])
    }
  })

  prepareTeacherDebitItems
    .then(async teacherDebitItems => {
      const batch = db.batch()
      const sortedTeacherDebitItems = reverse(teacherDebitItems.sort((a, b) => a.date - b.date))
      const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedTeacherDebitItems))
      const teacherDebitFullPayload = {
        ...teacherDebitPayload,
        balanceAfter: parseNumber(lastBalance) + parseNumber(teacherDebitPayload.value)
      }

      batch.set(expenseRef, expensePayload)
      batch.set(teacherDebitRef, teacherDebitFullPayload)
      batch.update(teacherRef, teacherPayload)

      batch.commit()
        .then(() => {
          updatePreviousTeacherDebitItems({ debit: teacherDebitFullPayload })
          toast.success('Dodano nowy wydatek/przychód')
          typeof callback === 'function' && callback()
        })
        .catch((error) => {
          toast.error('Wystąpił błąd podczas dodawania wydatku/przychodu')
          console.error("Transakcja nie powiodła się: ", error)
        })
    })
}

export const updateExpense = async ({ values, callback }) => {
  const { teacherDebitPayload, teacherPayload, expensePayload, difference } = values
  const batch = db.batch()

  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const expenseRef = db.collection('expenses').doc(expensePayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)

  batch.update(expenseRef, expensePayload)
  batch.update(teacherDebitRef, teacherDebitPayload)
  batch.update(teacherRef, teacherPayload)

  batch.commit()
    .then(() => {
      updatePreviousTeacherDebitItems({ debit: teacherDebitPayload, lessonDifference: difference * -1 })
      toast.success('Zaktualizowano wydatek/przychód')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas edycji wydatku/przychodu')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const removeExpense = async ({ values, callback }) => {
  const { teacherDebit, teacher, expenseId } = values
  const batch = db.batch()

  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebit.id)
  const expenseRef = db.collection('expenses').doc(expenseId)
  const teacherRef = db.collection('teachers').doc(teacher.id)

  batch.delete(expenseRef)
  batch.delete(teacherDebitRef)
  batch.update(teacherRef, teacher)

  batch.commit()
    .then(() => {
      updatePreviousTeacherDebitItems({
        debit: teacherDebit,
        lessonDifference: parseNumber(teacherDebit.value) * -1
      })
      toast.success('Usunięto wydatek/przychód')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Nie udało się usunąć wydatku/przychodu')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const getExpensesForAllTeachers = async ({ dateFrom, dateTo, setFn }) => {
  const snapshot = await db
    .collection('expenses')
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    setFn([])
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    setFn(data)
  }
}
