import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearSingleTeacherRoutine, fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import TeacherBasicDetails from 'modules/teacherDetails/components/TeacherBasicDetails'
import BackButton from 'components/BackButton'
import PATHS from 'utils/paths'
import TeacherDetailsTabs from 'modules/teacherDetails/components/TeacherDetailsTabs'
import AddStudentForm from 'modules/students/components/AddStudentForm'

const TeacherDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const fetchTeacher = () => {
    dispatch(fetchSingleTeacherRoutine({ id }))
  }

  useEffect(() => {
    dispatch(clearSingleTeacherRoutine())
  }, [])

  useEffect(() => {
    id && fetchTeacher()
  }, [id])

  return (
    <>
      <BackButton
        label='Powrót do listy nauczycieli'
        path={PATHS.teachers}
        withMargin
      />
      <TeacherBasicDetails />
      <AddStudentForm callback={fetchTeacher} />
      <TeacherDetailsTabs />
    </>
  )
}

export default TeacherDetails
