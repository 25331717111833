import React from 'react'
import Table from 'components/Table'
import { useSelector } from 'react-redux'
import { prop, propOr, reverse, sortBy } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import styled from 'styled-components'
import { selectCurrentWorkerIncomeItems } from 'modules/workers/ducks/selectors'
import DeleteWorkerIncomeModal from 'modules/workerDetails/components/DeleteWorkerIncomeModal'
import EditWorkerIncomeModal from 'modules/workerDetails/components/EditWorkerIncomeModal'
import { isNilOrEmpty } from 'utils/ramda'

const WorkerIncomeTable = () => {
  const list = useSelector(selectCurrentWorkerIncomeItems)
  const sorted = reverse(sortBy(prop('date'), list))

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Data</th>
          <th>Kwota</th>
          <th>Komentarz</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          sorted.map(item => (
            <tr key={item.id}>
              <td>{formatDate(item.date, DATE_FORMATS.dashed)}</td>
              <td>{propOr('---', 'value', item)}</td>
              <td>{propOr('---', 'comment', item)}</td>
              <td>
                <Actions onClick={e => e.stopPropagation()}>
                  <EditWorkerIncomeModal income={item} />
                  <DeleteWorkerIncomeModal income={item} />
                </Actions>
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
      {isNilOrEmpty(list) && (
        <EmptyState>
          Brak wpisów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default WorkerIncomeTable

const Actions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
