import React, { useEffect, useMemo, useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import Input from 'components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmailTemplateSettingsRoutine } from 'modules/settings/ducks/actions'
import { selectEmailTemplateSettings } from 'modules/settings/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { editEmailTemplateSettings } from 'services/SettingsService'
import { toast } from 'react-hot-toast'
import styled from 'styled-components'
import Button from 'components/Button'
import { values as ramdaValues } from 'ramda'

const initialValues = {
  header: '',
  footer: ''
}

const EmailTemplatePanel = () => {
  const [values, setValues] = useState(initialValues)
  const settings = useSelector(selectEmailTemplateSettings)
  const dispatch = useDispatch()

  useEffect(() => {
    isNotNilOrEmpty(settings) && setValues(settings)
  }, [settings])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    dispatch(fetchEmailTemplateSettingsRoutine())
  }, [])

  const handleSubmit = () => {
    editEmailTemplateSettings(values)
      .then(() => {
        toast.success('Pomyślnie zapisano zmiany')
      })
      .catch(() => {
        toast.error('Nie udało się zapisać zmian')
      })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(values)
    return toCheck.every(val => isNotNilOrEmpty(val))
  }, [values])

  return (
    <ExpandablePanel title='Wiadomość email'>
      <Input
        multiline
        rows={6}
        label='Wstęp'
        name='header'
        onChange={handleValueChange}
        value={values.header}
      />
      <Input
        multiline
        rows={6}
        label='Zakończenie'
        name='footer'
        onChange={handleValueChange}
        value={values.footer}
      />
      <ButtonWrapper>
        <Button
          color='primary'
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Zapisz
        </Button>
      </ButtonWrapper>
    </ExpandablePanel>
  )
}

export default EmailTemplatePanel

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 200px;
  }
`
