import { db } from 'firestoreConfig'
import { propOr, sortBy, prop, reverse } from 'ramda'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { toast } from 'react-hot-toast'

export const fetchSchoolSubjects = () => {
  return db
    .collection('settings')
    .doc('schoolSubjects')
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        return propOr([], 'entries', data)
      } else {
        console.log('Something went wrong')
      }
    })
    .catch(error => {
      console.log('Error getting document:', error)
    })
}

export const editSchoolSubjects = (values, callback) => {
  const objectRef = db.collection('settings').doc('schoolSubjects')

  return objectRef.update(values)
    .then(function () {
      typeof callback === 'function' && callback()
    })
    .catch(function (error) {
      console.error('Error updating document: ', error)
    })
}

export const getEmailTemplateSettings = () => {
  return db
    .collection('settings')
    .doc('email')
    .get()
    .then(async doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log('Something went wrong')
      }
    })
    .catch(error => {
      console.log('Error getting document:', error)
    })
}

export const editEmailTemplateSettings = (values, callback) => {
  const objectRef = db.collection('settings').doc('email')

  return objectRef.update(values)
    .then(function () {
      typeof callback === 'function' && callback()
    })
    .catch(function (error) {
      console.error('Error updating document: ', error)
    })
}

export const getLogs = async () => {
  const dateFrom = moment().subtract(3, 'day').format(DATE_FORMATS.input)

  const snapshot = await db
    .collection('logs')
    .where('createdAt', '>=', dateFrom)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return reverse(sortBy(prop('createdAt'), data))
  }
}

export const getBlockData = async () => {
  const ref = await db.collection('settings').doc('block')
  const empty = {
    message: '',
    isBlocked: false
  }

  return ref
    .get()
    .then(async doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        ref.set(empty)
        return empty
      }
    })
}

export const setBlockData = async values => {
  const ref = await db.collection('settings').doc('block')
  ref
    .set(values)
    .then(() => {
      toast.success('Zapisano zmiany')
    })
    .catch(err => {
      console.log({ err })
    })
}
