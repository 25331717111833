import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import EditStudentTeacherForm from 'modules/students/components/EditStudentTeacherForm'
import EditIcon from '@mui/icons-material/Edit'

const EditStudentTeacherModal = ({ onEditTeacher, teacher, index }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        open={open}
        onClose={handleClose}
        title='Edytuj nauczyciela'
      >
        <EditStudentTeacherForm
          teacher={teacher}
          index={index}
          handleCloseModal={handleClose}
          onEditTeacher={onEditTeacher}
        />
      </Modal>
    </>
  )
}

export default EditStudentTeacherModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
