import React, { useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import { addTeacher } from 'services/TeachersService'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import Input from 'components/Input'
import styled from 'styled-components'
import Button from 'components/Button'
import { DATE_FORMATS } from 'utils/date'
import moment from 'moment'
import { isNilOrEmpty } from 'utils/ramda'
import Select from 'components/Select'
import { TEACHER_PAYMENT_TYPES_OPTIONS } from 'utils/teacher'
import { pathOr, propOr } from 'ramda'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

const emptyValues = {
  name: '',
  email: '',
  phoneNumber: '',
  password: 'Smart1!',
  role: 'teacher',
  removed: false,
  balance: 0,
  paymentType: '',
  calendarId: '',
  rates: []
}

const AddTeacherForm = () => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const fetchTeachersList = () => {
    dispatch(fetchTeachersListRoutine())
    setValues(emptyValues)
  }

  const handleSubmit = e => {
    e.preventDefault()
    addTeacher({
      values: {
        ...values,
        name: values.name.trim(),
        calendarId: values.calendarId.trim(),
        email: values.email.trim(),
        phoneNumber: values.phoneNumber.trim(),
        createdAt: moment().format(DATE_FORMATS.withTimeAndSeconds)
      },
      callback: fetchTeachersList
    })
  }

  const handleRateValueChange = index => (name, value) => {
    const current = {
      ...pathOr({}, ['rates', index], values),
      [name]: value
    }
    const ratesCopy = [...values.rates]
    ratesCopy[index] = current
    handleValueChange('rates', ratesCopy)
  }

  const handleAddRate = () => {
    handleValueChange('rates', [
      ...propOr([], 'rates', values),
      { name: '', rate: '' }
    ])
  }

  const handleDeleteRate = index => () => {
    handleValueChange(
      'rates',
      values.rates.filter((_, idx) => idx !== index)
    )
  }

  return (
    <ExpandablePanel title='Nowy nauczyciel'>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Input
            label='Nazwa'
            name='name'
            onChange={handleValueChange}
            value={values.name}
          />
          <Input
            label='Email'
            name='email'
            onChange={handleValueChange}
            value={values.email}
          />
          <Input
            label='Numer telefonu'
            name='phoneNumber'
            onChange={handleValueChange}
            value={values.phoneNumber}
          />
        </Row>
        <Row>
          <Input
            label='ID kalendarza'
            name='calendarId'
            onChange={handleValueChange}
            value={values.calendarId}
          />
          <Select
            name='paymentType'
            options={TEACHER_PAYMENT_TYPES_OPTIONS}
            label='Typ rozliczania'
            onChange={handleValueChange}
            value={values.paymentType}
          />
        </Row>
        <RatesWrapper>
          <RatesHeader>
            <RatesSectionTitle>Stawki nauczyciela</RatesSectionTitle>
            <AddRate onClick={handleAddRate}>Dodaj stawkę</AddRate>
          </RatesHeader>
          {propOr([], 'rates', values).map((rate, index) => {
            return (
              <RateForm key={`rate-${index}`}>
                <Input
                  name='name'
                  label='Nazwa stawki'
                  value={pathOr('', ['rates', index, 'name'], values)}
                  onChange={handleRateValueChange(index)}
                />
                <Input
                  name='rate'
                  type='number'
                  label='Kwota'
                  value={pathOr('', ['rates', index, 'rate'], values)}
                  onChange={handleRateValueChange(index)}
                />
                <DeleteIcon onClick={handleDeleteRate(index)} />
              </RateForm>
            )
          })}
        </RatesWrapper>
        <ButtonWrapper>
          <Button
            type='submit'
            color='primary-outline'
            disabled={isNilOrEmpty(values.name)}
          >
            Dodaj nauczyciela
          </Button>
        </ButtonWrapper>
      </Form>
    </ExpandablePanel>
  )
}

export default AddTeacherForm

const Form = styled.form`
  margin-top: 10px;
  width: 100%;

  input {
    width: 100%;
  }
`

const Row = styled.div`
  gap: 10px;
  display: flex;
`

const ButtonWrapper = styled.div`
  min-width: 200px;
`


const RatesWrapper = styled.div`
  max-width: 400px;
`

const RateForm = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const RatesSectionTitle = styled.div`
`

const AddRate = styled.div`
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  margin: 0 0 0 auto;
`

const DeleteIcon = styled(RemoveCircleOutlineIcon)`
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const RatesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
