import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { dissoc, propOr, values as ramdaValues } from 'ramda'
import Input from 'components/Input'
import Button from 'components/Button'
import {
  fetchSingleTeacherExpensesRoutine,
  fetchSingleTeacherRoutine
} from 'modules/teachers/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentParsedQuery } from 'utils/navigation'
import { getSingleTeacherDebitItem } from 'services/LessonsService'
import EditIcon from '@mui/icons-material/Edit'
import { isNotNilOrEmpty } from 'utils/ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { updateExpense } from 'services/ExpensesService'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  title: '',
  value: 0
}

const EditTeacherExpenseModal = ({ expense }) => {
  const teacher = useSelector(selectCurrentTeacher)
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [teacherDebit, setTeacherDebit] = useState({})
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (isNotNilOrEmpty(expense) && open) {
      setValues(expense)
      getSingleTeacherDebitItem(expense.teacherDebitId, setTeacherDebit)
    }
  }, [expense, open])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const successCallback = () => {
    const query = getCurrentParsedQuery()
    dispatch(fetchSingleTeacherExpensesRoutine({ id: expense.teacherId, ...query }))
    dispatch(fetchSingleTeacherRoutine({ id: expense.teacherId }))
    handleClose()
  }

  const handleSubmit = e => {
    e.preventDefault()
    const amount = parseNumber(values.value)
    const oldValue =  parseNumber(expense.value)
    const difference = oldValue - amount

    const expensePayload = {
      ...expense,
      ...values,
      value: amount
    }

    const teacherDebitPayload = {
      ...teacherDebit,
      value: amount,
      balanceAfter: propOr(0, 'balanceAfter', teacherDebit) - difference
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) - difference
    }

    updateExpense({
      values: {
        difference,
        expensePayload,
        teacherDebitPayload,
        teacherPayload
      },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val))
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj wydatek/przychód'
        open={open}
        onClose={handleClose}
      >
        <BasicDetails>
          <div><span>Data:</span> {formatDate(expense.date, DATE_FORMATS.dashedWithTime)}</div>
        </BasicDetails>
        <form onSubmit={handleSubmit}>
          <Input
            label='Opis'
            name='title'
            onChange={handleValueChange}
            value={values.title}
          />
          <Input
            type='number'
            label='Kwota'
            name='value'
            onChange={handleValueChange}
            value={values.value}
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button
              type='submit'
              color='primary'
              disabled={!isValid}
            >
              Zapisz
            </Button>
          </ButtonsWrapper>
        </form>
      </Modal>
    </>
  )
}

export default EditTeacherExpenseModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 15px;
  
  button {
    max-width: 200px;
  }
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const BasicDetails = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  padding: 14px;
  border-radius: 4px;
  text-transform: uppercase;
  
  span {
    text-transform: none;
  }
  
  & > div {
    display: flex;
    padding: 3px;
    align-items: center;
    justify-content: space-between;
  }
`
