import firebase, { db } from '../firestoreConfig'
import { redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import { toast } from 'react-hot-toast'

export const loginUser = ({ email, password }) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      const user = firebase.auth().currentUser
      if (user) {
        return db
          .collection('teachers')
          .doc(user.uid)
          .get()
          .then(async doc => {
            if (doc.exists) {
              const data = doc.data()
              data.role === 'admin'
                ? redirect(PATHS.teachers)
                : redirect(PATHS.dashboard)
              return data
            } else {
              return db
                .collection('workers')
                .doc(user.uid)
                .get()
                .then(async doc => {
                  if (doc.exists) {
                    const data = doc.data()
                    redirect(PATHS.teachers)
                    return data
                  }
                })
                .catch(error => {
                  console.log('Error getting document:', error)
                })
            }
          })
          .catch(error => {
            console.log('Error getting document:', error)
          })
      }})
    .catch(error => {
      toast.error('Nie udało się zalogować.')
      console.error(error)
    })
}

export const findCurrentUser = handleUserChange => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      return db
        .collection('teachers')
        .doc(user.uid)
        .get()
        .then(async doc => {
          if (doc.exists) {
            const data = doc.data()
            localStorage.setItem('currentUser', data.name)
            handleUserChange(data)
          } else {
            return db
              .collection('workers')
              .doc(user.uid)
              .get()
              .then(async doc => {
                if (doc.exists) {
                  const data = doc.data()
                  handleUserChange(data)
                }
              })
              .catch(error => {
                console.log('Error getting document:', error)
                redirect(PATHS.login)
              })
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
          redirect(PATHS.login)
        })
    } else {
      redirect(PATHS.login)
    }
  })
}

export const logoutUser = clearUser => {
  firebase.auth().signOut().then(() => {
    clearUser()
    localStorage.removeItem('currentUser')
  }).catch(error => {
    console.error(error)
  })
  redirect(PATHS.login)
}

export const changeUserPassword = (newPassword, callback) => {
  const user = firebase.auth().currentUser

  user.updatePassword(newPassword).then(function() {
    toast.success('Hasło zostało zmienione')
    typeof callback === 'function' && callback()
  }).catch(function(error) {
    toast.error(error.message)
  })
}
