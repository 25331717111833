import { filter, pipe, prop, propOr, sortBy } from 'ramda'
import { createSelector } from 'reselect'

export const selectStudents = state => state.students

export const selectActiveStudents = createSelector(
  selectStudents,
  pipe(
    propOr([], 'list'),
    filter(item => !item.removed),
    sortBy(prop('name'))
  )
)

export const selectRemovedStudents = createSelector(
  selectStudents,
  propOr([], 'archivedList')
)

export const selectCurrentStudent = createSelector(
  selectStudents,
  propOr({}, 'currentStudent')
)

export const selectCurrentStudentLessons = createSelector(
    selectStudents,
  propOr([], 'currentStudentLessons')
)

export const selectCurrentStudentDebitItems = createSelector(
  selectStudents,
  propOr([], 'currentStudentDebitItems')
)
