const theme = {
  colors: {
    primary: {
      main: '#3F497F',
      light: '#8791c4',
      dark: '#2a3155'
    },
    secondary: {
      main: '#F7C04A',
      light: '#fce6b6',
      dark: '#ab7707'
    },
    text: '#333333',
    lightGrey: '#F2F2F2',
    disabled: '#CCCCCC',
    border: '#CCCCCC',
    darkGrey: '#333333',
    background: '#F9FAFD',
    error: '#FF4747',
    success: '#00cc66',
  },
  dimensions: {
    topBarHeight: '81px',
    sideBarWidth: '336px'
  },
  shadows: {
    main: 'rgb(37 38 94 / 10%) 4px 0 20px 0;'
  }
}

export default theme
