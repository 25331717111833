import React, { useMemo } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { LESSON_TYPES } from 'utils/lessons'
import DeleteStudentModal from 'modules/students/components/DeleteStudentModal'
import EditStudentModal from 'modules/students/components/EditStudentModal'
import { redirect } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from 'utils/date'
import { Tooltip } from '@mui/material'

const StudentsTableRow = ({ student, order, isTeacherView, actionCallback }) => {
  const toBeDeleted = propOr(false, 'toBeDeleted', student)
  const lessonTypes = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{LESSON_TYPES[t.lessonType]}</div>)}</>
  }, [student])

  const subjects = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{t.subject}</div>)}</>
  }, [student])

  const teachers = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{t.name}</div>)}</>
  }, [student])

  const teacherRates = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{t.teacherRate}</div>)}</>
  }, [student])

  const studentRates = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{t.studentRate}</div>)}</>
  }, [student])

  const lessonDurations = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
    return <>{studentTeachers.map(t => <div>{t.lessonDuration}</div>)}</>
  }, [student])

  return (
    <Wrapper
      toBeDeleted={toBeDeleted}
      onClick={() => redirect(`/students/${student.id}`)}
    >
      <td>{order}</td>
      <td>
        <StudentName>
          {student.name}
          <Tooltip title='Skopiuj nazwę ucznia'>
            <CopyIcon onClick={e => {
              e.stopPropagation()
              copyToClipboard(student.name.trim())()
            }} />
          </Tooltip>
        </StudentName>
      </td>
      <td>{lessonTypes}</td>
      <td>{subjects}</td>
      <td>{teachers}</td>
      <td>{lessonDurations}</td>
      <td>{studentRates}</td>
      <td>{teacherRates}</td>
      <td>{isNotNilOrEmpty(student.email) ? '✔' : '✖'}</td>
      <td>{isNotNilOrEmpty(student.phoneNumber) ? '✔' : '✖'}</td>
      <td>
        <Actions onClick={e => e.stopPropagation()}>
          <EditStudentModal student={student} isTeacherView={isTeacherView} actionCallback={actionCallback} />
          <DeleteStudentModal student={student} actionCallback={actionCallback} />
        </Actions>
      </td>
    </Wrapper>
  )
}

export default StudentsTableRow

const Wrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;
  color: ${({ toBeDeleted, theme }) => toBeDeleted ? theme.colors.error : 'default'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const CopyIcon = styled(ContentCopyIcon)`
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 5px;
`

const StudentName = styled.div`
  display: flex;
  align-items: center;
`
