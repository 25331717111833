import React from 'react'
import { useSelector } from 'react-redux'
import Table from 'components/Table'
import { selectRemovedStudents } from 'modules/students/ducks/selectors'
import ArchivedStudentsTableRow from 'modules/archivedStudents/compontents/ArchivedStudentsTableRow'
import styled from 'styled-components'
import { isNilOrEmpty } from 'utils/ramda'

const ArchivedStudentsTable = () => {
  const students = useSelector(selectRemovedStudents)

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Lp.</th>
          <th>Nazwa</th>
          <th>Email</th>
          <th>Tel.</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {students.map((student, index) => (
          <ArchivedStudentsTableRow
            order={index + 1}
            student={student}
            key={`student-${student.id}`}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(students) && (
        <EmptyState>
          Brak uczniów do wyświetlenia
        </EmptyState>
      )}
    </>

  )
}

export default ArchivedStudentsTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
