import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BackButton from 'components/BackButton'
import PATHS from 'utils/paths'
import { fetchSingleWorkerRoutine } from 'modules/workers/ducks/actions'
import WorkerBasicDetails from 'modules/workerDetails/components/WorkerBasicDetails'
import WorkerDebitHistory from 'modules/workerDetails/components/WorkerDebitHistory'

const WorkerDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    id && dispatch(fetchSingleWorkerRoutine({ id }))
  }, [id])

  return (
    <>
      <BackButton
        label='Powrót do listy pracowników'
        path={PATHS.workers}
        withMargin
      />
      <WorkerBasicDetails />
      <WorkerDebitHistory />
    </>
  )
}

export default WorkerDetails
