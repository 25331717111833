import React, { useEffect, useState } from 'react'
import { getLogs } from 'services/SettingsService'
import Table from 'components/Table'
import { DATE_FORMATS, formatDate } from 'utils/date'
import ExpandablePanel from 'components/ExpandablePanel'
import { pathOr } from 'ramda'

const TYPES = {
  addLesson: 'Dodanie lekcji',
  updateLesson: 'Edycja lekcji',
  removeLesson: 'Usunięcie lekcji',
  addExpense: 'Dodanie rozliczenia',
  updateExpense: 'Edycja rozliczenia',
  removeExpense: 'Usunięcie rozliczenia',
  addTeacherSalary: 'Dodanie wypłaty nauczyciela',
  updateTeacherSalary: 'Edycja wypłaty nauczyciela',
  removeTeacherSalary: 'Usunięcie wypłaty nauczyciela',
  addStudentPayment: 'Dodanie wpłaty ucznia',
  updateStudentPayment: 'Edycja wpłaty ucznia',
  removeStudentPayment: 'Usunięcie wpłaty ucznia'
}

const Logs = () => {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    getLogs()
      .then(resp => {
        setLogs(resp)
      })
  }, [])

  console.log({ logs })

  return (
    <ExpandablePanel title='Logi aplikacji'>
      <Table>
        <thead>
        <tr>
          <th>Kiedy</th>
          <th>Co</th>
          <th>Kto</th>
          <th>Nauczyciel</th>
          <th>Saldo nau.</th>
          <th>Uczeń</th>
          <th>Saldo ucznia</th>
          <th>Zmiana</th>
        </tr>
        </thead>
        <tbody>
        {
          logs.map(log => {
            const teacherName = pathOr(null, ['values', 'teacher', 'name'], log) || pathOr(null, ['values', 'teacherPayload', 'name'], log) || '---'
            const studentName = pathOr(null, ['values', 'student', 'name'], log) || pathOr(null, ['values', 'studentPayload', 'name'], log) || '---'

            const teacherBalance = pathOr(null, ['values', 'teacher', 'balance'], log) || pathOr(null, ['values', 'teacherPayload', 'balance'], log) || '---'
            const studentBalance = pathOr(null, ['values', 'student', 'balance'], log) || pathOr(null, ['values', 'studentPayload', 'balance'], log) || '---'

            const value = pathOr(null, ['values', 'debit', 'value'], log) || pathOr(null, ['values', 'debitPayload', 'value'], log) || '---'

            return (
              (
                <tr>
                  <td>{formatDate(log.createdAt, DATE_FORMATS.dashedWithTime)}</td>
                  <td>{TYPES[log.type]}</td>
                  <td>{log.user}</td>
                  <td>{teacherName}</td>
                  <td>{teacherBalance}</td>
                  <td>{studentName}</td>
                  <td>{studentBalance}</td>
                  <td>{value}</td>
                </tr>
              )
            )
          })
        }
        </tbody>
      </Table>
    </ExpandablePanel>
  )
}

export default Logs
