import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { dissoc, values as ramdaValues } from 'ramda'
import Input from 'components/Input'
import Button from 'components/Button'
import { v4 as uuid } from 'uuid'
import { isNotNilOrEmpty } from 'utils/ramda'
import Select from 'components/Select'

const emptyValues = {
  title: '',
  value: '',
  type: 'expense'
}

const OtherExpensesForm = ({ onSubmit }) => {
  const [values, setValues] = useState(emptyValues)

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit({
      ...values,
      id: uuid()
    })
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const isValid = useMemo(() => {
    return ramdaValues(values).every(val => isNotNilOrEmpty(val))
  }, [values])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          label='Opis'
          name='title'
          onChange={handleValueChange}
          value={values.title}
        />
        <Input
          type='number'
          label='Kwota'
          name='value'
          onChange={handleValueChange}
          value={values.value}
        />
        <Select
          name='type'
          label='Rodzaj'
          value={values.type}
          onChange={handleValueChange}
          options={[
            { label: 'Wydatek', value: 'expense' },
            { label: 'Przychód', value: 'income' }
          ]}
        />
        <Button
          type='submit'
          color='primary'
          disabled={!isValid}
        >
          Dodaj
        </Button>
      </Form>
    </>
  )
}

export default OtherExpensesForm

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 15px;
`
