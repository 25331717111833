import React, { useState } from 'react'
import AddPaymentForm from 'modules/studentDetails/components/AddPaymentForm'
import Modal from 'components/Modal'
import styled from 'styled-components'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { toast } from 'react-hot-toast'

const AddDebtorsPaymentModal = ({ student, fetchDebtors }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSuccess = () => {
    fetchDebtors()
    handleClose()
    toast.success('Pomyślnie dodano wpłatę')
  }

  return (
    <>
      <StyledIcon onClick={handleOpen} />
      <Modal
        open={open}
        title='Dodaj wpłatę'
        onClose={handleClose}
      >
        <AddPaymentForm student={student} callback={handleSuccess} column />
      </Modal>
    </>
  )
}

export default AddDebtorsPaymentModal

const StyledIcon = styled(PaidOutlinedIcon)`
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
