import { parseNumber } from 'utils/numbers'

export const LESSON_TYPES = {
  online_teacher: `Online - konto nau.`,
  online_company: 'Online - konto firmy',
  classroom_cash: 'Stacjonarne - gotówka',
  classroom_company: 'Stacjonarne - konto firmy',
  away_cash: 'Dojazd - gotówka',
  away_company: 'Dojazd - konto firmy'
}

export const LESSON_TYPES_SELECT_OPTIONS = [
  { label: LESSON_TYPES.online_company, value: 'online_company'},
  { label: LESSON_TYPES.online_teacher, value: 'online_teacher'},
  { label: LESSON_TYPES.classroom_cash, value: 'classroom_cash'},
  { label: LESSON_TYPES.classroom_company, value: 'classroom_company'},
  { label: LESSON_TYPES.away_cash, value: 'away_cash'},
  { label: LESSON_TYPES.away_company, value: 'away_company'}
]

export const isCashLesson = lessonType => ['classroom_cash', 'away_cash'].includes(lessonType)

export const getCountingModel = lessonType => {
  if (['online_teacher', 'classroom_cash', 'away_cash'].includes(lessonType)) {
    return 1
  }

  if (['online_company', 'classroom_company', 'away_company'].includes(lessonType)) {
    return 2
  }
}

export const countLessonBalance = lesson => {
  const { lessonType, duration, studentRate, teacherRate } = lesson
  const countingModel = getCountingModel(lessonType)

  const lessonDuration = parseNumber(duration)

  switch (countingModel) {
    case 1:
      return -((parseNumber(lessonDuration) * parseNumber(studentRate)) - (parseNumber(lessonDuration) * parseNumber(teacherRate)))
    case 2:
    default:
      return parseNumber(lessonDuration) * parseNumber(teacherRate)
  }
}

export const properHourVariation = hours => {
  switch (hours) {
    case 1:
      return 'godzinę'
    case 1.5:
    case 2.5:
    case 3.5:
    case 4.5:
    case 5.5:
    case 6.5:
    case 7.5:
    case 8.5:
    case 9.5:
    case 10.5:
    case 11.5:
    case 12.5:
    case 13.5:
    case 14.5:
    case 15.5:
    case 16.5:
    case 17.5:
    case 18.5:
    case 19.5:
    case 20.5:
    case 21.5:
    case 22.5:
    case 23.5:
    case 24.5:
    case 25.5:
    case 26.5:
    case 27.5:
    case 28.5:
    case 29.5:
    case 30.5:
    case 2:
    case 3:
    case 4:
    case 22:
    case 23:
    case 24:
    case 32:
    case 33:
    case 34:
      return 'godziny'
    default:
      return 'godzin'
  }
}
