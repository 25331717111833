import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleTeacherStudents } from 'services/TeachersService'
import styled from 'styled-components'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import MyStudentsTable from 'modules/myStudents/components/MyStudentsTable'
import ExpandablePanel from 'components/ExpandablePanel'

const MyStudents = () => {
  const [students, setStudents] = useState([])
  const user = useSelector(selectCurrentUser)
  const teacher = useSelector(selectCurrentTeacher)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSingleTeacherRoutine({ id: user.id }))
  }, [user])

  const getStudents = () => {
    getSingleTeacherStudents(teacher.id, setStudents)
  }

  useEffect(() => {
    teacher.id && getStudents()
  }, [teacher])

  return (
    <>
      <PageHeader>
        Uczniowie
      </PageHeader>
      <ExpandablePanel title='Lista uczniów' isStatic>
        <MyStudentsTable students={students} getStudents={getStudents} />
      </ExpandablePanel>
    </>
  )
}

export default MyStudents

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
