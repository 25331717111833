import React, { useEffect, useState } from 'react'
import { getDebtors } from 'services/DebtorsService'
import DebtorsByTeacherTable from 'modules/debtors/components/DebtorsByTeacherTable'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import Button from 'components/Button'
import { getCurrentParsedQuery, redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import { useHistory } from 'react-router-dom'
import Modal from 'components/Modal'

const Debtors = () => {
  const [teachers, setTeachers] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([])
  const [phoneModalOpen, setPhoneModalOpen] = useState()
  const { location: { search } } = useHistory()

  const toggleStudentSelection = student => () => {
    selectedStudents.some(s => s.id === student.id)
      ? setSelectedStudents(prev => prev.filter(s => s.id !== student.id))
      : setSelectedStudents(prev => [ ...prev,  student ])
  }

  useEffect(() => {
    const { id } = getCurrentParsedQuery()
    const elements = document.querySelectorAll(`[data-id="${id}"]`)
    if (isNotNilOrEmpty(elements[0])) {
      elements[0].scrollIntoView({ block: 'center' })
      elements[0].style.background = '#FCFCBF'
      setTimeout(() => {
        elements[0].style.background = 'transparent'
      }, 1000)
    }
  }, [teachers, search])

  const fetchDebtors = () => {
    getDebtors()
      .then(result => {
        setTeachers(result)
      })
  }

  useEffect(() => {
    fetchDebtors()
  }, [])

  const handleClearSelected = () => {
    setSelectedStudents([])
  }

  const handleRedirectToMessage = () => {
    redirect(PATHS.message, selectedStudents)
  }

  const handlePhoneNumbersModalOpen = () => setPhoneModalOpen(true)
  const handlePhoneNumbersModalClose = () => setPhoneModalOpen(false)

  return (
    <Wrapper>
      <SummaryPanel>
        <div>
          Zaznaczeni uczniowie: {selectedStudents.length}
        </div>
        <ButtonsContainer>
          <Button
            color='primary-outline'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handleClearSelected}
          >
            Wyczyść
          </Button>
          <Button
            style={{ minWidth: '200px' }}
            color='primary-outline'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handlePhoneNumbersModalOpen}
          >
            Pokaż numery telefonów
          </Button>
          <Button
            color='primary'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handleRedirectToMessage}
          >
            Wyślij do wielu
          </Button>
        </ButtonsContainer>
      </SummaryPanel>
      {teachers.filter(t => isNotNilOrEmpty(t.students)).map(teacher => (
        <DebtorsByTeacherTable
          key={teacher.id}
          teacher={teacher}
          selectedStudents={selectedStudents.map(s => s.id)}
          toggleStudentSelection={toggleStudentSelection}
          fetchDebtors={fetchDebtors}
        />
      ))}
      <Modal
        title='Numery zaznaczonych uczniów'
        open={phoneModalOpen}
        onClose={handlePhoneNumbersModalClose}
      >
        {selectedStudents.map(student => student.phoneNumber).join('; ')}
      </Modal>
    </Wrapper>
  )
}

export default Debtors

const SummaryPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 299px;
  padding: 10px 30px;
  background: #fff;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  min-width: 500px;
`

const Wrapper = styled.div`
  padding-top: 75px;
`
