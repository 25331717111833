import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/Input'
import Select from 'components/Select'
import { useSelector } from 'react-redux'
import { LESSON_TYPES_SELECT_OPTIONS } from 'utils/lessons'
import { selectSchoolSubjects } from 'modules/settings/ducks/selectors'
import Button from 'components/Button'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { getSingleTeacherRates } from 'services/TeachersService'
import { dissoc, values as ramdaValues } from 'ramda'

const emptyValues = {
  studentRate: '',
  teacherRate: '',
  lessonType: null,
  lessonDuration: '',
  id: '',
  name: '',
  subject: ''
}

const EditStudentTeacherForm = ({ teacher, index, onEditTeacher, handleCloseModal }) => {
  const [values, setValues] = useState(emptyValues)
  const [teacherRates, setTeacherRates] = useState([])
  const subjects = useSelector(selectSchoolSubjects)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const teacherRatesOptions = useMemo(() => {
    return teacherRates.map(rate => ({
      label: rate.name,
      value: rate.rate
    }))
  }, [teacherRates])

  useEffect(() => {
    teacher && getSingleTeacherRates(teacher.id, setTeacherRates)
  }, [teacher])

  const subjectsOptions = useMemo(() => {
    return subjects.map(subject => ({
      label: subject.name,
      value: subject.name
    }))
  }, [subjects])

  useEffect(() => {
    teacher && setValues(teacher)
  }, [teacher])

  const handleEdit = () => {
    onEditTeacher({
      ...values
    }, index)
    handleCloseModal()
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('id', values))
    return toCheck.every(val => isNotNilOrEmpty(val))
  }, [values])

  return (
      <Form>
        <Row>
          <Select
            name='lessonType'
            options={LESSON_TYPES_SELECT_OPTIONS}
            label='Typ lekcji'
            onChange={handleValueChange}
            value={values.lessonType}
          />
          <Select
            name='subject'
            options={subjectsOptions}
            label='Przedmiot'
            onChange={handleValueChange}
            value={values.subject}
          />
        </Row>
        <Row>
          <Input
            name='lessonDuration'
            value={values.lessonDuration}
            label='Czas trwania lekcji'
            onChange={handleValueChange}
          />
          <Input
            name='studentRate'
            value={values.studentRate}
            label='Stawka ucznia'
            onChange={handleValueChange}
          />
        </Row>
        <Row>
          <Select
            name='ratesHelper'
            options={teacherRatesOptions}
            label='Wybór stawek'
            disabled={isNilOrEmpty(values.id) || isNilOrEmpty(teacherRates)}
            onChange={(_, value) => handleValueChange('teacherRate', value)}
          />
          <Input
            name='teacherRate'
            value={values.teacherRate}
            label='Stawka nauczyciela'
            onChange={handleValueChange}
          />
        </Row>
        <ButtonWrapper>
          <Button
            onClick={handleCloseModal}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleEdit}
            color='primary'
            disabled={!isValid}
          >
            Zapisz
          </Button>
        </ButtonWrapper>
      </Form>
  )
}

export default EditStudentTeacherForm

const Form = styled.form`
  margin-top: 10px;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  
  & > div {
    max-width: 191px;
  }

  ${({ inModal }) => inModal && css`
    flex-direction: column;

    & > div {
      width: 100%;
    }
  `}
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  button {
    max-width: 200px;
  }
`
