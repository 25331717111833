import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { dissoc, propOr, values as ramdaValues } from 'ramda'
import Input from 'components/Input'
import Button from 'components/Button'
import { fetchSingleTeacherLessonsRoutine, fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import { getCurrentParsedQuery } from 'utils/navigation'
import { batchUpdateLesson, getSingleDebitItem, getSingleTeacherDebitItem, updateLesson } from 'services/LessonsService'
import EditIcon from '@mui/icons-material/Edit'
import { getSingleStudent } from 'services/StudentsService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { fetchSingleStudentLessonsRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import { getSingleTeacher } from 'services/TeachersService'
import { countLessonBalance, isCashLesson } from 'utils/lessons'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  studentRate: '',
  teacherRate: '',
  duration: '',
  comment: ''
}

const EditLessonModal = ({ lesson, isStudent, isDashboard }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [student, setStudent] = useState({})
  const [teacher, setTeacher] = useState({})
  const [debit, setDebit] = useState({})
  const [teacherDebit, setTeacherDebit] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (isNotNilOrEmpty(lesson) && open) {
      setValues(lesson)
      getSingleStudent(lesson.studentId, setStudent)
      getSingleDebitItem(lesson.debitId, setDebit)
      getSingleTeacherDebitItem(lesson.teacherDebitId, setTeacherDebit)
      getSingleTeacher({ id: lesson.teacher.id, callback: setTeacher })
    }
  }, [lesson, open])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
    setLoading(false)
  }

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const successCallback = () => {
    const query = getCurrentParsedQuery()
    if (isStudent) {
      dispatch(fetchSingleStudentLessonsRoutine({ id: lesson.student.id, ...query }))
      dispatch(fetchSingleStudentRoutine({ id: lesson.student.id }))
    } else {
      dispatch(fetchSingleTeacherLessonsRoutine({ id: lesson.teacher.id, ...query }))
      dispatch(fetchSingleTeacherRoutine({ id: lesson.teacher.id }))
    }
    setLoading(false)
    handleClose()
  }

  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()
    const lessonCost = parseNumber(values.duration) * parseNumber(values.studentRate)
    const oldValue =  parseNumber(lesson.duration) * parseNumber(lesson.studentRate)
    const lessonDifference = oldValue - lessonCost
    const teacherIncome = countLessonBalance({
      ...lesson,
      ...values
    })
    const isCash = isCashLesson(lesson.lessonType)

    const incomeDifference = teacherIncome - parseNumber(teacherDebit.value)

    const lessonPayload = {
      ...lesson,
      ...values
    }

    const debitPayload = {
      ...debit,
      studentRate: parseNumber(values.studentRate),
      duration: parseNumber(values.duration),
      value: isCash ? 0 : parseNumber(lessonCost) * -1,
      comment: values.comment,
      balanceAfter: isCash ? parseNumber(propOr(0, 'balanceAfter', debit)) : parseNumber(propOr(0, 'balanceAfter', debit)) + lessonDifference
    }

    const teacherDebitPayload = {
      ...teacherDebit,
      value: teacherIncome,
      teacherRate: parseNumber(values.teacherRate),
      duration: parseNumber(values.duration),
      comment: values.comment,
      balanceAfter: parseNumber(propOr(0, 'balanceAfter', teacherDebit)) + incomeDifference
    }

    const studentPayload = {
      ...student,
      balance: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student)) + lessonDifference
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) + incomeDifference
    }

    batchUpdateLesson({
      values: {
        lessonDifference: isCash ? 0 : lessonDifference,
        lessonPayload,
        debitPayload,
        studentPayload,
        teacherDebitPayload,
        teacherPayload,
        incomeDifference
      },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) &&
      [values.studentRate, values.teacherRate, values.duration].every(val => parseNumber(val) > 0)
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj lekcję'
        open={open}
        onClose={handleClose}
      >
        <BasicDetails>
          <div><span>Uczeń:</span> {lesson.student.name}</div>
          <div><span>Data:</span> {formatDate(lesson.date, DATE_FORMATS.dashed)}</div>
        </BasicDetails>
        <form onSubmit={handleSubmit}>
          <Input
            type='number'
            label='Czas trwania'
            name='duration'
            onChange={handleValueChange}
            value={values.duration}
          />
          {
            !isDashboard && (
              <>
                <Input
                  type='number'
                  label='Stawka ucznia'
                  name='studentRate'
                  onChange={handleValueChange}
                  value={values.studentRate}
                />
                <Input
                  type='number'
                  label='Stawka nauczyciela'
                  name='teacherRate'
                  onChange={handleValueChange}
                  value={values.teacherRate}
                />
              </>
            )
          }
          <Input
            label='Komentarz'
            name='comment'
            onChange={handleValueChange}
            value={values.comment}
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button
              type='submit'
              color='primary'
              disabled={!isValid || loading}
              isLoading={loading}
            >
              Zapisz
            </Button>
          </ButtonsWrapper>
        </form>
      </Modal>
    </>
  )
}

export default EditLessonModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 15px;

  button {
    max-width: 200px;
  }
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const BasicDetails = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  padding: 14px;
  border-radius: 4px;
  text-transform: uppercase;
  
  span {
    text-transform: none;
  }
  
  & > div {
    display: flex;
    padding: 3px;
    align-items: center;
    justify-content: space-between;
  }
`
