import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Input from 'components/Input'
import EditIcon from '@mui/icons-material/Edit'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import { fetchSingleWorkerIncomeItemsRoutine, fetchSingleWorkerRoutine } from 'modules/workers/ducks/actions'
import { updateWorkerIncome } from 'services/WorkersService'
import { dissoc, values as ramdaValues } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'

const defaultValues = {
  date: formatDate(new Date(), DATE_FORMATS.input),
  value: 0,
  comment: ''
}

const EditWorkerIncomeModal = ({ income }) => {
  const worker = useSelector(selectCurrentWorker)
  const [values, setValues] = useState(defaultValues)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    setValues(income)
  }, [income])

  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const successCallback = () => {
      dispatch(fetchSingleWorkerIncomeItemsRoutine({ id: worker.id }))
      dispatch(fetchSingleWorkerRoutine({ id: worker.id }))
      handleClose()
    }

    const incomePayload = {
      ...values,
      value: parseNumber(values.value)
    }

    updateWorkerIncome({
      values: { incomePayload },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.value) > 0
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj wpłatę'
        open={open}
        onClose={handleClose}
      >
        <Input
          name='value'
          type='number'
          label='Kwota wypłaty'
          value={values.value}
          onChange={handleValueChange}
        />
        <Input
          name='comment'
          label='Komentarz'
          value={values.comment}
          onChange={handleValueChange}
        />
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button disabled={!isValid} color='primary' onClick={handleSubmit}>Zapisz</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default EditWorkerIncomeModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
