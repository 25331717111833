import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import EditIcon from '@mui/icons-material/Edit'
import { fetchSingleTeacherRoutine, clearSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import EditTeacherForm from 'modules/teachers/components/EditTeacherForm'

const EditTeacherModal = ({ teacher, isSingleTeacherView }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  useEffect(() => {
    if (open) {
      !isSingleTeacherView && dispatch(clearSingleTeacherRoutine())
      dispatch(fetchSingleTeacherRoutine({ id: teacher.id }))
    }
  }, [open])

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  return (
      <>
        <Trigger onClick={handleOpen}>
          <StyledIcon />
        </Trigger>
        <Modal
          title='Edytuj'
          open={open}
          onClose={handleClose}
        >
          <EditTeacherForm handleCloseModal={handleClose} fetchSingle={isSingleTeacherView} />
        </Modal>
      </>
  )
}

export default EditTeacherModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
