import { db, secondaryApp } from 'firestoreConfig'
import { dissoc, values } from 'ramda'
import { toast } from 'react-hot-toast'

const updateListItem = (updated, list) => {
  let obj = {}
  list.forEach(worker => {
    obj[worker.id] = worker
  })
  obj[updated.id] = updated
  return values(obj)
}

export const addWorker = ({ values, callback }) => {
  return secondaryApp
    .auth()
    .createUserWithEmailAndPassword(values.email, values.password)
    .then(async resp => {
      const uid = resp.user.uid
      const docRef = db.collection('workers').doc(uid)
      const valuesToSet = {
        ...dissoc('password', values),
        id: uid
      }

      await docRef.set(valuesToSet)
      typeof callback === 'function' && callback()
    })
    .catch(error => {
      console.error(error.message)
    })
}

export const getWorkersList = () => {
  return db
    .collection('lists')
    .doc('workers')
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = doc.data()
        return data.list
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const fetchActiveWorkers = () => {
  let workers = []
  return db
    .collection('workers')
    .where('removed', '!=', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        workers = [...workers, doc.data()]
      })
    })
    .then(() => {
      return workers
    })
    .catch(error => {
      console.log('Error getting document:', error)
      return []
    })
}

export const getSingleWorker = ({ id, callback }) => {
  return db
    .collection('workers')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        typeof callback === 'function' && callback(data)
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const updateWorker = ({ worker, callback }) => {
  const objectRef = db.collection('workers').doc(worker.id)
  return objectRef.update(dissoc('id', worker))
    .then(async () => {
      const data = await objectRef.get()
      typeof callback === 'function' && callback()
      return data.data()
    })
    .catch(function(error) {
      console.error('Error updating document: ', error)
    })
}

export const removeWorker = ({ worker, callback }) => {
  updateWorker({
    worker: {
      ...worker,
      removed: true
    },
    callback
  })
}

export const addWorkerIncome = async ({ values, callback }) => {
  const { incomePayload } = values

  try {
    const debitRef = db.collection('workerIncome').doc(incomePayload.id)
    await debitRef.set(incomePayload)
      .then(async () => {
        typeof callback === 'function' && callback()
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas dodawania wypłaty pracownika')
      })

  } catch (err) {
    console.error(err)
  }
}

export const updateWorkerIncome = async ({ values, callback }) => {
  const { incomePayload } = values
  try {
    const debitRef = db.collection('workerIncome').doc(incomePayload.id)
    await debitRef.update(incomePayload)
      .then(async () => {
        typeof callback === 'function' && callback()
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas edycji wypłaty pracownika')
      })
  } catch (err) {
    console.error(err)
  }
}

export const getSingleWorkerIncomeItems = async ({ id }) => {
  const snapshot = await db
    .collection('workerIncome')
    .where('workerId', '==', id)
    .get()

  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const removeWorkerIncome = async ({ id, callback }) => {
  return await db
    .collection('workerIncome')
    .doc(id)
    .delete()
    .then(() => {
      typeof callback === 'function' && callback()
    })
}

export const getIncomesForAllWorkers = async ({ dateFrom, dateTo, setFn }) => {
  const snapshot = await db
    .collection('workerIncome')
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    setFn([])
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    setFn(data)
  }
}
