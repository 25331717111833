import { db } from 'firestoreConfig'
import { toast } from 'react-hot-toast'
import moment from 'moment'

export const updateFixedCost = async ({ values, title, callback }) => {
  try {
    const fixedCostsRef = db.collection('fixedCosts').doc(title)
    await fixedCostsRef.set(values)
      .then(() => {
        toast.success('Zaktualizowano koszta stałe')
        typeof callback === 'function' && callback()
      })
      .catch(() => {
        toast.error('Nie udało się zapisać zmian')
      })
  } catch (err) {
    console.error(err)
  }
}

export const getFixedCosts = async ({ title, setFn }) => {
  const ref = db.collection('fixedCosts').doc(title)
  const emptyValues = { zus: 0, tax: 0, rent: 0 }
  await ref
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        setFn(data)
      } else {
        await ref.set(emptyValues)
        setFn(emptyValues)
      }
    })
    .catch(() => {
      toast.error('Nie udało się pobrać kosztów stałych')
    })
}

export const addAdditionalFixedCost = async ({ values, callback }) => {
  const additionalFixedCostRef = db.collection('additionalFixedCosts').doc('costs')
  await additionalFixedCostRef
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const payload = [
          ...data.entries,
          values
        ]
        additionalFixedCostRef.set({ entries: payload })
        typeof callback === 'function' && callback()
        toast.success('Dodano nowy koszt stały')
      }
    })
    .catch(() => {
      toast.error('Nie udało się dodać kosztu')
    })
}

export const updateAdditionalFixedCost = async ({ values, callback }) => {
  const additionalFixedCostRef = db.collection('additionalFixedCosts').doc('costs')
  await additionalFixedCostRef
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const payload = data.entries?.map(el => {
          return el.id === values.id
            ? values
            : el
        })
        additionalFixedCostRef.set({ entries: payload })
        typeof callback === 'function' && callback()
        toast.success('Zaktualizowano koszt stały')
      }
    })
    .catch(() => {
      toast.error('Nie udało się zaktualizować kosztu')
    })
}

export const removeAdditionalFixedCost = async ({ id, callback }) => {
  const additionalFixedCostRef = db.collection('additionalFixedCosts').doc('costs')
  await additionalFixedCostRef
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const payload = data.entries?.filter(el => {
          return el.id !== id
        })
        additionalFixedCostRef.set({ entries: payload })
        typeof callback === 'function' && callback()
        toast.success('Usunięto koszt stały')
      }
    })
    .catch(() => {
      toast.error('Nie udało się usunąć kosztu')
    })
}

export const MONTHS = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
}

export const getAdditionalFixedCosts = async ({ title, setFn }) => {
  const additionalFixedCostRef = db.collection('additionalFixedCosts').doc('costs')
  const month = title.slice(0, -4)
  const monthNumber = MONTHS[month]
  const year = title.slice(-4)
  const currentMonth = `${year}-${monthNumber}`
  await additionalFixedCostRef
    .get()
    .then(async resp => {
      if (resp.exists) {
        const data = await resp.data()
        const response = data
          ?.entries
          ?.filter(cost => moment(currentMonth).isBetween(moment(cost.dateFrom), moment(cost.dateTo), 'month', '[]'))
        setFn(response)
      } else {
        setFn([])
      }
    })
    .catch(() => {
      toast.error('Nie udało się pobrać kosztów stałych')
    })
}
