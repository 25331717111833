import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { getSingleTeacherStudents } from 'services/TeachersService'
import Select from 'components/Select'
import { dissoc, pathOr, propOr, sortBy, prop, values as ramdaValues } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Input from 'components/Input'
import { DATE_FORMATS, dateWithTime, formatDate } from 'utils/date'
import Button from 'components/Button'
import { v4 as uuid } from 'uuid'
import { batchAddLesson } from 'services/LessonsService'
import { fetchSingleTeacherLessonsRoutine, fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import { getCurrentParsedQuery } from 'utils/navigation'
import { countLessonBalance, isCashLesson } from 'utils/lessons'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  studentId: '',
  subjectEntryId: '',
  date: formatDate(new Date(), DATE_FORMATS.input),
  comment: '',
  duration: 1
}

const AddLessonModal = ({ teacher, customButton, dates, isDashboard }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [students, setStudents] = useState([])
  const [currentStudentSubjects, setCurrentStudentSubjects] = useState([])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    teacher && open && getSingleTeacherStudents(teacher.id, setStudents)
  }, [teacher, open])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const studentsOptions = useMemo(() => {
    return sortBy(prop('name'), students).map(student => {
      const availableTeachers = propOr([], 'teachers', student)
      return {
        label: student.name,
        value: student.id,
        subjects: availableTeachers.filter(t => t.id === teacher.id)
      }
    })
  }, [students])

  const subjectsOptions = useMemo(() => {
    const mapped = currentStudentSubjects.map(subject => {
      return {
        label: subject.subject,
        value: subject.entryId,
        studentRate: parseNumber(subject.studentRate),
        teacherRate: parseNumber(subject.teacherRate),
      }
    })
    setValues(prev => ({
      ...prev,
      subjectEntryId: pathOr('', [0, 'value'], mapped),
      studentRate: pathOr('', [0, 'studentRate'], mapped),
      teacherRate: pathOr('', [0, 'teacherRate'], mapped)
    }))
    return mapped
  }, [currentStudentSubjects])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
    setLoading(false)
  }

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setCurrentStudentSubjects([])
    setValues(emptyValues)
  }

  const successCallback = () => {
    const query = isNotNilOrEmpty(dates) ? dates : getCurrentParsedQuery()
    dispatch(fetchSingleTeacherLessonsRoutine({ id: teacher.id, ...query }))
    dispatch(fetchSingleTeacherRoutine({ id: teacher.id }))
    setLoading(false)
    if (isDashboard) {
      setValues(prev => ({
        ...emptyValues,
        date: prev.date
      }))
    } else {
      handleClose()
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const selectedSubject = currentStudentSubjects.find(subject => subject.entryId === values.subjectEntryId)
    const selectedStudent = students.find(student => student.id === values.studentId)
    const lessonCost = parseNumber(values.duration) * parseNumber(values.studentRate)
    const teacherIncome = countLessonBalance({
      lessonType: selectedSubject.lessonType,
      duration: parseNumber(values.duration),
      studentRate: parseNumber(values.studentRate),
      teacherRate: parseNumber(values.teacherRate)
    })
    const createdAt = dateWithTime(new Date())
    const lessonId = uuid()
    const debitId = uuid()
    const teacherDebitId = uuid()
    const isCash = isCashLesson(selectedSubject.lessonType)

    const lessonPayload = {
      ...values,
      date: dateWithTime(values.date),
      id: lessonId,
      subject: selectedSubject.subject,
      studentRate: parseNumber(values.studentRate),
      teacherRate: parseNumber(values.teacherRate),
      lessonType: selectedSubject.lessonType,
      createdAt,
      debitId,
      teacherDebitId,
      teacher: {
        rate: parseNumber(values.teacherRate),
        name: teacher.name,
        id: teacher.id
      },
      student: {
        rate: parseNumber(values.studentRate),
        name: selectedStudent.name,
        id: selectedStudent.id
      }
    }

    const debitPayload = {
      type: 'lesson',
      studentId: selectedStudent.id,
      comment: values.comment,
      createdAt,
      subject: selectedSubject.subject,
      studentRate: parseNumber(values.studentRate),
      duration: parseNumber(values.duration),
      id: debitId,
      teacherDebitId,
      parentId: lessonId,
      value: isCash ? 0 : lessonCost * -1,
      date: dateWithTime(values.date),
      balanceAfter: isCash ? parseNumber(propOr(0, 'balance', selectedStudent)) : parseNumber(propOr(0, 'balance', selectedStudent)) - lessonCost
    }

    const teacherDebitPayload = {
      type: 'lesson',
      teacherId: teacher.id,
      comment: values.comment,
      createdAt,
      id: teacherDebitId,
      debitId,
      parentId: lessonId,
      value: teacherIncome,
      subject: selectedSubject.subject,
      teacherRate: parseNumber(values.teacherRate),
      duration: parseNumber(values.duration),
      date: dateWithTime(values.date),
      student: {
        name: selectedStudent.name,
        id: selectedStudent.id
      },
      balanceAfter: parseNumber(propOr(0, 'balance', teacher)) + teacherIncome
    }

    const studentPayload = {
      ...selectedStudent,
      balance: isCash ? parseNumber(propOr(0, 'balance', selectedStudent)) : parseNumber(propOr(0, 'balance', selectedStudent)) - lessonCost
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) + teacherIncome
    }

    batchAddLesson({
      values: {
        lessonPayload,
        debitPayload,
        studentPayload,
        teacherDebitPayload,
        teacherPayload
      },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.duration) > 0
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        {
          customButton
            ? <Button color='primary-outline'>Dodaj lekcję</Button>
            : <div>+ Dodaj lekcję</div>
        }
      </Trigger>
      <Modal
        title='Dodaj lekcję'
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Select
            name='studentId'
            options={studentsOptions}
            label='Uczeń'
            onChange={(name, value) => {
              handleValueChange(name, value)
              const option = studentsOptions.find(o => o.value === value)
              setCurrentStudentSubjects(option.subjects)
            }}
            value={values.studentId}
          />
          {
            currentStudentSubjects.length > 1 && (
              <Select
                name='subjectEntryId'
                options={subjectsOptions}
                label='Przedmiot'
                onChange={(name, value) => {
                  const subject = currentStudentSubjects.find(s => s.entryId === value)
                  setValues(prev => ({
                    ...prev,
                    duration: parseNumber(subject.lessonDuration),
                    studentRate: parseNumber(subject.studentRate),
                    teacherRate: parseNumber(subject.teacherRate),
                    [name]: value
                  }))
                }}
                value={values.subjectEntryId}
                disabled={isNilOrEmpty(values.studentId)}
              />
            )
          }
          <Input
            type='date'
            label='Data'
            name='date'
            onChange={handleValueChange}
            value={values.date}
          />
          <Input
            type='number'
            label='Czas trwania'
            name='duration'
            inputProps={{
              step: 0.25
            }}
            onChange={handleValueChange}
            value={values.duration}
          />
          {
            !isDashboard && (
              <>
                <Input
                  type='number'
                  label='Stawka ucznia'
                  name='studentRate'
                  onChange={handleValueChange}
                  value={values.studentRate}
                />
                <Input
                  type='number'
                  label='Stawka nauczyciela'
                  name='teacherRate'
                  onChange={handleValueChange}
                  value={values.teacherRate}
                />
              </>
            )
          }
          <Input
            label='Komentarz'
            name='comment'
            onChange={handleValueChange}
            value={values.comment}
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button
              type='submit'
              color='primary'
              disabled={!isValid || loading}
              isLoading={loading}
            >
              Dodaj
            </Button>
          </ButtonsWrapper>
        </form>
      </Modal>
    </>
  )
}

export default AddLessonModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 15px;

  button {
    max-width: 200px;
  }
`
