import React from 'react'
import Table from 'components/Table'
import { useSelector } from 'react-redux'
import { pathOr, prop, propOr, reverse, sortBy } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { TEACHER_DEBIT_ITEM_TYPES } from 'utils/teacher'
import { selectCurrentTeacherDebitItems } from 'modules/teachers/ducks/selectors'
import styled from 'styled-components'
import DeleteSalaryModal from 'modules/teacherDetails/components/DeleteSalaryModal'
import EditSalaryModal from 'modules/teacherDetails/components/EditSalaryModal'
import { isNilOrEmpty } from 'utils/ramda'
import EditTeacherDebitModal from 'modules/teacherDetails/components/EditTeacherDebitModal'

const TeacherDebitTable = () => {
  const list = useSelector(selectCurrentTeacherDebitItems)
  const sorted = reverse(sortBy(prop('date'), list))

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Co?</th>
          <th>Uczeń</th>
          <th>Data</th>
          <th>Liczba h</th>
          <th>Stawka/h</th>
          <th>Zmiana salda</th>
          <th>Saldo po</th>
          <th>Komentarz</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          sorted.map(item => (
            <TableRow value={item.value} key={item.id}>
              <td>{TEACHER_DEBIT_ITEM_TYPES[item.type]}</td>
              <td>{pathOr('---', ['student', 'name'], item)}</td>
              <td>{formatDate(item.date, DATE_FORMATS.dashed)}</td>
              <td>{propOr('---', 'duration', item)}</td>
              <td>{propOr('---', 'teacherRate', item)}</td>
              <td>{propOr('---', 'value', item)}</td>
              <td>{propOr('---', 'balanceAfter', item)}</td>
              <td>{propOr('---', 'comment', item)}</td>
              <td>
                <Actions onClick={e => e.stopPropagation()}>
                  {item.type !== 'lesson' && item.type !== 'expense' && (
                    <>
                      <EditSalaryModal salary={item} />
                      <DeleteSalaryModal salary={item} />
                    </>
                  )}
                  <EditTeacherDebitModal item={item} />
                </Actions>
              </td>
            </TableRow>
          ))
        }
        </tbody>
      </Table>
      {isNilOrEmpty(list) && (
        <EmptyState>
          Brak wpisów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default TeacherDebitTable

const Actions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

const TableRow = styled.tr`
  color: ${({ value }) => value === 0 ? 'default' : value > 0 ? 'green' : 'red'};
`

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
