import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { changeQuery } from 'utils/navigation'
import Input from 'components/Input'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import TeacherDebitTable from 'modules/teacherDetails/components/TeacherDebitTable'
import { fetchSingleTeacherDebitItemsRoutine, fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import AddSalaryForm from 'modules/teacherDetails/components/AddSalaryForm'

const defaultDates = {
  dateFrom: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input),
}

const TeacherDetailsDebitHistory = () => {
  const teacher = useSelector(selectCurrentTeacher)
  const [dates, setDates] = useState(defaultDates)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setDates(prev => ({ ...prev, [name]: value }))
  }

  const fetchTeacherDebitItems = () => {
    dates.dateFrom && teacher.id && dispatch(fetchSingleTeacherDebitItemsRoutine({
      id: teacher.id,
      ...dates
    }))
  }

  useEffect(() => {
    fetchTeacherDebitItems()
  }, [dates, teacher])

  useEffect(() => {
    changeQuery(dates)
  }, [dates])

  return (
    <>
      <Header>
        <AddSalaryForm
          callback={() => {
            fetchTeacherDebitItems()
            dispatch(fetchSingleTeacherRoutine({ id: teacher.id }))
          }}
        />
        <DateFilters>
          <Input
            type='date'
            label='Data od'
            name='dateFrom'
            onChange={handleValueChange}
            value={dates.dateFrom}
          />
          <Input
            type='date'
            label='Data do'
            name='dateTo'
            onChange={handleValueChange}
            value={dates.dateTo}
          />
        </DateFilters>
      </Header>
      <TeacherDebitTable />
    </>
  )
}

export default TeacherDetailsDebitHistory

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`

const DateFilters = styled.div`
  display: flex;
  gap: 15px;
  padding-right: 20px;
`
