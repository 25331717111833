import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { updateStudentPayment } from 'services/StudentsService'
import { dissoc, propOr, values as ramdaValues } from 'ramda'
import { getCurrentParsedQuery } from 'utils/navigation'
import {
  fetchSingleStudentDebitItemsRoutine,
  fetchSingleStudentRoutine
} from 'modules/students/ducks/actions'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import Input from 'components/Input'
import EditIcon from '@mui/icons-material/Edit'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'

const defaultValues = {
  value: 0,
  comment: ''
}

const EditPaymentModal = ({ payment }) => {
  const student = useSelector(selectCurrentStudent)
  const [values, setValues] = useState(defaultValues)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    setValues(payment)
  }, [payment])

  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const difference = parseNumber(payment.value) - parseNumber(values.value)

    const studentPayload = {
      ...student,
      balance: parseNumber(propOr(0, 'balance', student)) - difference
    }

    const successCallback = () => {
      const query = getCurrentParsedQuery()
      dispatch(fetchSingleStudentDebitItemsRoutine({ id: student.id, ...query }))
      dispatch(fetchSingleStudentRoutine({ id: student.id }))
      handleClose()
    }

    const debitPayload = {
      ...values,
      value: parseNumber(values.value),
      balanceAfter: parseNumber(payment.balanceAfter) - difference
    }

    updateStudentPayment({
      values: {
        studentPayload,
        debitPayload,
        difference
      },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.value) > 0
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj wpłatę'
        open={open}
        onClose={handleClose}
      >
        <Input
          name='value'
          type='number'
          label='Kwota wpłaty'
          value={values.value}
          onChange={handleValueChange}
        />
        <Input
          name='comment'
          label='Komentarz'
          value={values.comment}
          onChange={handleValueChange}
        />
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button disabled={!isValid} color='primary' onClick={handleSubmit}>Zapisz</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default EditPaymentModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
