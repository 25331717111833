import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import AddStudentTeacherForm from 'modules/students/components/AddStudentTeacherForm'

const AddStudentTeacherModal = ({ onAddTeacher }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
      <>
        <TriggerButton onClick={handleOpen}>+ Przypisz nauczyciela</TriggerButton>
        <Modal
          open={open}
          onClose={handleClose}
          title='Przypisz nauczyciela do ucznia'
        >
          <AddStudentTeacherForm
            handleCloseModal={handleClose}
            onAddTeacher={onAddTeacher}
          />
        </Modal>
      </>
  )
}

export default AddStudentTeacherModal

const TriggerButton = styled.div`
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
