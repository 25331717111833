import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectSchoolSubjects } from 'modules/settings/ducks/selectors'
import { fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'
import { editSchoolSubjects } from 'services/SettingsService'

const DeleteSubjectModal = ({ subject }) => {
  const [open, setOpen] = useState(false)
  const subjects = useSelector(selectSchoolSubjects)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const fetchSubjectsList = () => {
    dispatch(fetchSchoolSubjectsRoutine())
  }

  const handleDelete = () => {
    editSchoolSubjects({
      entries: subjects.filter(s => s.id !== subject.id)
    }, () => {
      fetchSubjectsList()
      handleClose()
    })
  }

  return (
      <>
        <Trigger onClick={handleOpen}>
          <StyledIcon />
        </Trigger>
        <Modal
          title='Usuń'
          open={open}
          onClose={handleClose}
        >
          Czy na pewno chcesz usunąć przedmiot <SubjectName>{subject.name}</SubjectName>?
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button color='primary' onClick={handleDelete}>Usuń</Button>
          </ButtonsWrapper>
        </Modal>
      </>
  )
}

export default DeleteSubjectModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const SubjectName = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
