import { fetchEmailTemplateSettingsRoutine, fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'

const defaultState = {
  schoolSubjects: [],
  emailTemplate: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case fetchSchoolSubjectsRoutine.SUCCESS:
      return {
        ...state,
        schoolSubjects: action.payload
      }
    case fetchEmailTemplateSettingsRoutine.SUCCESS:
      return {
        ...state,
        emailTemplate: action.payload
      }
    default:
      return state
  }
}
