import { propOr, sortBy, prop } from 'ramda'
import { fetchActiveTeachers } from 'services/TeachersService'
import { fetchActiveStudents } from 'services/StudentsService'
import { db } from 'firestoreConfig'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'

export const getDebtors = async () => {
  const studentsList = await fetchActiveStudents()
  const teachersList = await fetchActiveTeachers()
  const dateFrom = `${moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.input)}T00:01`
  const dateTo = `${moment().endOf('month').format(DATE_FORMATS.input)}T23:59`

  const studentsByTeacher = sortBy(prop('name'), teachersList).map(teacher => {
    const students = studentsList.filter(student => {
      const teachers = propOr([], 'teachers', student)
      return teachers.some(t => t.id === teacher.id)
    })
    return {
      teacher,
      students: sortBy(prop('name'), students)
    }
  })

  return db
    .collection('lessons')
    .where('date', '>', dateFrom)
    .where('date', '<', dateTo)
    .get()
    .then(lessonsSnapshot => {
      let lessons = []
      if (!lessonsSnapshot.empty) {
        lessonsSnapshot.forEach( doc => {
          const data = doc.data()
          lessons = [...lessons, data]
        })

        return studentsByTeacher.map(t => {
          const teacherStudents = propOr([], 'students', t)
          const studentsWithLessons = teacherStudents.map(ts => ({
            ...ts,
            lessons: lessons.filter(l => l.studentId === ts.id)
          }))

          return {
            ...t,
            students: studentsWithLessons
          }
        })
      } else {
        return studentsByTeacher
      }
    })
}
