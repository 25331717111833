import React from 'react'
import Tabs from 'components/Tabs'
import styled from 'styled-components'
import StudentDetailsTeachers from 'modules/studentDetails/components/StudentDetailsTeachers'
import StudentDetailsLessons from 'modules/studentDetails/components/StudentDetailsLessons'
import StudentDetailsDebitHistory from 'modules/studentDetails/components/StudentDetailsDebitHistory'

const StudentDetailsTabs = () => {
  const tabs = [
    {
      key: 'teachers',
      title: 'Nauczyciele',
      children: <StudentDetailsTeachers />
    },
    {
      key: 'lessons',
      title: 'Lekcje',
      children: <StudentDetailsLessons />
    },
    {
      key: 'debit',
      title: 'Historia salda',
      children: <StudentDetailsDebitHistory />
    }
  ]

  return (
      <PanelWrapper>
        <Tabs tabs={tabs} />
      </PanelWrapper>
  )
}

export default StudentDetailsTabs

const PanelWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`
