import styled from 'styled-components'

const Table = ({ children }) => {
  return (
    <TableWrapper>
      <StyledTable>{children}</StyledTable>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`

const StyledTable = styled.table`
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  position: relative;
  
  th, td {
    text-align: left;
    padding: 5px;
  }
  
  tr:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
  
  th {
    border-bottom: 1px solid black;
    text-transform: uppercase;
  }
  
  tr {
    transition: all .3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`

export default Table
