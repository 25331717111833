import React, { useMemo, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import styled from 'styled-components'
import { DATE_FORMATS, dateWithTime, formatDate } from 'utils/date'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import { addWorkerIncome } from 'services/WorkersService'
import { dissoc, values as ramdaValues } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'

const defaultValues = {
  date: formatDate(new Date(), DATE_FORMATS.input),
  value: 0,
  comment: ''
}

const AddPaymentForm = ({ callback }) => {
  const worker = useSelector(selectCurrentWorker)
  const [values, setValues] = useState(defaultValues)
  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const createdAt = dateWithTime(new Date())
    const incomeId = uuid()

    const incomePayload = {
      workerId: worker.id,
      comment: values.comment,
      createdAt,
      id: incomeId,
      value: parseNumber(values.value),
      date: values.date
    }

    addWorkerIncome({
      values: {
        incomePayload
      },
      callback: () => {
        callback()
        setValues(defaultValues)
      }
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.value) > 0
  }, [values])

  return (
    <AddPaymentWrapper>
      <Input
        name='value'
        type='number'
        label='Kwota wypłaty'
        value={values.value}
        onChange={handleValueChange}
      />
      <Input
        type='date'
        label='Data'
        name='date'
        onChange={handleValueChange}
        value={values.date}
      />
      <Input
        name='comment'
        label='Komentarz'
        value={values.comment}
        onChange={handleValueChange}
      />
      <Button onClick={handleSubmit} disabled={!isValid}>
        Dodaj
      </Button>
    </AddPaymentWrapper>
  )
}

export default AddPaymentForm

const AddPaymentWrapper = styled.div`
  display: flex;
  gap: 15px;
`
