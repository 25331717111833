import React from 'react'
import styled from 'styled-components'
import SubjectsPanel from 'modules/settings/components/SubjectsPanel'
import EmailTemplatePanel from 'modules/settings/components/EmailTemplatePanel'
import BalanceResetPanel from 'modules/settings/components/BalanceResetPanel'
import CheckDataCorrectness from 'modules/settings/components/CheckDataCorrectness'
import Logs from 'modules/settings/components/Logs'
import BlockPanel from 'modules/settings/components/BlockPanel'

const Settings = () => {
  return (
    <>
      <PageHeader>Ustawienia Panelu</PageHeader>
      <SubjectsPanel />
      <EmailTemplatePanel />
      <BalanceResetPanel />
      <CheckDataCorrectness />
      <Logs />
      <BlockPanel />
    </>
  )
}

export default Settings

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
