import React from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { redirect } from 'utils/navigation'
import EditWorkerModal from 'modules/workers/components/EditWorkerModal'
import DeleteWorkerModal from 'modules/workers/components/DeleteWorkerModal'

const WorkerTableRow = ({ worker, order }) => {
  return (
    <Wrapper onClick={() => redirect(`/workers/${worker.id}`)}>
      <td>{order}.</td>
      <td>{propOr('---', 'name', worker)}</td>
      <td>{propOr('---', 'email', worker)}</td>
      <td>{propOr('---', 'phoneNumber', worker)}</td>
      <td>
        <Actions onClick={e => e.stopPropagation()}>
          <EditWorkerModal worker={worker} />
          <DeleteWorkerModal worker={worker} />
        </Actions>
      </td>
    </Wrapper>
  )
}

export default WorkerTableRow

const Wrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
