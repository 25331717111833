import React from 'react'
import { useSelector } from 'react-redux'
import { selectArchivedTeachersList } from 'modules/teachers/ducks/selectors'
import Table from 'components/Table'
import { isNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import ArchivedTeachersTableRow from 'modules/archivedTeachers/components/ArchivedTeachersTableRow'

const ArchivedTeachersTable = () => {
  const teachers = useSelector(selectArchivedTeachersList)

  return (
    <>
      <Table>
        <thead>
        <th>Lp.</th>
        <th>Nazwa</th>
        <th>Email</th>
        <th>Numer telefonu</th>
        <th />
        </thead>
        <tbody>
        {teachers.map((teacher, index) => (
          <ArchivedTeachersTableRow
            order={index + 1}
            teacher={teacher}
            key={`teacher-${teacher.id}`}
          />
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(teachers) && (
        <EmptyState>
          Brak nauczycieli do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default ArchivedTeachersTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
