import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectSettings = state => state.settings

export const selectSchoolSubjects = createSelector(
    selectSettings,
  propOr([], 'schoolSubjects')
)

export const selectEmailTemplateSettings = createSelector(
    selectSettings,
  propOr({}, 'emailTemplate')
)
