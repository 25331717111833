export const TEACHER_DEBIT_ITEM_TYPES = {
  lesson: 'Lekcja',
  salary: 'Wypłata',
  expense: 'Inne',
  underpayment: 'Niedopłata',
  overpayment: 'Nadpłata',
  advance: 'Zaliczka',
  absence: 'Nieobecność ucz.',
  additional: 'Dodatek'
}

export const TEACHER_PAYMENT_TYPES = {
  transfer: 'Przelew',
  postalOrder: 'Przekaz pocztowy',
  other: 'Inne'
}

export const TEACHER_PAYMENT_TYPES_OPTIONS = [
  { label: TEACHER_PAYMENT_TYPES.transfer, value: 'transfer'},
  { label: TEACHER_PAYMENT_TYPES.postalOrder, value: 'postalOrder'},
  { label: TEACHER_PAYMENT_TYPES.other, value: 'other'},
]
