import React, { useEffect } from 'react'
import styled from 'styled-components'
import ExpandablePanel from 'components/ExpandablePanel'
import { fetchArchivedTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import ArchivedTeachersTable from 'modules/archivedTeachers/components/ArchivedTeachersTable'

const ArchivedTeachers = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchArchivedTeachersListRoutine())
  }, [])

  return (
    <>
      <PageHeader>
        Archiwum nauczycieli
      </PageHeader>
      <ExpandablePanel startOpen title='Lista nauczycieli'>
        <ArchivedTeachersTable />
      </ExpandablePanel>
    </>
  )
}

export default ArchivedTeachers

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
