import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Collapse } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ExpandablePanel = ({ title, children, startOpen = false, isStatic }) => {
  const [open, setOpen] = useState(startOpen || isStatic)

  const toggleOpen = () => isStatic ? () => {} : setOpen(prev => !prev)

  return (
    <Wrapper>
      <HeaderWrapper onClick={toggleOpen} isStatic={isStatic}>
        <PanelTitle>
          {title}
        </PanelTitle>
        {!isStatic && <ChevronIcon open={open} />}
      </HeaderWrapper>
      <Collapse in={open}>
        {children}
      </Collapse>
    </Wrapper>
  )
}

export default ExpandablePanel

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`

const PanelTitle = styled.div`
  margin-bottom: 15px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 3px;
    width: 100px;
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isStatic }) => isStatic ? 'default' : 'pointer'};
`

const ChevronIcon = styled(KeyboardArrowDownIcon)`
  transition: all .3s;

  ${({ open }) => open && css`
    transform: rotate(-180deg);
  `}
`
