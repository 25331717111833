import React, { useEffect, useState } from 'react'
import { editSchoolSubjects } from 'services/SettingsService'
import styled from 'styled-components'
import Input from 'components/Input'
import ExpandablePanel from 'components/ExpandablePanel'
import Button from 'components/Button'
import { v4 as uuid } from 'uuid'
import { prop, sortBy } from 'ramda'
import { fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectSchoolSubjects } from 'modules/settings/ducks/selectors'
import Table from 'components/Table'
import DeleteSubjectModal from 'modules/settings/components/DeleteSubjectModal'
import { isNilOrEmpty } from 'utils/ramda'

const SubjectsPanel = () => {
  const subjects = useSelector(selectSchoolSubjects)
  const [name, setName] = useState('')
  const dispatch = useDispatch()

  const fetchSubjectsList = () => {
    dispatch(fetchSchoolSubjectsRoutine())
  }

  useEffect(() => {
    fetchSubjectsList()
  }, [])

  const handleAddSubject = e => {
    e.preventDefault()
    editSchoolSubjects({
      entries: [ ...subjects, { id: uuid(), name }]
    }, () => {
      fetchSubjectsList()
      setName('')
    })
  }

  return (
    <ExpandablePanel title='Przedmioty'>
      <Form onSubmit={handleAddSubject}>
        <Input
          name='name'
          value={name}
          label='Nazwa przedmiotu'
          onChange={(_, value) => setName(value)}
        />
        <Button
          id='button'
          type='submit'
          color='primary-outline'
          disabled={isNilOrEmpty(name)}
        >
          Dodaj przedmiot
        </Button>
      </Form>
      <Table>
        <thead>
          <th>Nazwa</th>
          <th />
        </thead>
        <tbody>
        {sortBy(prop('name'), subjects).map(subject => (
          <SubjectItem key={subject.name}>
            <td>{subject.name}</td>
            <td>
              <DeleteSubjectModal subject={subject} />
            </td>
          </SubjectItem>
        ))}
        </tbody>
      </Table>
    </ExpandablePanel>
  )
}

export default SubjectsPanel

const Form = styled.form`
  width: 100%;
  height: 56px;
  gap: 10px;
  display: flex;
  margin: 10px 0;
  #button {
    width: 200px;
  }
`

const SubjectItem = styled.tr`
  padding: 0 5px;
  
  td:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
`
