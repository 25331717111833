import {
  clearSingleWorkerRoutine, fetchSingleWorkerIncomeItemsRoutine,
  fetchSingleWorkerRoutine,
  fetchWorkersListRoutine
} from 'modules/workers/ducks/actions'

const defaultState = {
  list: [],
  currentWorker: {},
  currentWorkerIncomeItems: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case fetchWorkersListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case fetchSingleWorkerRoutine.SUCCESS:
      return {
        ...state,
        currentWorker: action.payload
      }
    case clearSingleWorkerRoutine.SUCCESS:
      return {
        ...state,
        currentWorker: defaultState.currentTeacher
      }
    case fetchSingleWorkerIncomeItemsRoutine.SUCCESS:
      return {
        ...state,
        currentWorkerIncomeItems: action.payload
      }
    default:
      return state
  }
}
