import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork } from '@redux-saga/core/effects'

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const setCurrentUserRoutine = createRoutine('GET_CURRENT_USER')
export const clearCurrentUserRoutine = createRoutine('CLEAR_CURRENT_USER')

function * loginUser () {
  yield put(loginUserRoutine.request())
  try {
    yield put(loginUserRoutine.success())
  } catch (e) {
    yield put(loginUserRoutine.failure(e))
    console.error(e)
  }
}

function * setCurrentUser ({ payload }) {
  yield put(setCurrentUserRoutine.request())
  try {
    yield put(setCurrentUserRoutine.success(payload))
  } catch (e) {
    yield put(setCurrentUserRoutine.failure(e))
    console.error(e)
  }
}

function * clearCurrentUser () {
  yield put(clearCurrentUserRoutine.success())
}

// WATCHERS
export function * loginUserWatcher () {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

export function * setCurrentUserWatcher () {
  yield takeLatest(setCurrentUserRoutine.TRIGGER, setCurrentUser)
}

export function * clearCurrentUserWatcher () {
  yield takeLatest(clearCurrentUserRoutine.TRIGGER, clearCurrentUser)
}

// SAGAS
export const authSagas = [
  fork(loginUserWatcher),
  fork(setCurrentUserWatcher),
  fork(clearCurrentUserWatcher)
]
