import React, { useEffect } from 'react'
import styled from 'styled-components'
import AddStudentForm from 'modules/students/components/AddStudentForm'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import { fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import StudentsTable from 'modules/students/components/StudentsTable'
import ExpandablePanel from 'components/ExpandablePanel'

const Students = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
    dispatch(fetchSchoolSubjectsRoutine())
    dispatch(getActiveStudentsRoutine())
  }, [])

  return (
    <div>
      <PageHeader>
        Uczniowie
      </PageHeader>
      <AddStudentForm />
      <ExpandablePanel startOpen title='Lista uczniów'>
        <StudentsTable />
      </ExpandablePanel>
    </div>
  )
}

export default Students

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
