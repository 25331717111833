import React from 'react'
import styled from 'styled-components'
import { pathOr, propOr } from 'ramda'
import { countLessonBalance, LESSON_TYPES } from 'utils/lessons'
import { DATE_FORMATS, formatDate } from 'utils/date'
import EditLessonModal from 'modules/lessons/components/EditLessonModal'
import DeleteLessonModal from 'modules/lessons/components/DeleteLessonModal'
import { parseNumber } from 'utils/numbers'

const LessonTableRow = ({ lesson, isStudent, isDashboard }) => {
  return (
    <Wrapper>
      <td>{formatDate(propOr('---', 'date', lesson), isDashboard ? DATE_FORMATS.dashboard : DATE_FORMATS.dashed)}</td>
      {
        isStudent
          ? <td>{pathOr('---', ['teacher', 'name'], lesson)}</td>
          : <td>{pathOr('---', ['student', 'name'], lesson)}</td>
      }
      <td>
        {
          isStudent
            ? pathOr('---', ['subject'], lesson)
            : isDashboard
              ? LESSON_TYPES[propOr('undefined', 'lessonType', lesson)].split(' ')[0]
              : LESSON_TYPES[propOr('undefined', 'lessonType', lesson)]
        }
      </td>
      <td>{propOr('---', 'duration', lesson)}</td>
      {
        isDashboard ? (
          <>
            <td>{parseNumber(lesson.teacherRate) * parseNumber(lesson.duration)}</td>
            <td>{countLessonBalance(lesson)}</td>
          </>
        ) : (
          <>
            <StudentCashCell>{propOr('---', 'studentRate', lesson)}</StudentCashCell>
            <StudentCashCell>{propOr('---', 'teacherRate', lesson)}</StudentCashCell>
            {
              !isStudent && (
                <>
                  <td>{parseNumber(lesson.teacherRate) * parseNumber(lesson.duration)}</td>
                  <td>{parseNumber(lesson.studentRate) * parseNumber(lesson.duration)}</td>
                  <td>{countLessonBalance(lesson)}</td>
                </>
              )
            }
          </>
        )
      }
      <td>{propOr('---', 'comment', lesson)}</td>
      <td>
        <Actions onClick={e => e.stopPropagation()}>
          <EditLessonModal isDashboard={isDashboard} lesson={lesson} isStudent={isStudent} />
          <DeleteLessonModal lesson={lesson} isStudent={isStudent} />
        </Actions>
      </td>
    </Wrapper>
  )
}

export default LessonTableRow

const Wrapper = styled.tr`
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const StudentCashCell = styled.td`
  width: 80px;
`
