import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ExpandablePanel from 'components/ExpandablePanel'
import { getLessonsForAllStudents } from 'services/LessonsService'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { getExpensesForAllTeachers } from 'services/ExpensesService'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllTeachersList } from 'modules/teachers/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import FixedCostsForm from 'modules/finacnes/components/FixedCostsForm'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { getAdditionalFixedCosts, getFixedCosts, updateFixedCost } from 'services/fixedCostsService'
import OtherExpensesForm from 'modules/finacnes/components/OtherExpensesForm'
import { addCompanyExpense, getCompanyExpenses } from 'services/CompanyExpesnesService'
import Table from 'components/Table'
import { EXPENSE_TYPES } from 'utils/finances'
import DeleteOtherExpenseModal from 'modules/finacnes/components/DeleteOtherExpenseModal'
import { fetchWorkersListRoutine } from 'modules/workers/ducks/actions'
import { selectWorkersList } from 'modules/workers/ducks/selectors'
import { getIncomesForAllWorkers } from 'services/WorkersService'
import { parseNumber } from 'utils/numbers'
import AdditionalFixedCostsPanel from 'modules/finacnes/components/AdditionalFixedCostsPanel'

const defaultDates = {
  dateFrom: moment().startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input)
}

const Finances = () => {
  const [currentDate, setCurrentDate] = useState(moment())
  const [dates, setDates] = useState(defaultDates)
  const teachers = useSelector(selectAllTeachersList)
  const workers = useSelector(selectWorkersList)
  const [lessons, setLessons] = useState([])
  const [workerIncomes, setWorkerIncomes] = useState([])
  const [fixedCosts, setFixedCosts] = useState({})
  const [additionalFixedCosts, setAdditionalFixedCosts] = useState([])
  const [companyExpenses, setCompanyExpenses] = useState([])
  const [fixedCostsValues, setFixedCostsValues] = useState({ tax: 0, rent: 0, zus: 0 })

  const dispatch = useDispatch()

  useEffect(() => {
    setFixedCostsValues(fixedCosts)
  }, [fixedCosts])

  const decrementMonth = () => {
    setCurrentDate(prev => moment(prev).subtract(1, 'month'))
  }

  const incrementMonth = () => {
    setCurrentDate(prev => moment(prev).add(1, 'month'))
  }

  useEffect(() => {
    setDates({
      dateFrom: currentDate.startOf('month').format(DATE_FORMATS.input),
      dateTo: currentDate.endOf('month').format(DATE_FORMATS.input)
    })
  }, [currentDate])

  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
    dispatch(fetchWorkersListRoutine())
  }, [])

  const handleGetAdditionalFixedCosts = () => {
    const month = currentDate.locale('en').format('MMMM')
    const year = currentDate.format('YYYY')
    const title = `${month}${year}`

    getAdditionalFixedCosts({
      title,
      setFn: setAdditionalFixedCosts
    })
  }

  useEffect(() => {
    getLessonsForAllStudents({
      dateTo: dates.dateTo,
      dateFrom: dates.dateFrom,
      setFn: setLessons
    })
    getIncomesForAllWorkers({
      dateTo: dates.dateTo,
      dateFrom: dates.dateFrom,
      setFn: setWorkerIncomes
    })

    const month = currentDate.locale('en').format('MMMM')
    const year = currentDate.format('YYYY')
    const title = `${month}${year}`

    getFixedCosts({
      title,
      setFn: setFixedCosts
    })

    getAdditionalFixedCosts({
      title,
      setFn: setAdditionalFixedCosts
    })

    getCompanyExpenses({
      title,
      setFn: setCompanyExpenses
    })

  }, [dates])

  const teachersWithDetails = useMemo(() => {
    const n = num => parseNumber(num)
    return teachers.map(teacher => {
      const teacherLessons = lessons.filter(lesson => lesson.teacher.id === teacher.id)

      if (isNotNilOrEmpty(teacherLessons)) {
        const lessonsBalance = teacherLessons.reduce((acc, { teacherRate, studentRate, duration }) => {
          return acc + n(studentRate) * n(duration) - n(teacherRate) * n(duration)
        }, 0)

        return {
          ...teacher,
          result: lessonsBalance
        }
      } else {
        return null
      }
    }).filter(t => isNotNilOrEmpty(t))
  }, [lessons, teachers])

  const workersWithDetails = useMemo(() => {
    return workers.map(worker => {
      const incomes = workerIncomes.filter(income => income.workerId === worker.id)

      if (isNotNilOrEmpty(incomes)) {
        const balance = incomes.reduce((acc, { value }) => {
          return acc + parseNumber(value)
        }, 0)

        return {
          ...worker,
          result: balance
        }
      } else {
        return null
      }
    }).filter(w => isNotNilOrEmpty(w))
  }, [workerIncomes, workers])

  const handleFixedCostsUpdate = values => {
    const month = currentDate.locale('en').format('MMMM')
    const year = currentDate.format('YYYY')
    const title = `${month}${year}`

    updateFixedCost({
      title,
      values,
      callback: () => {
        getFixedCosts({
          title,
          setFn: setFixedCosts
        })
      }
    })
  }

  const handleCompanyExpenseAdd = expense => {
    const month = currentDate.locale('en').format('MMMM')
    const year = currentDate.format('YYYY')
    const title = `${month}${year}`

    addCompanyExpense({
      title,
      newExpense: expense,
      callback: () => {
        getCompanyExpenses({
          title,
          setFn: setCompanyExpenses
        })
      }
    })
  }

  const totalTeachersIncome = useMemo(() => {
    if (isNotNilOrEmpty(teachersWithDetails)) {
      return teachersWithDetails.reduce((acc, { result }) => acc + parseNumber(result), 0)
    } else {
      return 0
    }
  }, [teachersWithDetails])

  const totalWorkersIncome = useMemo(() => {
    if (isNotNilOrEmpty(workersWithDetails)) {
      return workersWithDetails.reduce((acc, { result }) => acc + parseNumber(result), 0)
    } else {
      return 0
    }
  }, [teachersWithDetails])

  const companyExpensesBalance = useMemo(() => {
    if (isNotNilOrEmpty(companyExpenses)) {
      let result = 0
      companyExpenses.forEach(expense => {
        if (expense.type === 'expense') {
          result -= parseNumber(expense.value)
        } else {
          result += parseNumber(expense.value)
        }
      })
      return result
    } else {
      return 0
    }
  }, [companyExpenses])

  const fixedCostsBalance = useMemo(() => {
    if (isNotNilOrEmpty(fixedCosts)) {
      const { rent, zus, tax } = fixedCosts
      return parseNumber(rent) + parseNumber(zus) + parseNumber(tax)
    } else {
      return 0
    }
  }, [fixedCosts])

  const additionalFixedCostsBalance = useMemo(() => {
    if (isNotNilOrEmpty(additionalFixedCosts)) {
      return additionalFixedCosts.reduce((acc, current) => acc + parseNumber(current.value), 0)
    } else {
      return 0
    }
  }, [additionalFixedCosts])
  console.log({ additionalFixedCosts, additionalFixedCostsBalance })

  const totalIncome = useMemo(() => {
    return totalTeachersIncome - totalWorkersIncome + companyExpensesBalance - fixedCostsBalance - additionalFixedCostsBalance
  }, [totalTeachersIncome, totalWorkersIncome, companyExpensesBalance, fixedCostsBalance, additionalFixedCostsBalance])

  return (
    <>
      <PageHeader>
        Finanse
      </PageHeader>
      <DateFilters>
        <ArrowBackIosNewIcon onClick={decrementMonth} />
        <MonthName>{currentDate.locale('pl').format('MMMM')} {currentDate.format('YYYY')}</MonthName>
        <ArrowForwardIosIcon onClick={incrementMonth} />
      </DateFilters>
      <ExpandablePanel title='Bilans miesiąca' startOpen>
        {
          totalTeachersIncome > 0 && (
            <TotalItem>
              <div>Przychód z nauczycieli:</div>
              <div>{totalTeachersIncome}zł</div>
            </TotalItem>
          )
        }
        {
          totalWorkersIncome > 0 && (
            <TotalItem>
              <div>Wydatek na pracowników:</div>
              <div>-{totalWorkersIncome}zł</div>
            </TotalItem>
          )
        }
        {
          companyExpensesBalance !== 0 && (
            <TotalItem>
              <div>Dodatkowe koszta:</div>
              <div>{companyExpensesBalance}zł</div>
            </TotalItem>
          )
        }
        {
          (fixedCostsBalance !== 0 || additionalFixedCostsBalance !== 0) && (
            <TotalItem>
              <div>Koszta stałe:</div>
              <div>{(fixedCostsBalance + additionalFixedCostsBalance) * -1}zł</div>
            </TotalItem>
          )
        }
        {
          totalIncome !== 0 && (
            <TotalItem>
              <div>Łączny bilans:</div>
              <div>{totalIncome}zł</div>
            </TotalItem>
          )
        }
        {
          fixedCostsBalance === 0 && totalIncome === 0 && companyExpensesBalance === 0 && totalWorkersIncome <= 0 && (
            <div>Brak elementów do podsumowania</div>
          )
        }
      </ExpandablePanel>
      <ExpandablePanel title='Nauczyciele'>
        <TeachersWrapper>
          {
            isNotNilOrEmpty(teachersWithDetails) ? teachersWithDetails.map(teacher => (
              <SingleTeacher key={teacher.id}>
                <TeacherName>{teacher.name}</TeacherName>
                <TeacherResult>{teacher.result}zł</TeacherResult>
              </SingleTeacher>
            )) : <div>Brak lekcji w tym miesiącu</div>
          }
        </TeachersWrapper>
        {
          isNotNilOrEmpty(teachersWithDetails) && (
            <TotalWrapper>
              Łączny przychód: {totalTeachersIncome}zł
            </TotalWrapper>
          )
        }
      </ExpandablePanel>
      <ExpandablePanel title='Pracownicy'>
        <TeachersWrapper>
          {
            isNotNilOrEmpty(workersWithDetails) ? workersWithDetails.map(worker => (
              <SingleTeacher key={worker.id}>
                <TeacherName>{worker.name}</TeacherName>
                <TeacherResult>{worker.result * -1}zł</TeacherResult>
              </SingleTeacher>
            )) : <div>Brak wypłat w tym miesiącu</div>
          }
        </TeachersWrapper>
        {
          isNotNilOrEmpty(workersWithDetails) && (
            <TotalWrapper>
              Łączny wydatek: {totalWorkersIncome}zł
            </TotalWrapper>
          )
        }
      </ExpandablePanel>
      <ExpandablePanel title='Koszta stałe'>
        <FixedCostsForm values={fixedCostsValues} onSubmit={handleFixedCostsUpdate} />
        <AdditionalFixedCostsPanel
          costs={additionalFixedCosts}
          callback={handleGetAdditionalFixedCosts}
        />
        {
          fixedCostsBalance !== 0 && (
            <TotalWrapper>
              Łączny wydatek: {fixedCostsBalance}zł
            </TotalWrapper>
          )
        }
      </ExpandablePanel>
      <ExpandablePanel title='Dodatkowe wydatki/przychody'>
        <OtherExpensesForm onSubmit={handleCompanyExpenseAdd} />
        {
          isNotNilOrEmpty(companyExpenses)
            ? (
              <Table>
                <thead>
                <tr>
                  <th>Opis</th>
                  <th>Kwota</th>
                  <th>Rodzaj</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {companyExpenses.map(expense => {
                  return (
                    <tr>
                      <td>{expense.title}</td>
                      <td>{expense.value}</td>
                      <td>{EXPENSE_TYPES[expense.type]}</td>
                      <Actions>
                        <DeleteOtherExpenseModal date={currentDate} expenseId={expense.id} setFn={setCompanyExpenses} />
                      </Actions>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            )
            : (
              <EmptyState>
                Brak wydatków/przychodów w tym miesiącu
              </EmptyState>
            )
        }
        {
          isNotNilOrEmpty(companyExpenses) && (
            <TotalWrapper>
              Bilans: {companyExpensesBalance}zł
            </TotalWrapper>
          )
        }
      </ExpandablePanel>
    </>
  )
}

export default Finances

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`

const DateFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  text-transform: capitalize;
  font-size: 22px !important;
  margin-bottom: 20px;
  
  svg {
    cursor: pointer;
  }
`

const TeachersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-row-gap: 20px;
  margin-top: 10px;
`

const SingleTeacher = styled.div``

const TeacherName = styled.div`
  font-weight: bold;
  font-size: 14px;
`

const TeacherResult = styled.div`
  font-size: 14px;
`

const MonthName = styled.div`
  width: 200px;
  text-align: center;
`

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`

const TotalItem = styled.div`
  padding: 5px 20%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:last-of-type {
    font-weight: bold;
    color: #fff;
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`
