import { pathOr, propOr } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'

const Tabs = ({ tabs }) => {
  const { location: { search } } = useHistory()
  const keys = tabs.map(tab => tab.key)
  const currentQuery = getCurrentParsedQuery()
  const initialTab = keys.includes(currentQuery.activeTab) ? currentQuery.activeTab : pathOr('', [0, 'key'], tabs)
  const [activeTab, setActiveTab] = useState(initialTab)

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    if (isNotNilOrEmpty(currentQuery.activeTab || !keys.includes(currentQuery.activeTab))) {
      changeQuery({
        ...currentQuery,
        activeTab: initialTab
      })
    }
  }, [search])

  const changeTabHandler = key => () => {
    const currentQuery = getCurrentParsedQuery()
    setActiveTab(key)
    changeQuery({
      ...currentQuery,
      activeTab: key
    })
  }

  const tabsHeaders = useMemo(() => {
    return tabs.map((tab) => (
      <TabTitle
        key={tab.key}
        onClick={changeTabHandler(tab.key)}
        active={tab.key === activeTab}
      >
        {tab.title}
      </TabTitle>
    ))
  }, [tabs, activeTab])

  const tabContent = useMemo(() => {
    const tab = tabs.find(item => item.key === activeTab)
    return propOr(null, 'children', tab)
  }, [tabs, activeTab])

  return (
    <>
      <TabsWrapper>
        {tabsHeaders}
      </TabsWrapper>
      <TabContent>
        {tabContent}
      </TabContent>
    </>
  )
}

Tabs.defaultProps = {
  tabs: []
}

export default Tabs

const TabsWrapper = styled.div`
  display: flex;
`

const TabTitle = styled.div`
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;

  ${({ active }) => active && css`
    color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main};
  `}
`

const TabContent = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 20px 0;
`
