import React, { useState } from 'react'
import { Drawer } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components'
import NavigationContent from 'components/navigation/NavigationContent'

const MobileNavigation = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Trigger onClick={handleOpen}>
        <MenuIcon />
      </Trigger>
      <Drawer
        anchor='left'
        open={open}
        onClose={handleClose}
      >
        <Wrapper>
          <NavigationContent />
        </Wrapper>
      </Drawer>
    </>
  )
}

export default MobileNavigation

const Trigger = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`

const Wrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.main};
`
