import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import { fetchSchoolSubjects, getEmailTemplateSettings } from 'services/SettingsService'

export const fetchSchoolSubjectsRoutine = createRoutine('FETCH_SCHOOL_SUBJECTS')
export const fetchEmailTemplateSettingsRoutine = createRoutine('FETCH_EMAIL_TEMPLATE_SETTINGS')

function * getSchoolSubjects () {
  yield put(fetchSchoolSubjectsRoutine.request())
  try {
    const result = yield call(fetchSchoolSubjects)
    yield put(fetchSchoolSubjectsRoutine.success(result))
  } catch (e) {
    yield put(fetchSchoolSubjectsRoutine.failure(e))
    console.error(e)
  }
}

function * fetchEmailTemplateSettings () {
  yield put(fetchEmailTemplateSettingsRoutine.request())
  try {
    const result = yield call(getEmailTemplateSettings)
    yield put(fetchEmailTemplateSettingsRoutine.success(result))
  } catch (e) {
    yield put(fetchEmailTemplateSettingsRoutine.failure(e))
    console.error(e)
  }
}

export function * fetchSchoolSubjectsWatcher () {
  yield takeLatest(fetchSchoolSubjectsRoutine.TRIGGER, getSchoolSubjects)
}

export function * fetchEmailTemplateSettingsWatcher () {
  yield takeLatest(fetchEmailTemplateSettingsRoutine.TRIGGER, fetchEmailTemplateSettings)
}

export const settingsSagas = [
  fork(fetchSchoolSubjectsWatcher),
  fork(fetchEmailTemplateSettingsWatcher)
]
