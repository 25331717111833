import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { removeStudent } from 'services/StudentsService'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'

const DeleteStudentModal = ({ student, actionCallback }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleDelete = () => {
    removeStudent({
      student,
      callback: () => {
        isNotNilOrEmpty(actionCallback) ? actionCallback() : dispatch(getActiveStudentsRoutine())
      }
    })
  }

  return (
      <>
        <Trigger onClick={handleOpen}>
          <StyledIcon />
        </Trigger>
        <Modal
          title='Usuń'
          open={open}
          onClose={handleClose}
        >
          {
            parseNumber(student.balance) !== 0
            ? (
                <Warning>
                  Nie można usunąć ucznia. Jego obecne saldo wynosi {student.balance}.
                </Warning>
              )
              : (
                <div>
                  Czy na pewno chcesz usunąć ucznia <StudentName>{student.name}</StudentName>?
                </div>
              )
          }
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button color='primary' disabled={parseNumber(student.balance) !== 0} onClick={handleDelete}>Usuń</Button>
          </ButtonsWrapper>
        </Modal>
      </>
  )
}

export default DeleteStudentModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const StudentName = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

const Warning = styled.div`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 20px;
`
