import React, { useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { dissoc, propOr } from 'ramda'
import PATHS from 'utils/paths'

const PrivateRoute = props => {
  const { allowedRoles } = props
  const user = useSelector(selectCurrentUser)
  const role = propOr('', 'role', user)

  const hasValidRole = useMemo(() => {
    return role && allowedRoles
      ? allowedRoles.includes(role)
      : false
  }, [role, allowedRoles])

  if (isNotNilOrEmpty(role)) {
    return hasValidRole
      ? <Route {...dissoc('allowedRoles', props)} />
      : <Redirect to={PATHS.login} />
  } else {
    return <div />
  }

}

export default PrivateRoute
