import React, { useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import Input from 'components/Input'
import styled from 'styled-components'
import Button from 'components/Button'
import { isNilOrEmpty } from 'utils/ramda'
import { changeUserPassword } from 'services/AuthService'

const Profile = () => {
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    changeUserPassword(password, () => {
      setPassword('')
      setPassword2('')
    })
  }

  return (
    <>
      <PageHeader>
        Profil użytkownika
      </PageHeader>
      <ExpandablePanel startOpen title='Zmiana hasła'>
        <form onSubmit={handleSubmit}>
          <Input
            name='password'
            type='password'
            label='Nowe hasło'
            value={password}
            onChange={(_, value) => setPassword(value)}
          />
          <Input
            name='password2'
            type='password'
            label='Powtórz hasło'
            value={password2}
            onChange={(_, value) => setPassword2(value)}
          />
          <ButtonWrapper>
            <Button
              color='primary'
              disabled={isNilOrEmpty(password) || isNilOrEmpty(password2) || password !== password2}
              type='submit'
            >
              Zapisz nowe hasło
            </Button>
          </ButtonWrapper>
        </form>
      </ExpandablePanel>
    </>
  )
}

export default Profile

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 200px;
  }
`

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
