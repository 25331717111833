import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { propOr } from 'ramda'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { selectCurrentWorker } from 'modules/workers/ducks/selectors'
import EditWorkerModal from 'modules/workers/components/EditWorkerModal'

const WorkerBasicDetails = () => {
  const worker = useSelector(selectCurrentWorker)

  return (
    <TeacherName>
      {worker.name}
      <EditWorkerModal worker={worker} isSingleWorkerView />
    </TeacherName>
    // <PanelWrapper>
    //   <Wrapper>
    //     <ImageWrapper>
    //       <UserIcon />
    //     </ImageWrapper>
    //     <UserDetailsWrapper>
    //
    //       <DetailsWrapper>
    //         <div>
    //           <SectionName>Dane pracownika</SectionName>
    //           <UserDetail>
    //             <div>ID:</div>
    //             <div>{worker.id}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Email:</div>
    //             <div>{propOr('---', 'email', worker)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Numer telefonu:</div>
    //             <div>{propOr('---', 'phoneNumber', worker)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Data utworzenia:</div>
    //             <div>{formatDate(worker.createdAt, DATE_FORMATS.dashed)}</div>
    //           </UserDetail>
    //         </div>
    //       </DetailsWrapper>
    //     </UserDetailsWrapper>
    //   </Wrapper>
    // </PanelWrapper>
  )
}

export default WorkerBasicDetails

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  max-width: 600px;
`

const TeacherName = styled.div`
  display: flex;
  gap: 15px;
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
`

const SectionName = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`

const UserDetailsWrapper = styled.div`
  width: 100%;
`

const DetailsWrapper = styled.div`
  display: flex;
  gap: 30px;
  
  & > div {
    flex: 1;
  }
`

const UserIcon = styled(AccountCircleIcon)`
  font-size: 150px !important;
  color: ${({ theme }) => theme.colors.secondary.main};
`

const UserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const PanelWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`
