const PATHS = {
  login: '/',
  teachers: '/teachers',
  workers: '/workers',
  workerDetails: '/workers/:id',
  teacherDetails: '/teachers/:id',
  students: '/students',
  studentDetails: '/students/:id',
  debtors: '/debtors',
  settings: '/settings',
  profile: '/profile',
  finances: '/finances',
  dashboard: '/dashboard',
  message: '/message',
  archivedStudents: '/archived-students',
  archivedTeachers: '/archived-teachers',
  calendarSync: '/calendar-sync',
  teachersIncomes: '/payouts',
  myStudents: '/my-students',
  panelTopicality: '/topicality'
}

export default PATHS
