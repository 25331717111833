import React, { useEffect, useState } from 'react'
import { getBlockData, setBlockData } from 'services/SettingsService'
import ExpandablePanel from 'components/ExpandablePanel'
import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import styled from 'styled-components'

const emptyValues = {
  message: '',
  isBlocked: false,
  key: ''
}

const BlockPanel = () => {
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    getBlockData()
      .then(resp => {
        setValues(resp)
      })
  }, [])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setBlockData(values)
  }

  return (
    <ExpandablePanel title='Blokada panelu'>
        <Input
          name='message'
          label='Wiadomość'
          value={values.message}
          onChange={handleValueChange}
        />
        <Checkbox
          name='isBlocked'
          label='Blokada włączona'
          value={values.isBlocked}
          onChange={handleValueChange}
        />
        <ButtonWrapper>
          <Button onClick={handleSubmit}>
            Zapisz
          </Button>
        </ButtonWrapper>
    </ExpandablePanel>
  )
}

export default BlockPanel

const ButtonWrapper = styled.form`
  margin-top: 10px;
  max-width: 200px;
`
