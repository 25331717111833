import { filter, pipe, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectTeachers = state => state.workers

export const selectWorkersList = createSelector(
  selectTeachers,
  pipe(
    propOr([], 'list'),
    filter(item => !item.removed)
  )
)

export const selectCurrentWorker = createSelector(
  selectTeachers,
  propOr({}, 'currentWorker')
)

export const selectCurrentWorkerIncomeItems = createSelector(
  selectTeachers,
  propOr([], 'currentWorkerIncomeItems')
)
