import { filter, pipe, propOr, sortBy, prop } from 'ramda'
import { createSelector } from 'reselect'

export const selectTeachers = state => state.teachers

export const selectAllTeachersList = createSelector(
  selectTeachers,
  pipe(
    propOr([], 'list'),
    sortBy(prop('name'))
  )
)

export const selectTeachersList = createSelector(
  selectTeachers,
  pipe(
    propOr([], 'list'),
    filter(item => !item.removed),
    sortBy(prop('name'))
  )
)

export const selectArchivedTeachersList = createSelector(
  selectTeachers,
  propOr([], 'archivedList')
)

export const selectCurrentTeacher = createSelector(
  selectTeachers,
  propOr({}, 'currentTeacher')
)

export const selectCurrentTeacherLessons = createSelector(
  selectTeachers,
  propOr([], 'currentTeacherLessons')
)

export const selectCurrentTeacherExpenses = createSelector(
  selectTeachers,
  propOr([], 'currentTeacherExpenses')
)

export const selectCurrentTeacherOtherAmounts = createSelector(
  selectTeachers,
  propOr([], 'currentTeacherOther')
)

export const selectCurrentTeacherDebitItems = createSelector(
  selectTeachers,
  propOr([], 'currentTeacherDebitItems')
)

export const selectTeachersSalaries = createSelector(
  selectTeachers,
  propOr([], 'teachersSalaries')
)
