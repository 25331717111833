import {
  clearSingleTeacherRoutine,
  fetchArchivedTeachersListRoutine,
  fetchSingleTeacherDebitItemsRoutine,
  fetchSingleTeacherExpensesRoutine,
  fetchSingleTeacherLessonsRoutine, fetchSingleTeacherOtherAmountsRoutine,
  fetchSingleTeacherRoutine,
  fetchTeachersListRoutine,
  fetchTeachersSalariesRoutine
} from 'modules/teachers/ducks/actions'

const defaultState = {
  list: [],
  archivedList: [],
  currentTeacher: {},
  currentTeacherLessons: [],
  currentTeacherExpenses: [],
  currentTeacherOther: [],
  currentTeacherDebitItems: [],
  teachersSalaries: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case fetchTeachersListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case fetchArchivedTeachersListRoutine.SUCCESS:
      return {
        ...state,
        archivedList: action.payload
      }
    case fetchSingleTeacherRoutine.SUCCESS:
      return {
        ...state,
        currentTeacher: action.payload
      }
    case clearSingleTeacherRoutine.SUCCESS:
      return {
        ...state,
        currentTeacher: defaultState.currentTeacher,
        currentTeacherLessons: [],
        currentTeacherExpenses: [],
        currentTeacherDebitItems: []
      }
    case fetchSingleTeacherLessonsRoutine.SUCCESS:
      return {
        ...state,
        currentTeacherLessons: action.payload
      }
    case fetchSingleTeacherExpensesRoutine.SUCCESS:
      return {
        ...state,
        currentTeacherExpenses: action.payload
      }
    case fetchSingleTeacherOtherAmountsRoutine.SUCCESS:
      return {
        ...state,
        currentTeacherOther: action.payload
      }
    case fetchSingleTeacherDebitItemsRoutine.SUCCESS:
      return {
        ...state,
        currentTeacherDebitItems: action.payload
      }
    case fetchTeachersSalariesRoutine.SUCCESS:
      return {
        ...state,
        teachersSalaries: action.payload
      }
    default:
      return state
  }
}
