import {
  clearCurrentUserRoutine,
  loginUserRoutine, setCurrentUserRoutine
} from 'modules/auth/ducks/actions'

const defaultState = {
  user: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case loginUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case setCurrentUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case clearCurrentUserRoutine.SUCCESS:
      return {
        ...state,
        user: defaultState.user
      }
    default:
      return state
  }
}
