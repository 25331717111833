import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { changeQuery } from 'utils/navigation'
import Input from 'components/Input'
import styled from 'styled-components'
import { fetchSingleStudentDebitItemsRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import StudentDebitTable from 'modules/studentDetails/components/StudentDebitTable'
import AddPaymentForm from 'modules/studentDetails/components/AddPaymentForm'

const defaultDates = {
  dateFrom: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input),
}

const StudentDetailsDebitHistory = () => {
  const student = useSelector(selectCurrentStudent)
  const [dates, setDates] = useState(defaultDates)
  const dispatch = useDispatch()

  const handleDateChange = (name, value) => {
    setDates(prev => ({ ...prev, [name]: value }))
  }

  const fetchStudentDebitItems = () => {
    dates.dateFrom && student.id && dispatch(fetchSingleStudentDebitItemsRoutine({
      id: student.id,
      ...dates
    }))
  }

  useEffect(() => {
    fetchStudentDebitItems()
  }, [dates, student])

  useEffect(() => {
    changeQuery(dates)
  }, [dates])

  return (
    <>
      <Header>
        <AddPaymentForm
          callback={() => {
            fetchStudentDebitItems()
            dispatch(fetchSingleStudentRoutine({ id: student.id }))
          }}
          student={student}
        />
        <DateFilters>
          <Input
            type='date'
            label='Data od'
            name='dateFrom'
            onChange={handleDateChange}
            value={dates.dateFrom}
          />
          <Input
            type='date'
            label='Data do'
            name='dateTo'
            onChange={handleDateChange}
            value={dates.dateTo}
          />
        </DateFilters>
      </Header>
      <StudentDebitTable />
    </>
  )
}

export default StudentDetailsDebitHistory


const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`

const DateFilters = styled.div`
  display: flex;
  gap: 15px;
  padding-right: 20px;
`
