import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'

// TEST
// const firebaseConfig = {
//   apiKey: "AIzaSyCnxbCK-dH4nQHfx_sqAkm33BnCQH47VyU",
//   authDomain: "sshool-fb626.firebaseapp.com",
//   databaseURL: "https://sshool-fb626.firebaseio.com",
//   projectId: "sshool-fb626",
//   storageBucket: "sshool-fb626.appspot.com",
//   messagingSenderId: "369996439458",
//   appId: "1:369996439458:web:3932b667f848cc4429dcfb"
// }

// PROD
const firebaseConfig = {
  apiKey: "AIzaSyCKfAGwNTz5QJpwcrY3KwxNh8SUJQBNtg4",
  authDomain: "smart-school-new.firebaseapp.com",
  databaseURL: "https://smart-school-new.firebaseio.com",
  projectId: "smart-school-new",
  storageBucket: "smart-school-new.appspot.com",
  messagingSenderId: "139511065685",
  appId: "1:139511065685:web:7083abcfa45affa88eb8dc"
}

firebase.initializeApp(firebaseConfig)
firebase.firestore().settings({ timestampsInSnapshots: true, experimentalForceLongPolling: true })

export const db = firebase.firestore()

export const secondaryApp = firebase.initializeApp(firebaseConfig, 'secondary')

export default firebase
