import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { fetchWorkersListRoutine } from 'modules/workers/ducks/actions'
import { removeWorker } from 'services/WorkersService'

const DeleteTeacherModal = ({ worker }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSuccess = () => {
    dispatch(fetchWorkersListRoutine())
    handleClose()
  }

  const handleDelete = () => {
    removeWorker({
      worker,
      callback: handleSuccess
    })
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Usuń'
        open={open}
        onClose={handleClose}
      >
        Czy na pewno chcesz usunąć pracownika <TeacherName>{worker.name}</TeacherName>?
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleDelete}>Usuń</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default DeleteTeacherModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const TeacherName = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
