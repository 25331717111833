import React from 'react'
import Table from 'components/Table'
import { LESSON_TYPES } from 'utils/lessons'
import EditStudentTeacherModal from 'modules/students/components/EditStudentTeacherModal'
import styled from 'styled-components'
import { redirect } from 'utils/navigation'
import DeleteStudentTeacherModal from 'modules/students/components/DeleteStudentTeacherModal'

const StudentTeachersList = ({ teachers = [], onEditTeacher, hideActions, onDeleteTeacher }) => {
  return (
    <Table>
      <thead>
      <tr>
        <th>Nazwa</th>
        <th>Przedmiot</th>
        <th>Typ lekcji</th>
        <th>Czas</th>
        <th>Stawka ucz.</th>
        <th>Stawka nau.</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {teachers.map((teacher, index) => (
        <RowWrapper onClick={() => redirect(`/teachers/${teacher.id}`)} key={teacher.id}>
          <td>{teacher.name}</td>
          <td>{teacher.subject}</td>
          <td>{LESSON_TYPES[teacher.lessonType]}</td>
          <td>{teacher.lessonDuration}</td>
          <td>{teacher.studentRate}</td>
          <td>{teacher.teacherRate}</td>
          <td>
            <Actions onClick={e => e.stopPropagation()}>
              {
                !hideActions && (
                  <>
                    <EditStudentTeacherModal
                      index={index}
                      teacher={teacher}
                      onEditTeacher={onEditTeacher}
                    />
                    <DeleteStudentTeacherModal
                      teacher={teacher}
                      onDeleteTeacher={onDeleteTeacher}
                      index={index}
                    />
                  </>
                )
              }
            </Actions>
          </td>
        </RowWrapper>
      ))}
      </tbody>
    </Table>
  )
}

export default StudentTeachersList

const RowWrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
