import React, { useEffect, useMemo, useState } from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'
import Input from 'components/Input'
import Button from 'components/Button'
import { propOr } from 'ramda'
import { updateTeacherSalary } from 'services/TeachersService'
import moment from 'moment'
import { fetchTeachersSalariesRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'
import CloseIcon from '@mui/icons-material/Close'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'

const initialValues = {
  comment: '',
  value: 0,
  color: 'transparent'
}

const EditCellTooltip = ({ item, children, teacher }) => {
  const [open, setOpen] = useState(false)
  const hasComment = isNotNilOrEmpty(item.comment)
  const [values, setValues] = useState(initialValues)
  const dates = {
    current: moment(),
    prev5: moment().subtract(5, 'month')
  }
  const dispatch = useDispatch()

  const setItemValues = () => {
    setValues({
      comment: item.comment,
      value: parseNumber(item.value) * -1,
      color: propOr('transparent', 'color', item)
    })
  }

  useEffect(() => {
    setItemValues()
  }, [item])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const textColor = useMemo(() => {
    switch(values.color) {
      case 'blue':
      case 'green':
      case 'red':
        return '#fff'
      default:
        return '#000'
    }
  }, [values.color])

  const handleSubmit = e => {
    e.preventDefault()
    const difference = parseNumber(item.value) * -1 - parseNumber(values.value)

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) + difference
    }

    const successCallback = () => {
      setOpen(false)
      dispatch(fetchTeachersSalariesRoutine({ dates }))
    }

    const teacherDebitPayload = {
      ...item,
      ...values,
      value: parseNumber(values.value) * -1,
      balanceAfter: parseNumber(item.balanceAfter) + difference
    }

    updateTeacherSalary({
      values: {
        teacherPayload,
        teacherDebitPayload,
        difference
      },
      callback: successCallback
    })
  }

  const toggleOpen = () => {
    setOpen(prev => {
      prev && setItemValues()
      return !prev
    })
  }

  const handleClose = e => {
    e.stopPropagation()
    toggleOpen()
  }

  return (
    <Tooltip
      arrow
      key={item.id}
      open={open}
      disableHoverListener
      onClick={toggleOpen}
      placement='bottom-start'
      title={(
        <TooltipComponent>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <ColorPicker>
            <ColorBox color='transparent' onClick={() => handleValueChange('color', 'transparent')} />
            <ColorBox color='yellow' onClick={() => handleValueChange('color', 'yellow')} />
            <ColorBox color='green' onClick={() => handleValueChange('color', 'green')} />
            <ColorBox color='red' onClick={() => handleValueChange('color', 'red')} />
            <ColorBox color='blue' onClick={() => handleValueChange('color', 'blue')} />
          </ColorPicker>
          <form onSubmit={handleSubmit}>
            <Input
              variant='outlined'
              name='value'
              label='Kwota'
              noMarginBottom
              value={values.value}
              onChange={handleValueChange}
            />
            <CommentWrapper>
              <Input
                variant='outlined'
                multiline
                rows={2}
                value={values.comment}
                name='comment'
                label='Komentarz'
                noMarginBottom
                onChange={handleValueChange}
              />
            </CommentWrapper>
            <Button small type='submit'>Zapisz</Button>
          </form>
        </TooltipComponent>
      )}
    >
      <TooltipChild
        color={values.color}
        textColor={textColor}
      >
        {hasComment && (
          <Tooltip title={<CommentTooltipContent>{values.comment}</CommentTooltipContent>}>
            <CommentBadge textColor={textColor}>
              <CommentOutlinedIcon />
            </CommentBadge>
          </Tooltip>
        )}
        {children}
      </TooltipChild>
    </Tooltip>
  )
}

export default EditCellTooltip

const TooltipComponent = styled.div`
  width: 200px;
  background-color: white;
  padding: 10px 0;
  position: relative;
`

const ColorPicker = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  justify-content: center;
`

const ColorBox = styled.div`
  background-color: ${({ color }) => color};
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  cursor: pointer;
`

const TooltipChild = styled.div`
  cursor: pointer;
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const CommentBadge = styled.div`
  position: absolute;
  left: 10px;
  top: 8px;
  color: ${({ textColor }) => textColor};
  
  & > * {
    font-size: 16px !important;
  }
`

const CommentWrapper = styled.div`
  margin: 15px 0;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

const CommentTooltipContent = styled.div`
  font-size: 16px;
`
