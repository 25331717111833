import React, { useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import { addWorker } from 'services/WorkersService'
import { useDispatch } from 'react-redux'
import Input from 'components/Input'
import styled from 'styled-components'
import Button from 'components/Button'
import { DATE_FORMATS } from 'utils/date'
import moment from 'moment'
import { fetchWorkersListRoutine } from 'modules/workers/ducks/actions'
import { isNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  name: '',
  email: '',
  phoneNumber: '',
  password: 'Smart1!',
  role: 'worker',
  removed: false,
  balance: 0
}

const AddWorkerModal = () => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSuccess = () => {
    dispatch(fetchWorkersListRoutine())
    setValues(emptyValues)
  }

  const handleSubmit = e => {
    e.preventDefault()
    addWorker({
      values: { ...values, createdAt: moment().format(DATE_FORMATS.withTimeAndSeconds) },
      callback: handleSuccess
    })
  }

  return (
    <ExpandablePanel title='Nowy pracownik'>
      <Form onSubmit={handleSubmit}>
        <Input
          label='Nazwa'
          name='name'
          onChange={handleValueChange}
          value={values.name}
        />
        <Input
          label='Email'
          name='email'
          onChange={handleValueChange}
          value={values.email}
        />
        <Input
          label='Numer telefonu'
          name='phoneNumber'
          onChange={handleValueChange}
          value={values.phoneNumber}
        />
        <ButtonWrapper>
          <Button
            type='submit'
            color='primary-outline'
            disabled={isNilOrEmpty(values.name)}
          >
            Dodaj pracownika
          </Button>
        </ButtonWrapper>
      </Form>
    </ExpandablePanel>
  )
}

export default AddWorkerModal

const Form = styled.form`
  margin-top: 10px;
  width: 100%;
  gap: 10px;
  display: flex;
  
  input {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  min-width: 200px;  
`
