import React, { useEffect, useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import { useDispatch, useSelector } from 'react-redux'
import { isNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import Button from 'components/Button'
import { selectTeachersList } from 'modules/teachers/ducks/selectors'
import { selectActiveStudents } from 'modules/students/ducks/selectors'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import { updateTeacher } from 'services/TeachersService'
import { updateStudent } from 'services/StudentsService'

const BalanceResetPanel = () => {
  const teachers = useSelector(selectTeachersList)
  const students = useSelector(selectActiveStudents)
  const [doneTeachers, setDoneTeachers] = useState(0)
  const [doneStudents, setDoneStudents] = useState(0)
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
    dispatch(getActiveStudentsRoutine())
  }, [])

  const handleSubmit = () => {
    teachers.forEach((teacher, index) => {
      setTimeout(() => {
        updateTeacher({
          teacher: { ...teacher, balance: 0 },
          callback: () => setDoneTeachers(prev => prev + 1)
        })
      }, index * 1000)
    })

    students.forEach((student, index) => {
      setTimeout(() => {
        updateStudent({
          student: { ...student, balance: 0 },
          callback: () => setDoneStudents(prev => prev + 1)
        })
      }, index * 1000)
    })
  }

  return (
    <ExpandablePanel title='Reset salda'>
      <div>
        Zresetuj salda wszystkim uczniom i nauczycielom
      </div>
      <div>
        Nauczyciele: {doneTeachers}
      </div>
      <div>
        Uczniowie: {doneStudents}
      </div>
      <ButtonWrapper>
        <Button
          color='primary'
          onClick={handleSubmit}
          disabled={isNilOrEmpty(teachers) || isNilOrEmpty(students)}
        >
          Resetuj
        </Button>
      </ButtonWrapper>
    </ExpandablePanel>
  )
}

export default BalanceResetPanel

const ButtonWrapper = styled.div`
  button {
    max-width: 200px;
  }
`
