import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleStudent } from 'services/StudentsService'
import { getSingleDebitItem, getSingleTeacherDebitItem, justRemoveLesson, removeLesson } from 'services/LessonsService'
import { propOr } from 'ramda'
import { getCurrentParsedQuery } from 'utils/navigation'
import { fetchSingleTeacherLessonsRoutine, fetchSingleTeacherRoutine } from 'modules/teachers/ducks/actions'
import { fetchSingleStudentLessonsRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import { getSingleTeacher } from 'services/TeachersService'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { isCashLesson } from 'utils/lessons'
import { parseNumber } from 'utils/numbers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const DeleteLessonModal = ({ lesson, isStudent }) => {
  const [open, setOpen] = useState(false)
  const [student, setStudent] = useState({})
  const [teacher, setTeacher] = useState({})
  const [debit, setDebit] = useState({})
  const [teacherDebit, setTeacherDebit] = useState({})
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const isAdmin = user.role === 'admin'

  useEffect(() => {
    if (isNotNilOrEmpty(lesson) && open) {
      getSingleStudent(lesson.studentId, setStudent)
      getSingleDebitItem(lesson.debitId, setDebit)
      getSingleTeacherDebitItem(lesson.teacherDebitId, setTeacherDebit)
      getSingleTeacher({ id: lesson.teacher.id, callback: setTeacher })
    }
  }, [lesson, open])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setStudent({})
    setTeacher({})
    setDebit({})
    setTeacherDebit({})
  }

  const isButtonDisabled = useMemo(() => {
    return [student, teacher, debit, teacherDebit].some(item => isNilOrEmpty(item))
  }, [student, teacher, debit, teacherDebit])

  const successCallback = () => {
    const query = getCurrentParsedQuery()
    if (isStudent) {
      dispatch(fetchSingleStudentLessonsRoutine({ id: lesson.student.id, ...query }))
      dispatch(fetchSingleStudentRoutine({ id: lesson.student.id }))
    } else {
      dispatch(fetchSingleTeacherLessonsRoutine({ id: lesson.teacher.id, ...query }))
      dispatch(fetchSingleTeacherRoutine({ id: lesson.teacher.id }))
    }
    handleClose()
  }

  const justRemove = () => {
    justRemoveLesson({
      values: {
        debitId: debit.id,
        teacherDebit: teacherDebit.id,
        lessonId: lesson.id,
      },
      callback: successCallback
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isCash = isCashLesson(lesson.lessonType)

    const studentPayload = {
      ...student,
      balance: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student)) - parseNumber(debit.value)
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) - parseNumber(teacherDebit.value)
    }

    removeLesson({
      values: {
        lessonId: lesson.id,
        student: studentPayload,
        debit,
        teacherDebit,
        teacher: teacherPayload
      },
      callback: successCallback
    })
  }


  return (
      <>
        <Trigger onClick={handleOpen}>
          <StyledIcon />
        </Trigger>
        <Modal
          title='Usuń lekcję'
          open={open}
          onClose={handleClose}
        >
          Czy na pewno chcesz usunąć tę lekcję?
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button color='primary' disabled={isButtonDisabled} onClick={handleSubmit}>Usuń</Button>
          </ButtonsWrapper>
          {
            isAdmin && <Button color='primary' onClick={justRemove}>Usuń bez przeliczania</Button>
          }
        </Modal>
      </>
  )
}

export default DeleteLessonModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(DeleteIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
