import React from 'react'
import Table from 'components/Table'
import { useSelector } from 'react-redux'
import { selectCurrentStudentDebitItems } from 'modules/students/ducks/selectors'
import { STUDENT_DEBIT_ITEM_TYPES } from 'utils/student'
import { prop, propOr, reverse, sortBy } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import DeletePaymentModal from 'modules/studentDetails/components/DeletePaymentModal'
import EditPaymentModal from 'modules/studentDetails/components/EditPaymentModal'
import styled from 'styled-components'
import { isNilOrEmpty } from 'utils/ramda'
import EditStudentDebitModal from 'modules/studentDetails/components/EditStudentDebitModal'

const StudentDebitTable = () => {
  const list = useSelector(selectCurrentStudentDebitItems)
  const sorted = reverse(sortBy(prop('date'), list))

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Co?</th>
          <th>Przedmiot</th>
          <th>Data</th>
          <th>Liczba h</th>
          <th>Stawka/h</th>
          <th>Zmiana salda</th>
          <th>Saldo po</th>
          <th>Komentarz</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          sorted.map(item => (
            <TableRow value={item.value} key={item.id}>
              <td>{STUDENT_DEBIT_ITEM_TYPES[item.type]}</td>
              <td>{propOr('---', 'subject', item)}</td>
              <td>{formatDate(item.date, DATE_FORMATS.dashed)}</td>
              <td>{propOr('---', 'duration', item)}</td>
              <td>{propOr('---', 'studentRate', item)}</td>
              <td>{propOr('---', 'value', item)}</td>
              <td>{propOr('---', 'balanceAfter', item)}</td>
              <td>{propOr('---', 'comment', item)}</td>
              <td>
                <Actions onClick={e => e.stopPropagation()}>
                  {item.type === 'payment' && (
                    <>
                      <EditPaymentModal payment={item} />
                      <DeletePaymentModal payment={item} />
                    </>
                  )}
                  <EditStudentDebitModal item={item} />
                </Actions>
              </td>
            </TableRow>
          ))
        }
        </tbody>
      </Table>
      {isNilOrEmpty(list) && (
        <EmptyState>
          Brak wpisów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default StudentDebitTable

const Actions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`

const TableRow = styled.tr`
  color: ${({ value }) => value === 0 ? 'default' : value > 0 ? 'green' : 'red'};
`

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`
