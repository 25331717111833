import React, { useEffect } from 'react'
import styled from 'styled-components'
import AddTeacherForm from 'modules/teachers/components/AddTeacherForm'
import TeachersTable from 'modules/teachers/components/TeachersTable'
import ExpandablePanel from 'components/ExpandablePanel'
import { fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { useDispatch } from 'react-redux'

const Teachers = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
  }, [])

  return (
    <>
      <PageHeader>
        Nauczyciele
      </PageHeader>
      <AddTeacherForm />
      <ExpandablePanel startOpen title='Lista nauczycieli'>
        <TeachersTable />
      </ExpandablePanel>
    </>
  )
}

export default Teachers

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`
