import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentParsedQuery } from 'utils/navigation'
import { fetchSingleTeacherDebitItemsRoutine } from 'modules/teachers/ducks/actions'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { updateTeacherDebitItem } from 'services/TeachersService'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import Input from 'components/Input'
import { updateStudentDebitItem } from 'services/StudentsService'
import { fetchSingleStudentDebitItemsRoutine } from 'modules/students/ducks/actions'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'

const EditStudentDebitModal = ({ item }) => {
  const [values, setValues] = useState({})
  const student = useSelector(selectCurrentStudent)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setValues(item)
  }, [item])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const successCallback = () => {
      const query = getCurrentParsedQuery()
      dispatch(fetchSingleStudentDebitItemsRoutine({ id: student.id, ...query }))
      handleClose()
    }

    updateStudentDebitItem({
      values,
      callback: successCallback
    })
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj wpis historii salda'
        open={open}
        onClose={handleClose}
      >
        <AlertWrapper>
          <AlertTitle>UWAGA!</AlertTitle>
          <AlertText>Ta zmiana nie spowoduje przeliczenia innych wpisów w historii salda!</AlertText>
        </AlertWrapper>
        <Input
          type='number'
          name='balanceAfter'
          label='Bilans po'
          value={values.balanceAfter}
          onChange={handleValueChange}
        />
        <ButtonsWrapper>
          <Button onClick={handleClose} color='secondary'>Anuluj</Button>
          <Button color='primary' onClick={handleSubmit}>Zapisz</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default EditStudentDebitModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(ReportProblemOutlinedIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

const AlertWrapper = styled.div`
  background-color: rgba(179, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
`

const AlertTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`

const AlertText = styled.div`
  text-align: center;
`
