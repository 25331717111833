import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import EditStudentModal from 'modules/students/components/EditStudentModal'
import Button from 'components/Button'
import { redirect } from 'utils/navigation'

const StudentBasicDetails = () => {
  const student = useSelector(selectCurrentStudent)

  return (
    <Header>
      <StudentName>
        {student.name}
        <EditStudentModal student={student} isSingleStudentView />
      </StudentName>
      <ButtonWrapper>
        <Button color='primary-outline' onClick={() => redirect(`/debtors?id=${student.id}`)}>
          Znajdź w dłużnikach
        </Button>
      </ButtonWrapper>

    </Header>
    // <PanelWrapper>
    //   <Wrapper>
    //     <ImageWrapper>
    //       <UserIcon />
    //     </ImageWrapper>
    //     <UserDetailsWrapper>
    //
    //       <DetailsWrapper>
    //         <div>
    //           <SectionName>Dane ucznia</SectionName>
    //           <UserDetail>
    //             <div>ID:</div>
    //             <div>{student.id}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Email:</div>
    //             <div>{propOr('---', 'email', student)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Numer telefonu:</div>
    //             <div>{propOr('---', 'phoneNumber', student)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Data utworzenia:</div>
    //             <div>{formatDate(student.createdAt, DATE_FORMATS.dashed)}</div>
    //           </UserDetail>
    //           <UserDetail>
    //             <div>Aktualne saldo:</div>
    //             <div>{propOr('---', 'balance', student)}</div>
    //           </UserDetail>
    //         </div>
    //         <div />
    //       </DetailsWrapper>
    //     </UserDetailsWrapper>
    //   </Wrapper>
    // </PanelWrapper>
  )
}

export default StudentBasicDetails

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 10px;
  flex: 1;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StudentName = styled.div`
  display: flex;
  gap: 15px;
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
`

const ButtonWrapper = styled.div`
  max-width: 250px;
`

const SectionName = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`

const UserDetailsWrapper = styled.div`
  width: 100%;
`

const DetailsWrapper = styled.div`
  display: flex;
  gap: 30px;
  
  & > div {
    flex: 1;
  }
`

const UserIcon = styled(AccountCircleIcon)`
  font-size: 150px !important;
  color: ${({ theme }) => theme.colors.secondary.main};
`

const UserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const PanelWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;
`
