import React, { useMemo, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import styled, { css } from 'styled-components'
import { DATE_FORMATS, dateWithTime, formatDate } from 'utils/date'
import { v4 as uuid } from 'uuid'
import { dissoc, propOr, values as ramdaValues } from 'ramda'
import { addStudentPayment } from 'services/StudentsService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'

const defaultValues = {
  date: formatDate(new Date(), DATE_FORMATS.input),
  value: 0,
  comment: ''
}

const AddPaymentForm = ({ callback, student, column }) => {
  const [values, setValues] = useState(defaultValues)
  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const createdAt = dateWithTime(new Date())
    const debitId = uuid()

    const debitPayload = {
      type: 'payment',
      studentId: student.id,
      comment: values.comment,
      createdAt,
      id: debitId,
      value: parseNumber(values.value),
      date: dateWithTime(values.date),
      balanceAfter: parseNumber(propOr(0, 'balance', student)) + parseNumber(values.value)
    }

    const studentPayload = {
      ...student,
      balance: parseNumber(propOr(0, 'balance', student)) + parseNumber(values.value)
    }

    addStudentPayment({
      values: {
        debitPayload,
        studentPayload
      },
      callback: () => {
        callback()
        setValues(defaultValues)
      }
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.value) > 0
  }, [values])

  return (
    <AddPaymentWrapper isColumn={column}>
      <Input
        name='value'
        type='number'
        label='Kwota wpłaty'
        value={values.value}
        onChange={handleValueChange}
      />
      <Input
        type='date'
        label='Data'
        name='date'
        onChange={handleValueChange}
        value={values.date}
      />
      <Input
        name='comment'
        label='Komentarz'
        value={values.comment}
        onChange={handleValueChange}
      />
      <Button disabled={!isValid} onClick={handleSubmit}>
        Dodaj
      </Button>
    </AddPaymentWrapper>
  )
}

export default AddPaymentForm

const AddPaymentWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isColumn }) => isColumn ? 'column' : 'row'};
  gap: 15px;
  width: 50%;
  
  ${({ isColumn }) => isColumn && css`
    flex-direction: column;
    width: 100%;
  `}
`
