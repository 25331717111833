import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LessonsTable from 'modules/lessons/components/LessonsTable'
import moment from 'moment'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Input from 'components/Input'
import styled from 'styled-components'
import { changeQuery } from 'utils/navigation'
import { selectCurrentStudent, selectCurrentStudentLessons } from 'modules/students/ducks/selectors'
import { fetchSingleStudentLessonsRoutine } from 'modules/students/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { uniq } from 'ramda'
import StudentDetailsAddLessonModal from 'modules/studentDetails/components/StudentDetailsAddLessonModal'
import { parseNumber } from 'utils/numbers'

const defaultDates = {
  dateFrom: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input),
}

const StudentDetailsLessons = () => {
  const student = useSelector(selectCurrentStudent)
  const studentLessons = useSelector(selectCurrentStudentLessons)
  const [dates, setDates] = useState(defaultDates)
  const dispatch = useDispatch()

  useEffect(() => {
    dates.dateFrom && student && dispatch(fetchSingleStudentLessonsRoutine({
      id: student.id,
      ...dates
    }))
  }, [dates, student])

  const handleValueChange = (name, value) => {
    setDates(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    changeQuery(dates)
  }, [dates])

  const summaryContent = useMemo(() => {
    if (isNotNilOrEmpty(studentLessons)) {
      const totalDuration = studentLessons.reduce((acc, { duration }) => acc + parseNumber(duration), 0)
      const list = studentLessons.map(lesson => `${formatDate(lesson.date, DATE_FORMATS.dashed)} (${lesson.duration}h)`)
      const rates = uniq(studentLessons.map(lesson => lesson.studentRate))
      return (
        <>
          <div>
            <span>Stawka:</span> {rates.join(' / ')}
          </div>
          <div>
            <span>Liczba godzin:</span> {totalDuration}
          </div>
          <div>
            <span>Daty lekcji:</span> {list.join(', ')}
          </div>
        </>
      )
    }
  }, [studentLessons])

  return (
      <>
        <Header>
          <DateFilters>
            <Input
              type='date'
              label='Data od'
              name='dateFrom'
              onChange={handleValueChange}
              value={dates.dateFrom}
            />
            <Input
              type='date'
              label='Data do'
              name='dateTo'
              onChange={handleValueChange}
              value={dates.dateTo}
            />
          </DateFilters>
        <StudentDetailsAddLessonModal />
        </Header>
        <LessonsTable isStudent />
        {
          isNotNilOrEmpty(summaryContent) && (
            <SummaryWrapper>
              <SummaryTitle>Podsumowanie lekcji</SummaryTitle>
              {summaryContent}
            </SummaryWrapper>
          )
        }
      </>
  )
}

export default StudentDetailsLessons

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`

const DateFilters = styled.div`
  display: flex;
  gap: 15px;
  flex: 1;
`

const SummaryWrapper = styled.div`
  margin-top: 50px;
  font-size: 14px;
  
  span {
    font-weight: bold;
  }
`

const SummaryTitle = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`
