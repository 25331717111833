import React from 'react'
import styled from 'styled-components'
import EditTeacherModal from 'modules/teachers/components/EditTeacherModal'
import DeleteTeacherModal from 'modules/teachers/components/DeleteTeacherModal'
import { propOr } from 'ramda'
import { redirect } from 'utils/navigation'

const TeachersTableRow = ({ teacher, order }) => {
  return (
    <Wrapper onClick={() => redirect(`/teachers/${teacher.id}`)}>
      <td>{order}.</td>
      <td>{propOr('---', 'name', teacher)}</td>
      <td>{propOr('---', 'email', teacher)}</td>
      <td>{propOr('---', 'phoneNumber', teacher)}</td>
      <td>
        <Actions onClick={e => e.stopPropagation()}>
          <EditTeacherModal teacher={teacher} />
          <DeleteTeacherModal teacher={teacher} />
        </Actions>
      </td>
    </Wrapper>
  )
}

export default TeachersTableRow

const Wrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
