import { init, send } from 'emailjs-com'
import { toast } from 'react-hot-toast'

const userId = process.env.REACT_APP_EMAIL_JS_USER_ID
const serviceId = process.env.REACT_APP_EMAIL_JS_SERVICE_ID
// const accessToken = '2ad5cb19f8047197ce4a7d52844b2aad'
const templateId = 'template_e99m8vq'
init(userId)

export const sendEmail = ({ students, message, title, onSuccess, onError }) => {
  const templateParams = {
    title,
    message,
    from_name: 'Smart School',
    to_email: students.join(','),
    reply_to: 'smartschoolpl@gmail.com',
  }

  send(serviceId, templateId, templateParams)
    .then(response => {
      toast.success('Wiadomość została wysłana')
      typeof onSuccess === 'function' && onSuccess()
    }, () => {
      toast.error('Nie udało się wysłać wiadomości')
      typeof onError === 'function' && onError()
    })
}
