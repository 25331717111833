import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch } from 'react-redux'
import EditWorkerForm from 'modules/workers/components/EditWorkerForm'
import { clearSingleWorkerRoutine, fetchSingleWorkerRoutine } from 'modules/workers/ducks/actions'

const EditWorkerModal = ({ worker, isSingleWorkerView }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  useEffect(() => {
    if (open) {
      dispatch(fetchSingleWorkerRoutine({ id: worker.id }))
    } else {
      !isSingleWorkerView && dispatch(clearSingleWorkerRoutine())
    }
  }, [open])

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  return (
    <>
      <Trigger onClick={handleOpen}>
        <StyledIcon />
      </Trigger>
      <Modal
        title='Edytuj'
        open={open}
        onClose={handleClose}
      >
        <EditWorkerForm handleCloseModal={handleClose} fetchSingle={isSingleWorkerView} />
      </Modal>
    </>
  )
}

export default EditWorkerModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
