import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Select from 'components/Select'
import { dissoc, propOr, prop, sortBy, values as ramdaValues } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Input from 'components/Input'
import { DATE_FORMATS, dateWithTime, formatDate } from 'utils/date'
import Button from 'components/Button'
import { v4 as uuid } from 'uuid'
import { batchAddLesson } from 'services/LessonsService'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentParsedQuery } from 'utils/navigation'
import { countLessonBalance, isCashLesson } from 'utils/lessons'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import { fetchSingleStudentLessonsRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import { selectTeachersList } from 'modules/teachers/ducks/selectors'
import { toast } from 'react-hot-toast'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  studentId: '',
  subjectEntryId: '',
  date: formatDate(new Date(), DATE_FORMATS.input),
  comment: '',
  duration: 1
}

const StudentDetailsAddLessonModal = ({ dates }) => {
  const student = useSelector(selectCurrentStudent)
  const allTeachers = useSelector(selectTeachersList)
  const teachers = propOr([], 'teachers', student)
  const [open, setOpen] = useState(false)
  const [selectedTeacherId, setSelectedTeacherId] = useState('')
  const [subject, setSubject] = useState({})
  const [teacher, setTeacher] = useState({})
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleValueChange('studentId', student.id)
  }, [student])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (open && teachers.length === 1) {
      handleSelectInputChange(null, teachers[0].entryId)
    }
  }, [open])

  const teachersOptions = useMemo(() => {
    return sortBy(prop('name'), teachers).map(teacher => {
      return {
        label: `${teacher.name} (${teacher.subject})`,
        value: teacher.entryId,
      }
    })
  }, [teachers])

  const handleSelectInputChange = (_, value) => {
    const subjectTeacher = teachers.find(t => t.entryId === value)
    const realTeacher = allTeachers.find(t => t.id === subjectTeacher.id)
    setSelectedTeacherId(value)
    if (isNotNilOrEmpty(realTeacher)) {
      setTeacher(realTeacher)
    } else {
      setTeacher({})
      toast.error('Nie znaleziono nauczyciela. Być może został usunięty przez administratora')
    }
    setSubject(subjectTeacher)
    setValues(prev => ({
      ...prev,
      duration: subjectTeacher.lessonDuration,
      studentRate: subjectTeacher.studentRate,
      teacherRate: subjectTeacher.teacherRate,
      subjectEntryId: subjectTeacher.entryId
    }))
  }

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
    setLoading(false)
  }

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
    setTeacher({})
    setSubject({})
  }

  const successCallback = () => {
    const query = isNotNilOrEmpty(dates) ? dates : getCurrentParsedQuery()
    dispatch(fetchSingleStudentLessonsRoutine({ id: student.id, ...query }))
    dispatch(fetchSingleStudentRoutine({ id: student.id }))
    setLoading(false)
    handleClose()
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const lessonCost = parseNumber(values.duration) * parseNumber(values.studentRate)
    const teacherIncome = countLessonBalance({
      lessonType: subject.lessonType,
      duration: parseNumber(values.duration),
      studentRate: parseNumber(values.studentRate),
      teacherRate: parseNumber(values.teacherRate)
    })
    const createdAt = dateWithTime(new Date())
    const lessonId = uuid()
    const debitId = uuid()
    const teacherDebitId = uuid()
    const isCash = isCashLesson(subject.lessonType)

    const lessonPayload = {
      ...values,
      date: dateWithTime(values.date),
      id: lessonId,
      subject: subject.subject,
      studentRate: parseNumber(values.studentRate),
      teacherRate: parseNumber(values.teacherRate),
      lessonType: subject.lessonType,
      createdAt,
      debitId,
      teacherDebitId,
      teacher: {
        rate: parseNumber(values.teacherRate),
        name: teacher.name,
        id: teacher.id
      },
      student: {
        rate: parseNumber(values.studentRate),
        name: student.name,
        id: student.id
      }
    }

    const debitPayload = {
      type: 'lesson',
      studentId: student.id,
      comment: values.comment,
      createdAt,
      subject: subject.subject,
      studentRate: parseNumber(values.studentRate),
      duration: parseNumber(values.duration),
      id: debitId,
      teacherDebitId,
      parentId: lessonId,
      value: isCash ? 0 : parseNumber(lessonCost) * -1,
      date: dateWithTime(values.date),
      balanceAfter: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student)) - lessonCost
    }

    const teacherDebitPayload = {
      type: 'lesson',
      teacherId: teacher.id,
      comment: values.comment,
      createdAt,
      id: teacherDebitId,
      debitId,
      parentId: lessonId,
      value: teacherIncome,
      subject: subject.subject,
      teacherRate: parseNumber(values.teacherRate),
      duration: parseNumber(values.duration),
      date: dateWithTime(values.date),
      student: {
        name: student.name,
        id: student.id
      },
      balanceAfter: parseNumber(propOr(0, 'balance', teacher)) + teacherIncome
    }

    const studentPayload = {
      ...student,
      balance: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student)) - lessonCost
    }

    const teacherPayload = {
      ...teacher,
      balance: parseNumber(propOr(0, 'balance', teacher)) + teacherIncome
    }

    batchAddLesson({
      values: {
        lessonPayload,
        debitPayload,
        studentPayload,
        teacherDebitPayload,
        teacherPayload
      },
      callback: successCallback
    })
  }

  const isValid = useMemo(() => {
    const toCheck = ramdaValues(dissoc('comment', values))
    return toCheck.every(val => isNotNilOrEmpty(val)) && parseNumber(values.duration) > 0
  }, [values])

  return (
    <>
      <Trigger onClick={handleOpen}>
        <div>+ Dodaj lekcję</div>
      </Trigger>
      <Modal
        title='Dodaj lekcję'
        open={open}
        onClose={handleClose}
      >
        <StudentName>{student.name}</StudentName>
        <form onSubmit={handleSubmit}>
          <Select
            name='teacher'
            options={teachersOptions}
            label='Nauczyciel'
            onChange={handleSelectInputChange}
            value={selectedTeacherId}
          />
          <Input
            type='date'
            label='Data'
            name='date'
            onChange={handleValueChange}
            value={values.date}
          />
          <Input
            type='number'
            label='Czas trwania'
            name='duration'
            onChange={handleValueChange}
            value={values.duration}
          />
          <Input
            type='number'
            label='Stawka ucznia'
            name='studentRate'
            onChange={handleValueChange}
            value={values.studentRate}
          />
          <Input
            type='number'
            label='Stawka nauczyciela'
            name='teacherRate'
            onChange={handleValueChange}
            value={values.teacherRate}
          />
          <Input
            label='Komentarz'
            name='comment'
            onChange={handleValueChange}
            value={values.comment}
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} color='secondary'>Anuluj</Button>
            <Button
              type='submit'
              color='primary'
              disabled={!isValid || loading || isNilOrEmpty(teacher)}
              isLoading={loading}
            >
              Dodaj
            </Button>
          </ButtonsWrapper>
        </form>
      </Modal>
    </>
  )
}

export default StudentDetailsAddLessonModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 15px;

  button {
    max-width: 200px;
  }
`

const StudentName = styled.div`
  margin-bottom: 10px;
  text-align: center;
`
