import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, propOr } from 'ramda'
import styled from 'styled-components'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import { fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'
import StudentTeachersList from 'modules/students/components/StudentTeachersList'

const TeacherDetailsStudents = () => {
  const student = useSelector(selectCurrentStudent)
  const teachers = propOr([], 'teachers', student)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSchoolSubjectsRoutine())
  }, [])

  return (
    <>
      {
        isEmpty(teachers)
          ? <EmptyState>Brak przypisanych nauczycieli</EmptyState>
          : <StudentTeachersList teachers={teachers} hideActions />
      }
    </>

  )
}

export default TeacherDetailsStudents

const EmptyState = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
