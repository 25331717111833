import React, { useMemo } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { isCashLesson, LESSON_TYPES } from 'utils/lessons'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Tooltip } from '@mui/material'
import { copyToClipboard } from 'utils/date'
import MarkStudentToBeDeleted from 'modules/myStudents/components/MarkStudentToBeDeleted'

const MyStudentsTableRow = ({ student, order, getStudents, hasCashStudents }) => {
  const user = useSelector(selectCurrentUser)

  const lessonTypes = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
      .filter(t => t.id === user.id)
    return <>{studentTeachers.map(t => <div>{LESSON_TYPES[t.lessonType].split(' - ')[0]}</div>)}</>
  }, [student])

  const studentRates = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
      .filter(t => t.id === user.id)
    return <>{studentTeachers.map(t => <div>{isCashLesson(t.lessonType) ? t.studentRate : '---'}</div>)}</>
  }, [student])

  const teacherRates = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
      .filter(t => t.id === user.id)
    return <>{studentTeachers.map(t => <div>{t.teacherRate}</div>)}</>
  }, [student])

  const lessonDurations = useMemo(() => {
    const studentTeachers = propOr([], 'teachers', student)
      .filter(t => t.id === user.id)
    return <>{studentTeachers.map(t => <div>{t.lessonDuration}</div>)}</>
  }, [student])

  return (
    <Wrapper>
      <td>{order}</td>
      <td>
        <StudentName>
          {student.name}
          <Tooltip title='Skopiuj nazwę ucznia'>
            <CopyIcon onClick={e => {
              e.stopPropagation()
              copyToClipboard(student.name.trim())()
            }} />
          </Tooltip>
        </StudentName>
        </td>
      <td>{lessonTypes}</td>
      <td>{lessonDurations}</td>
      {hasCashStudents && <td>{studentRates}</td>}
      <td>{teacherRates}</td>
      <td>
        <MarkStudentToBeDeleted student={student} getStudents={getStudents} />
      </td>
    </Wrapper>
  )
}

export default MyStudentsTableRow

const Wrapper = styled.tr`
  transition: all .3s;
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`

const CopyIcon = styled(ContentCopyIcon)`
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 5px;
`

const StudentName = styled.div`
  display: flex;
  align-items: center;
`
