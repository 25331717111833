import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/Modal'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch } from 'react-redux'
import { clearSingleStudentRoutine, fetchSingleStudentRoutine } from 'modules/students/ducks/actions'
import EditStudentForm from 'modules/students/components/EditStudentForm'

const EditStudentModal = ({ student, isSingleStudentView, isTeacherView, actionCallback }) => {
  const [open, setOpen] = useState(false)
  const [wasOpen, setWasOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  useEffect(() => {
    if (open) {
      !wasOpen && setWasOpen(true)
      dispatch(fetchSingleStudentRoutine({ id: student.id }))
    } else {
      !isSingleStudentView && wasOpen && dispatch(clearSingleStudentRoutine())
    }
  }, [open])

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  return (
      <>
        <Trigger onClick={handleOpen}>
          <StyledIcon />
        </Trigger>
        <Modal
          title='Edytuj'
          open={open}
          onClose={handleClose}
        >
          <EditStudentForm
            handleCloseModal={handleClose}
            fetchSingle={isSingleStudentView}
            isTeacherView={isTeacherView}
            actionCallback={actionCallback}
          />
        </Modal>
      </>
  )
}

export default EditStudentModal

const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const StyledIcon = styled(EditIcon)`
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
