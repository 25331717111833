import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/Input'
import Button from 'components/Button'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import StudentTeachersList from 'modules/students/components/StudentTeachersList'
import AddStudentTeacherModal from 'modules/students/components/AddStudentTeacherModal'
import { updateStudent } from 'services/StudentsService'
import { fetchSingleStudentRoutine, getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import { selectCurrentStudent } from 'modules/students/ducks/selectors'
import { pick, propOr, update } from 'ramda'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import Checkbox from 'components/Checkbox'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  name: '',
  teachers: [],
  email: '',
  phoneNumber: '',
  balance: 0
}

const EditStudentForm = ({ handleCloseModal, fetchSingle, isTeacherView, actionCallback }) => {
  const currentTeacher = useSelector(selectCurrentTeacher)
  const student = useSelector(selectCurrentStudent)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const callback = () => {
    handleCloseModal()
    setValues(emptyValues)
    if (isTeacherView && currentTeacher) {
      typeof actionCallback === 'function' && actionCallback()
    } else {
      fetchSingle
        ? dispatch(fetchSingleStudentRoutine({ id: student.id }))
        : dispatch(getActiveStudentsRoutine())
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    updateStudent({
      student: {
        ...student,
        ...values,
        balance: parseNumber(values.balance)
      },
      callback
    })
  }

  const handleAddTeacher = teacherValues => {
    handleValueChange('teachers', [
      ...values.teachers,
      teacherValues
    ])
  }

  const handleEditTeacher = (teacherValues, index) => {
    setValues(prev => ({
      ...prev,
      teachers: update(index, teacherValues, prev.teachers)
    }))
  }

  const handleDeleteTeacher = index => {
    setValues(prev => ({
      ...prev,
      teachers: values.teachers.filter((_, i) => i !== index)
    }))
  }

  useEffect(() => {
    student && setValues({
      ...pick([
        'name',
        'teachers',
        'createdAt',
        'email',
        'balance',
        'phoneNumber'
      ], student),
      toBeDeleted: propOr(false, 'toBeDeleted', student)
    })
  }, [student])

  return (
    <>

      <SectionHeader>Dane podstawowe</SectionHeader>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Input
            label='Nazwa ucznia'
            name='name'
            onChange={handleValueChange}
            value={values.name}
          />
          <Input
            label='Numer telefonu'
            name='phoneNumber'
            onChange={handleValueChange}
            value={values.phoneNumber}
          />
          <Input
            label='Email'
            name='email'
            onChange={(name, value) => handleValueChange('email', value.replace(/\s/g, ''))}
            value={values.email}
          />
          <Input
            label='Aktualne saldo'
            name='balance'
            onChange={handleValueChange}
            value={values.balance}
          />
        </Row>
        <Row>
          <Checkbox
            name='toBeDeleted'
            label='Oznaczony do usunięcia'
            value={values.toBeDeleted}
            onChange={handleValueChange}
          />
        </Row>
        <ListHeaderWrapper>
          <SectionHeader noMargin>Przypisani nauczyciele</SectionHeader>
          <AddStudentTeacherModal onAddTeacher={handleAddTeacher} />
        </ListHeaderWrapper>
        {isNotNilOrEmpty(values.teachers) && (
          <StudentTeachersList
            onEditTeacher={handleEditTeacher}
            onDeleteTeacher={handleDeleteTeacher}
            teachers={values.teachers}
          />
        )}
        <ButtonsWrapper>
          <Button onClick={handleCloseModal} color='secondary'>Anuluj</Button>
          <Button
            type='submit'
            color='primary'
            disabled={isNilOrEmpty(values.name)}
          >
            Zapisz
          </Button>
        </ButtonsWrapper>
      </Form>

    </>
  )
}

export default EditStudentForm

const Form = styled.form`
  margin-top: 10px;
  width: 100%;
  
  input {
    width: 100%;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;

  button {
    max-width: 200px;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  
  ${({ inModal }) => inModal && css`
    flex-direction: column;
    & > div {
      width: 100%;
    }
  `}
`

const SectionHeader = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '30px'};
  color: ${({ theme }) => theme.colors.secondary.main};
`

const ListHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 10px;
`
