import { db } from 'firestoreConfig'
import firebase from 'firebase'
import { omit, pathOr, reverse } from 'ramda'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { isNotNil } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'
import { v4 as uuid } from 'uuid'

export const addLog = async text => {
  const logId = uuid()
  await db
    .collection('logs')
    .doc(logId)
    .set({
      id: logId,
      value: text
    })
}

export const updatePreviousDebitItems = async ({ debit, callback, lessonDifference }) => {
  await db
    .collection('debit')
    .where('studentId', '==', debit.studentId)
    .where('date', '>', debit.date)
    .get()
    .then(querySnapshot => {
      const docs = querySnapshot.docs
      const incrementAmount = isNotNil(lessonDifference)
        ? parseNumber(lessonDifference)
        : parseNumber(debit.value)

      const updates = docs.map(doc => {
        const newAmount = doc.data().balanceAfter + incrementAmount
        return {
          ref: doc.ref,
          data: { balanceAfter: newAmount }
        }
      })

      return firebase.firestore().runTransaction(transaction => {
        updates.forEach(update => {
          transaction.update(update.ref, update.data)
        })
        return Promise.resolve()
      })
    })
    .then(() => {
      typeof callback === 'function' && callback()
    })
    .catch(err => {
      console.error(err)
    })
}

export const updatePreviousTeacherDebitItems = async ({ debit, callback, lessonDifference }) => {
  await db
    .collection('teacherDebit')
    .where('teacherId', '==', debit.teacherId)
    .where('date', '>', debit.date)
    .get()
    .then(querySnapshot => {
      const docs = querySnapshot.docs
      const incrementAmount = isNotNil(lessonDifference)
        ? parseNumber(lessonDifference)
        : parseNumber(debit.value)

      const updates = docs.map(doc => {
        const newAmount = doc.data().balanceAfter + incrementAmount
        return {
          ref: doc.ref,
          data: { balanceAfter: newAmount }
        }
      })

      return firebase.firestore().runTransaction(transaction => {
        updates.forEach(update => {
          transaction.update(update.ref, update.data)
        })
        return Promise.resolve()
      })
    })
    .then(() => {
      typeof callback === 'function' && callback()
    })
    .catch(err => {
      console.error(err)
    })
}

export const getSingleDebitItem = (id, setValuesFn) => {
  return db
    .collection('debit')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        typeof setValuesFn === 'function' && setValuesFn(data)
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const getSingleTeacherDebitItem = (id, setValuesFn) => {
  return db
    .collection('teacherDebit')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        typeof setValuesFn === 'function' && setValuesFn(data)
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

// export const addLesson = async ({ values, callback, silent }) => {
//   const { debitPayload, studentPayload, lessonPayload, teacherDebitPayload, teacherPayload } = values
//
//   try {
//     const debitRef = db.collection('debit').doc(debitPayload.id)
//     const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
//     const lessonRef = db.collection('lessons').doc(lessonPayload.id)
//     const prevSnapshot = await db
//       .collection('debit')
//       .where('studentId', '==', debitPayload.studentId)
//       .where('date', '<', debitPayload.date)
//       .where('date', '>', moment(debitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     const prevTeacherDebitSnapshot = await db
//       .collection('teacherDebit')
//       .where('teacherId', '==', teacherDebitPayload.teacherId)
//       .where('date', '<', teacherDebitPayload.date)
//       .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     await updateStudent({ student: studentPayload })
//       .then(async () => {
//         await updateTeacher({ teacher: teacherPayload })
//           .then(async () => {
//             let prevItems = []
//             if (!prevSnapshot.empty) {
//               prevSnapshot.forEach(doc => {
//                 const data = doc.data()
//                 prevItems.push(data)
//               })
//             }
//             const sorted = reverse(prevItems.sort((a, b) => a.date - b.date))
//             const lastBalance = pathOr(0, [0, 'balanceAfter'], sorted)
//
//             const fullDebitPayload = {
//               ...debitPayload,
//               balanceAfter: lastBalance + debitPayload.value
//             }
//             await debitRef.set(fullDebitPayload)
//               .then(async () => {
//                 await updatePreviousDebitItems({ debit: fullDebitPayload })
//                 let prevItems = []
//                 if (!prevTeacherDebitSnapshot.empty) {
//                   prevTeacherDebitSnapshot.forEach(doc => {
//                     const data = doc.data()
//                     prevItems.push(data)
//                   })
//                 }
//                 const sorted = reverse(prevItems.sort((a, b) => a.date - b.date))
//                 const lastBalance = pathOr(0, [0, 'balanceAfter'], sorted)
//
//                 const teacherDebitFullPayload = {
//                   ...teacherDebitPayload,
//                   balanceAfter: lastBalance + teacherDebitPayload.value
//                 }
//
//                 await teacherDebitRef.set(teacherDebitFullPayload)
//                   .then(async () => {
//                     await updatePreviousTeacherDebitItems({ debit: teacherDebitFullPayload })
//                     await lessonRef.set(lessonPayload)
//                       .then(() => {
//                         !silent && toast.success('Dodano nową lekcję')
//                         typeof callback === 'function' && callback()
//                       })
//                       .catch(() => {
//                         toast.error('Wystąpił błąd podczas dodawania lekcji, zgłoś go administratorowi')
//                       })
//                   })
//                   .catch(() => {
//                     toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda nauczyciela, zgłoś go administratorowi')
//                   })
//               })
//               .catch(() => {
//                 toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda ucznia, zgłoś go administratorowi')
//               })
//           })
//           .catch(() => {
//             toast.error('Wystąpił błąd podczas zmiany salda nauczyciela, zgłoś go administratorowi')
//           })
//       })
//       .catch(() => {
//         toast.error('Wystąpił błąd podczas zmiany salda ucznia, zgłoś go administratorowi')
//       })
//   } catch (err) {
//     console.error(err)
//   }
// }

export const batchAddLesson =  async ({ values, callback, silent }) => {
  const { debitPayload, studentPayload, lessonPayload, teacherDebitPayload, teacherPayload } = values
  const batch = db.batch()

  const debitRef = db.collection('debit').doc(debitPayload.id)
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const lessonRef = db.collection('lessons').doc(lessonPayload.id)
  const studentRef = db.collection('students').doc(studentPayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)
  const student = omit([
    'students',
    'balances',
    'lessons',
    'hasLessonsInPrevMonth',
    'message',
    'title'
  ], studentPayload)

  const prevSnapshot = await db
    .collection('debit')
    .where('studentId', '==', debitPayload.studentId)
    .where('date', '<', debitPayload.date)
    .where('date', '>', moment(debitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
    .get()

  const prevTeacherDebitSnapshot = await db
    .collection('teacherDebit')
    .where('teacherId', '==', teacherDebitPayload.teacherId)
    .where('date', '<', teacherDebitPayload.date)
    .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
    .get()

  const prepareStudentDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    if (!prevSnapshot.empty) {
      prevSnapshot.forEach(doc => {
        debitsData = [...debitsData, doc.data()]
        if (index === prevSnapshot.docs.length - 1) {
          resolve(debitsData)
        }
        index++
      })
    } else {
      return resolve([])
    }
  })

  const prepareTeacherDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    if (!prevTeacherDebitSnapshot.empty) {
      prevTeacherDebitSnapshot.forEach(doc => {
        debitsData = [...debitsData, doc.data()]
        if (index === prevTeacherDebitSnapshot.docs.length - 1) {
          resolve(debitsData)
        }
        index++
      })
    } else {
      return resolve([])
    }
  })

  prepareStudentDebitItems
    .then(studentDebitItems => {
      prepareTeacherDebitItems
        .then(async teacherDebitItems => {
          const sortedStudentDebitItems = reverse(studentDebitItems.sort((a, b) => a.date - b.date))
          const lastStudentDebitBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedStudentDebitItems))
          const sortedTeacherDebitItems = reverse(teacherDebitItems.sort((a, b) => a.date - b.date))
          const lastTeacherDebitBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedTeacherDebitItems))
          const fullStudentDebitPayload = {
            ...debitPayload,
            balanceAfter: parseNumber(lastStudentDebitBalance) + parseNumber(debitPayload.value)
          }
          const fullTeacherDebitPayload = {
            ...teacherDebitPayload,
            balanceAfter: parseNumber(lastTeacherDebitBalance) + parseNumber(teacherDebitPayload.value)
          }

          batch.set(debitRef, fullStudentDebitPayload)
          batch.set(teacherDebitRef, fullTeacherDebitPayload)
          batch.set(lessonRef, lessonPayload)
          batch.update(studentRef, student)
          batch.update(teacherRef, teacherPayload)

          batch.commit()
            .then(() => {
              updatePreviousDebitItems({ debit: fullStudentDebitPayload })
              updatePreviousTeacherDebitItems({ debit: fullTeacherDebitPayload })

              !silent && toast.success('Dodano nową lekcję')
              typeof callback === 'function' && callback()
            })
            .catch((error) => {
              toast.error('Wystąpił błąd podczas dodawania nowej lekcji')
              console.error("Transakcja nie powiodła się: ", error)
            })
        })
    })
}

// export const addLesson = async ({ values, callback, silent }) => {
//   const { debitPayload, studentPayload, lessonPayload, teacherDebitPayload, teacherPayload } = values
//
//   try {
//     const debitRef = db.collection('debit').doc(debitPayload.id)
//     const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
//     const lessonRef = db.collection('lessons').doc(lessonPayload.id)
//     const prevSnapshot = await db
//       .collection('debit')
//       .where('studentId', '==', debitPayload.studentId)
//       .where('date', '<', debitPayload.date)
//       .where('date', '>', moment(debitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     const prevTeacherDebitSnapshot = await db
//       .collection('teacherDebit')
//       .where('teacherId', '==', teacherDebitPayload.teacherId)
//       .where('date', '<', teacherDebitPayload.date)
//       .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     const prepareStudentDebitItems = new Promise(resolve => {
//       let debitsData = []
//       let index = 0
//       if (!prevSnapshot.empty) {
//         prevSnapshot.forEach(doc => {
//           debitsData = [...debitsData, doc.data()]
//           if (index === prevSnapshot.docs.length - 1) {
//             resolve(debitsData)
//           }
//           index++
//         })
//       } else {
//         return resolve([])
//       }
//     })
//
//     const prepareTeacherDebitItems = new Promise(resolve => {
//       let debitsData = []
//       let index = 0
//       if (!prevTeacherDebitSnapshot.empty) {
//         prevTeacherDebitSnapshot.forEach(doc => {
//           debitsData = [...debitsData, doc.data()]
//           if (index === prevTeacherDebitSnapshot.docs.length - 1) {
//             resolve(debitsData)
//           }
//           index++
//         })
//       } else {
//         return resolve([])
//       }
//     })
//
//     prepareStudentDebitItems
//       .then(studentDebitItems => {
//         prepareTeacherDebitItems
//           .then(async teacherDebitItems => {
//             await updateStudent({ student: studentPayload })
//               .then(async () => {
//                 await updateTeacher({ teacher: teacherPayload })
//                   .then(async () => {
//                     const sortedStudentDebitItems = reverse(studentDebitItems.sort((a, b) => a.date - b.date))
//                     const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedStudentDebitItems))
//
//                     const fullDebitPayload = {
//                       ...debitPayload,
//                       balanceAfter: parseNumber(lastBalance) + parseNumber(debitPayload.value)
//                     }
//                     await debitRef.set(fullDebitPayload)
//                       .then(async () => {
//                         await updatePreviousDebitItems({ debit: fullDebitPayload })
//                         const sortedTeacherDebitItems = reverse(teacherDebitItems.sort((a, b) => a.date - b.date))
//                         const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedTeacherDebitItems))
//
//                         const teacherDebitFullPayload = {
//                           ...teacherDebitPayload,
//                           balanceAfter: parseNumber(lastBalance) + parseNumber(teacherDebitPayload.value)
//                         }
//
//                         await teacherDebitRef.set(teacherDebitFullPayload)
//                           .then(async () => {
//                             await updatePreviousTeacherDebitItems({ debit: teacherDebitFullPayload })
//                             await lessonRef.set(lessonPayload)
//                               .then(() => {
//                                 !silent && toast.success('Dodano nową lekcję')
//                                 typeof callback === 'function' && callback()
//                               })
//                               .catch(() => {
//                                 toast.error('Wystąpił błąd podczas dodawania lekcji, zgłoś go administratorowi')
//                               })
//                           })
//                           .catch(() => {
//                             toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda nauczyciela, zgłoś go administratorowi')
//                           })
//                       })
//                       .catch(() => {
//                         toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda ucznia, zgłoś go administratorowi')
//                       })
//                   })
//                   .catch(() => {
//                     toast.error('Wystąpił błąd podczas zmiany salda nauczyciela, zgłoś go administratorowi')
//                   })
//               })
//               .catch(() => {
//                 toast.error('Wystąpił błąd podczas zmiany salda ucznia, zgłoś go administratorowi')
//               })
//
//           })
//           .catch(() => {
//             toast.error('Wystapił błąd przy pobieraniu historii salda nauczyciela')
//           })
//       })
//       .catch(() => {
//         toast.error('Wystapił błąd przy pobieraniu historii salda ucznia')
//       })
//
//   } catch (err) {
//     console.error(err)
//   }
// }

// export const addImportedLesson = async ({ values, callback, silent }) => {
//   const { debitPayload, lessonPayload, teacherDebitPayload, teacherPayload, isCash, lessonCost } = values
//
//   try {
//     const studentRef = await db.collection('students').doc(lessonPayload.studentId)
//     const debitRef = db.collection('debit').doc(debitPayload.id)
//     const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
//     const lessonRef = db.collection('lessons').doc(lessonPayload.id)
//     const prevSnapshot = await db
//       .collection('debit')
//       .where('studentId', '==', debitPayload.studentId)
//       .where('date', '<', debitPayload.date)
//       .where('date', '>', moment(debitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     const prevTeacherDebitSnapshot = await db
//       .collection('teacherDebit')
//       .where('teacherId', '==', teacherDebitPayload.teacherId)
//       .where('date', '<', teacherDebitPayload.date)
//       .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
//       .get()
//
//     const prepareStudentDebitItems = new Promise(resolve => {
//       let debitsData = []
//       let index = 0
//       if (!prevSnapshot.empty) {
//         prevSnapshot.forEach(doc => {
//           debitsData = [...debitsData, doc.data()]
//           if (index === prevSnapshot.docs.length - 1) {
//             resolve(debitsData)
//           }
//           index++
//         })
//       } else {
//         return resolve([])
//       }
//     })
//
//     const prepareTeacherDebitItems = new Promise(resolve => {
//       let debitsData = []
//       let index = 0
//       if (!prevTeacherDebitSnapshot.empty) {
//         prevTeacherDebitSnapshot.forEach(doc => {
//           debitsData = [...debitsData, doc.data()]
//           if (index === prevTeacherDebitSnapshot.docs.length - 1) {
//             resolve(debitsData)
//           }
//           index++
//         })
//       } else {
//         return resolve([])
//       }
//     })
//
//     await studentRef.get()
//       .then(async doc => {
//         if (doc.exists) {
//           const student = await doc.data()
//           const studentPayload = {
//             ...student,
//             balance: isCash ? parseNumber(propOr(0, 'balance', student)) : parseNumber(propOr(0, 'balance', student)) - lessonCost
//           }
//           prepareStudentDebitItems
//             .then(studentDebitItems => {
//               prepareTeacherDebitItems
//                 .then(async teacherDebitItems => {
//                   await updateStudent({ student: studentPayload })
//                     .then(async () => {
//                       await updateTeacher({ teacher: teacherPayload })
//                         .then(async () => {
//                           const sortedStudentDebitItems = reverse(studentDebitItems.sort((a, b) => a.date - b.date))
//                           const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedStudentDebitItems))
//
//                           const fullDebitPayload = {
//                             ...debitPayload,
//                             balanceAfter: parseNumber(lastBalance) + parseNumber(debitPayload.value)
//                           }
//                           await debitRef.set(fullDebitPayload)
//                             .then(async () => {
//                               await updatePreviousDebitItems({ debit: fullDebitPayload })
//                               const sortedTeacherDebitItems = reverse(teacherDebitItems.sort((a, b) => a.date - b.date))
//                               const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedTeacherDebitItems))
//
//                               const teacherDebitFullPayload = {
//                                 ...teacherDebitPayload,
//                                 balanceAfter: parseNumber(lastBalance) + parseNumber(teacherDebitPayload.value)
//                               }
//
//                               await teacherDebitRef.set(teacherDebitFullPayload)
//                                 .then(async () => {
//                                   await updatePreviousTeacherDebitItems({ debit: teacherDebitFullPayload })
//                                   await lessonRef.set(lessonPayload)
//                                     .then(() => {
//                                       !silent && toast.success('Dodano nową lekcję')
//                                       typeof callback === 'function' && callback()
//                                     })
//                                     .catch(() => {
//                                       toast.error('Wystąpił błąd podczas dodawania lekcji, zgłoś go administratorowi')
//                                     })
//                                 })
//                                 .catch(() => {
//                                   toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda nauczyciela, zgłoś go administratorowi')
//                                 })
//                             })
//                             .catch(() => {
//                               toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda ucznia, zgłoś go administratorowi')
//                             })
//                         })
//                         .catch(() => {
//                           toast.error('Wystąpił błąd podczas zmiany salda nauczyciela, zgłoś go administratorowi')
//                         })
//                     })
//                     .catch(() => {
//                       toast.error('Wystąpił błąd podczas zmiany salda ucznia, zgłoś go administratorowi')
//                     })
//
//                 })
//                 .catch(() => {
//                   toast.error('Wystapił błąd przy pobieraniu historii salda nauczyciela')
//                 })
//             })
//             .catch(() => {
//               toast.error('Wystapił błąd przy pobieraniu historii salda ucznia')
//             })
//         }
//       })
//       .catch(() => {
//         toast.error('Nie udało się pobrać aktualnego salda ucznia')
//       })
//   } catch (err) {
//     console.error(err)
//   }
// }

export const batchUpdateLesson = async ({ values, callback }) => {
  const { debitPayload, teacherDebitPayload, studentPayload, teacherPayload, lessonPayload, lessonDifference, incomeDifference } = values
  const batch = db.batch()

  const debitRef = db.collection('debit').doc(debitPayload.id)
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const lessonRef = db.collection('lessons').doc(lessonPayload.id)
  const studentRef = db.collection('students').doc(studentPayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)
  const student = omit([
    'students',
    'balances',
    'lessons',
    'hasLessonsInPrevMonth',
    'message',
    'title'
  ], studentPayload)

  batch.update(debitRef, debitPayload)
  batch.update(teacherDebitRef, teacherDebitPayload)
  batch.update(lessonRef, lessonPayload)
  batch.update(studentRef, student)
  batch.update(teacherRef, teacherPayload)

  batch.commit()
    .then(() => {
      updatePreviousDebitItems({ debit: debitPayload, lessonDifference })
      updatePreviousTeacherDebitItems({ debit: teacherDebitPayload, lessonDifference: incomeDifference })

      toast.success('Zaktualizowano lekcję')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas edycji lekcji')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

// export const updateLesson = async ({ values, callback }) => {
//   const { debitPayload, teacherDebitPayload, studentPayload, teacherPayload, lessonPayload, lessonDifference, incomeDifference } = values
//
//   try {
//     const debitRef = db.collection('debit').doc(debitPayload.id)
//     const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
//     const lessonRef = db.collection('lessons').doc(lessonPayload.id)
//
//     await updateStudent({ student: studentPayload })
//       .then(async () => {
//         updateTeacher({ teacher: teacherPayload })
//           .then(async () => {
//             await debitRef.update(debitPayload)
//               .then(async () => {
//                 await updatePreviousDebitItems({ debit: debitPayload, lessonDifference })
//                 await teacherDebitRef.update(teacherDebitPayload)
//                   .then(async () => {
//                     await updatePreviousTeacherDebitItems({ debit: teacherDebitPayload, lessonDifference: incomeDifference })
//                     await lessonRef.update(lessonPayload)
//                       .then(() => {
//                         toast.success('Zaktualizowano lekcję')
//                         typeof callback === 'function' && callback()
//                       })
//                       .catch(() => {
//                         toast.error('Wystąpił błąd podczas edycji lekcji, zgłoś go administratorowi')
//                       })
//                   })
//                   .catch(() => {
//                     toast.error('Wystąpił błąd podczas dodawania wpisu do historii salda nauczyciela, zgłoś go administratorowi')
//                   })
//
//               })
//               .catch(() => {
//                 toast.error('Wystąpił błąd podczas edycji wpisu w historii salda ucznia, zgłoś go administratorowi')
//               })
//           })
//           .catch(() => {
//             toast.error('Wystąpił błąd podczas zmiany salda nauczyciela, zgłoś go administratorowi')
//           })
//       })
//       .catch(() => {
//         toast.error('Wystąpił błąd podczas zmiany salda ucznia, zgłoś go administratorowi')
//       })
//   } catch (err) {
//     console.error(err)
//   }
// }

export const justRemoveLesson = async ({ values, callback }) => {
  const { debitId, teacherDebitId, lessonId } = values

  try {
    if (debitId) {
      const debitRef = db.collection('debit').doc(debitId)
      debitRef.delete()
    }

    if (teacherDebitId) {
      const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitId)
      teacherDebitRef.delete()
    }

    if (lessonId) {
      const lessonRef = db.collection('lessons').doc(lessonId)
      lessonRef.delete()
    }

    callback && callback()
  } catch (err) {
    console.error(err)
  }
}


export const removeLesson = async ({ values, callback }) => {
  const { debit, teacherDebit, lessonId, student, teacher } = values

  const batch = db.batch()

  const debitRef = db.collection('debit').doc(debit.id)
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebit.id)
  const lessonRef = db.collection('lessons').doc(lessonId)
  const studentRef = db.collection('students').doc(student.id)
  const teacherRef = db.collection('teachers').doc(teacher.id)
  const studentPayload = omit([
    'students',
    'balances',
    'lessons',
    'hasLessonsInPrevMonth',
    'message',
    'title'
  ], student)

  batch.delete(debitRef)
  batch.delete(teacherDebitRef)
  batch.delete(lessonRef)
  batch.update(studentRef, studentPayload)
  batch.update(teacherRef, teacher)

  batch.commit()
    .then(() => {
      updatePreviousDebitItems({ debit: debit, lessonDifference: parseNumber(debit.value) * -1 })
      updatePreviousTeacherDebitItems({
        debit: teacherDebit,
        lessonDifference: parseNumber(teacherDebit.value) * -1
      })

      toast.success('Usunięto lekcję')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas usuwania lekcji')
      console.error("Transakcja nie powiodła się: ", error)
    })
}


export const getLessonsForAllStudents = async ({ dateFrom, dateTo, setFn }) => {
  const snapshot = await db
    .collection('lessons')
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    setFn([])
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    setFn(data)
  }
}
