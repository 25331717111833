import React from 'react'
import Table from 'components/Table'
import { isNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import { DATE_FORMATS, formatDate } from 'utils/date'
import EditTeacherExpenseModal from 'modules/teacherDetails/components/EditTeacherExpenseModal'
import DeleteTeacherExpenseModal from 'modules/teacherDetails/components/DeleteTeacherExpenseModal'

const ExpensesTable = ({ expenses }) => {
  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Data</th>
          <th>Opis</th>
          <th>Kwota</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {expenses && expenses.sort((a, b) => b.date.localeCompare(a.date)).map(expense => (
          <tr key={expense.id}>
            <td>{formatDate(expense.date, DATE_FORMATS.dashed)}</td>
            <td>{expense.comment}</td>
            <td>{expense.value}</td>
            <td>
              <Actions onClick={e => e.stopPropagation()}>
                {
                  expense.type === 'expense' && (
                    <>
                      <EditTeacherExpenseModal expense={expense} />
                      <DeleteTeacherExpenseModal expense={expense} />
                    </>
                  )
                }
              </Actions>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      {isNilOrEmpty(expenses) && (
        <EmptyState>
          Brak wydatków/przychodów do wyświetlenia
        </EmptyState>
      )}
    </>
  )
}

export default ExpensesTable

const EmptyState = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
