import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from 'components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentTeacher } from 'modules/teachers/ducks/selectors'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/Button'
import { updateTeacher } from 'services/TeachersService'
import { fetchSingleTeacherRoutine, fetchTeachersListRoutine } from 'modules/teachers/ducks/actions'
import { pathOr, propOr } from 'ramda'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TEACHER_PAYMENT_TYPES_OPTIONS } from 'utils/teacher'
import Select from 'components/Select'
import { parseNumber } from 'utils/numbers'

const emptyValues = {
  name: '',
  email: '',
  phoneNumber: '',
  rates: [],
  calendarId: '',
  balance: 0
}

const EditTeacherForm = ({ handleCloseModal, fetchSingle }) => {
  const [values, setValues] = useState(emptyValues)
  const teacher = useSelector(selectCurrentTeacher)
  const dispatch = useDispatch()

  const callback = () => {
    fetchSingle
      ? dispatch(fetchSingleTeacherRoutine({ id: teacher.id }))
      : dispatch(fetchTeachersListRoutine())
    handleCloseModal()
  }

  useEffect(() => {
    isNotNilOrEmpty(teacher) && setValues(teacher)
  }, [teacher])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleRateValueChange = index => (name, value) => {
    const current = {
      ...pathOr({}, ['rates', index], values),
      [name]: value
    }
    const ratesCopy = [ ...values.rates ]
    ratesCopy[index] = current
    handleValueChange('rates', ratesCopy)
  }

  const handleAddRate = () => {
    handleValueChange('rates', [
      ...propOr([], 'rates', values),
      { name: '', rate: '' }
    ])
  }

  const handleDeleteRate = index => () => {
    handleValueChange(
      'rates',
      values.rates.filter((_, idx) => idx !== index)
    )
  }

  const handleUpdate = () => {
    updateTeacher({
      teacher: {
        ...values,
        balance: parseNumber(values.balance),
        rates: propOr([], 'rates', values)
          .filter(rate => isNotNilOrEmpty(rate.name) && isNotNilOrEmpty(rate.rate))
      },
      callback
    })
  }

  return (
    <Wrapper>
      <Input
        name='email'
        label='Email'
        disabled
        value={values.email}
        onChange={handleValueChange}
      />
      <Input
        name='phoneNumber'
        label='Numer telefonu'
        value={values.phoneNumber}
        onChange={handleValueChange}
      />
      <Input
        name='name'
        label='Nazwa'
        value={values.name}
        onChange={handleValueChange}
      />
      <Select
        name='paymentType'
        options={TEACHER_PAYMENT_TYPES_OPTIONS}
        label='Typ rozliczania'
        onChange={handleValueChange}
        value={values.paymentType}
      />
      <Input
        name='calendarId'
        label='ID kalendarza Google'
        value={values.calendarId}
        onChange={handleValueChange}
      />
      <Input
        name='balance'
        label='Aktualne saldo'
        value={values.balance}
        onChange={handleValueChange}
      />
      <RatesWrapper>
        <RatesSectionTitle>Stawki nauczyciela</RatesSectionTitle>
        {propOr([], 'rates', values).map((rate, index) => {
          return (
            <RateForm key={`rate-${index}`}>
              <Input
                name='name'
                label='Nazwa stawki'
                value={pathOr('', ['rates', index, 'name'], values)}
                onChange={handleRateValueChange(index)}
              />
              <Input
                name='rate'
                type='number'
                label='Kwota'
                value={pathOr('', ['rates', index, 'rate'], values)}
                onChange={handleRateValueChange(index)}
              />
              <DeleteIcon onClick={handleDeleteRate(index)}/>
            </RateForm>
          )
        })}
        <AddRate onClick={handleAddRate}>Dodaj stawkę</AddRate>
      </RatesWrapper>
      <ButtonsWrapper>
        <Button onClick={handleCloseModal} color='secondary'>Anuluj</Button>
        <Button
          color='primary'
          onClick={handleUpdate}
          disabled={isNilOrEmpty(values.name)}
        >
          Zapisz
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default EditTeacherForm

const Wrapper = styled.form`
  
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

const RatesWrapper = styled.div`
  margin-top: 20px;
`

const RateForm = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const RatesSectionTitle = styled.div`
  margin: 30px 0 10px;
`

const AddRate = styled.div`
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  margin: 0 0 0 auto;
`

const DeleteIcon = styled(RemoveCircleOutlineIcon)`
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
