import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import {
  fetchActiveWorkers,
  getSingleWorker,
  getSingleWorkerIncomeItems
} from 'services/WorkersService'

export const fetchWorkersListRoutine = createRoutine('FETCH_WORKERS_LIST')
export const fetchSingleWorkerRoutine = createRoutine('FETCH_WORKER')
export const clearSingleWorkerRoutine = createRoutine('CLEAR_WORKER')
export const fetchSingleWorkerIncomeItemsRoutine = createRoutine('FETCH_WORKER_INCOME')

function * fetchWorkersList () {
  yield put(fetchWorkersListRoutine.request())
  try {
    const result = yield call(fetchActiveWorkers)
    yield put(fetchWorkersListRoutine.success(result))
  } catch (e) {
    yield put(fetchWorkersListRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleWorker ({ payload }) {
  yield put(fetchSingleWorkerRoutine.request())
  try {
    const result = yield call(getSingleWorker, payload)
    yield put(fetchSingleWorkerRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleWorkerRoutine.failure(e))
    console.error(e)
  }
}

function * clearSingleWorker () {
  yield put(clearSingleWorkerRoutine.success())
}

function * fetchSingleWorkerIncomeItems ({ payload }) {
  yield put(fetchSingleWorkerIncomeItemsRoutine.request())
  try {
    const result = yield call(getSingleWorkerIncomeItems, payload)
    yield put(fetchSingleWorkerIncomeItemsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleWorkerIncomeItemsRoutine.failure(e))
    console.error(e)
  }
}

export function * fetchWorkersListWatcher () {
  yield takeLatest(fetchWorkersListRoutine.TRIGGER, fetchWorkersList)
}

export function * fetchSingleWorkerWatcher () {
  yield takeLatest(fetchSingleWorkerRoutine.TRIGGER, fetchSingleWorker)
}

export function * clearSingleWorkerWatcher () {
  yield takeLatest(clearSingleWorkerRoutine.TRIGGER, clearSingleWorker)
}

export function * fetchSingleWorkerIncomeItemsWatcher () {
  yield takeLatest(fetchSingleWorkerIncomeItemsRoutine.TRIGGER, fetchSingleWorkerIncomeItems)
}

export const workersSagas = [
  fork(fetchWorkersListWatcher),
  fork(fetchSingleWorkerWatcher),
  fork(clearSingleWorkerWatcher),
  fork(fetchSingleWorkerIncomeItemsWatcher)
]
